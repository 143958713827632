import styled from 'styled-components'

export const ContainerHeader = styled.div`
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #004a80;
  padding: 0 15px;
  img {
    height: 40px;
  }

  > a {
    display: flex;
    align-items: center;
    text-decoration: none;

    h4 {
      margin: 0;
      color: #fff;
      /* outline: ; */
    }
  }

  button {
    color: #fff;
  }

  svg {
    width: 18px;
    height: 18px;
  }

  .ContainerButtonHader {
    display: flex;
    gap: 2px;
    align-items: center;
    a {
      width: 41px;
    }

    button {
      width: 41px;
      height: 32px;
    }
  }
`
