import { Box, Button, Flex, Icon, Spacer } from '@chakra-ui/react'
import React from 'react'
import { FaArrowRight } from 'react-icons/fa'

const Site = ({ site }: { site: string }) => {
  return (
    <Flex direction="column" w="100%" mb="5px">
      <Box flex={1} fontFamily="inter-medium" fontSize="18px" color="gray.500">
        Site
      </Box>
      <Flex
        fontSize="18px"
        fontFamily="inter-light"
        bg="#EEEEFA"
        p="6px"
        mt="5px"
        borderRadius="5px"
        dir="row"
        alignItems="center"
      >
        <Box>{site}</Box>
        <Spacer />
        <Button
          bg="blue"
          onClick={() => {
            if (site) {
              const url = site
              let formattedUrl = site

              if (!url.startsWith('http://') && !url.startsWith('https://')) {
                formattedUrl = 'https://' + url
              }

              window.open(formattedUrl, '_blank')
            }
          }}
        >
          <Icon
            as={FaArrowRight}
            size="sm"
            aria-label="go-to-site"
            color="white"
          />
        </Button>
      </Flex>
    </Flex>
  )
}

export default Site
