import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import {
  Button,
  Form,
  Modal,
  OverlayTrigger,
  ProgressBar,
} from 'react-bootstrap'
import {
  BsArrowDownShort,
  BsArrowUpShort,
  BsPeopleFill,
  BsPersonFillAdd,
} from 'react-icons/bs'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import {
  Buttons,
  ContainerFooter,
  ContainerFreq,
  ContainerTable,
  ContainerTost,
} from './styled'
import {
  CadastrarPessoaEvento,
  GetIdEventos,
  GetPessoasByEvento,
  PostPessoaEvento,
  RegisterMesa,
  responseFrequenciByEventos,
} from '../../service'
import { GiHamburgerMenu } from 'react-icons/gi'
import { FaEdit } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { renderTooltipAdd, renderTooltipEdit } from '../../utils/Tooltip'
import { TableIconImage } from '.'
import { ContextAPI } from '../../hooks/useContextApi'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { BiInfoCircle } from 'react-icons/bi'
import Select from 'react-select'
import { notifyError } from '../../components/Toast/intex'
import { valueContainerCSS } from 'react-select/dist/declarations/src/components/containers'
import { Box, HStack, Spacer, Text, list } from '@chakra-ui/react'
// import { Container } from './styles';

const FrequenciaDoEvento: React.FC = () => {
  const [value, setValue] = useState<string>()
  const [suggestions, setSuggestions] = useState<Pessoa[]>([])
  const [reload, setReload] = useState(false)
  const [statusSort, setStatusSort] = useState<string>('')
  const [nomeSort, setNomeSort] = useState<string>('')
  const [pronomeSort, setPronomeSort] = useState<string>('')

  const [tituloSort, setTituloSort] = useState<string>('')
  const [precedenciaSort, setPrecedenciaSort] = useState<string>('')
  const [mesaSort, setMesaSort] = useState<string>('')
  const [searchValue, setSearchValue] = useState<{
    value: number
    label: string
  }>()
  const [searchPeople, setSearchPeople] = useState<{
    value: number
    label: string
  }>()

  const [searchPeopleInput, setSearchPeopleInput] = useState<
    PessoaFrequenciaEvento[]
  >([])

  const [DataList, setDataList] = React.useState<PessoaFrequenciaEvento[]>([])
  const [WidthTable, setWidthTable] = React.useState<number>(0)
  const [evento, setEvento] = React.useState<FrequenciaType>()

  const [statusAtt, setStatusAtt] = React.useState<
    { eventoId: number; pessoaId: number; presente: boolean }[]
  >([])

  const [finalizarChamada, setFinalizarChamada] = useState<boolean>(false)
  const [alterarMesa, setAlterarMesa] = useState(false)

  const [AddPessoaEventoBool, setAddPessoaEventoBool] = useState(false)

  const [progressBar, setProgressBar] = React.useReducer(
    (prev: any, next: Partial<any>) => {
      return { ...prev, ...next }
    },
    {
      presente: 0,
      ausente: 0,
      nominado: 0,
    },
  )

  const {
    modalEvent,
    handleModalCreate,
    handleModalSucess,
    handleAlterName,
    handleChangeUrlRoute,
    handleChangeNewNotify,
    pessoas,
  } = React.useContext(ContextAPI)
  const isFetch = useRef(false)
  const urlPath = '/frequenciasevento/'
  const idEvento = location.pathname.replace(urlPath, '')

  useEffect(() => {
    if (!isFetch.current) {
      isFetch.current = true
      Response()
    }
    async function Response() {
      const [responsePessoaByEvento, responseFrequencia, Evento] =
        await Promise.all([
          GetPessoasByEvento(Number(idEvento)),
          responseFrequenciByEventos(Number(idEvento)),
          GetIdEventos(idEvento),
        ])
      setEvento(Evento.data)

      // Vamos fazer os representantes serem pessoas do evento

      const pessoasFreqList: PessoaFrequenciaEvento[] = []
      const objPessoaList: PessoaFrequenciaEvento[] =
        responsePessoaByEvento.data
      const listaDeOrdenacaoDePrecedencia = []

      for (const pessoa of objPessoaList) {
        const data = {
          id: pessoa.pessoaId,
          totalPrecedencia: Number(pessoa.precedencia),
        }
        if (pessoa.representantes) {
          for (const representante of pessoa.representantes) {
            data.totalPrecedencia += Number(representante.precedencia)
          }
        }
        listaDeOrdenacaoDePrecedencia.push(data)
      }

      listaDeOrdenacaoDePrecedencia.sort((a, b) => {
        if (a.totalPrecedencia < b.totalPrecedencia) {
          return 1
        } else {
          return -1
        }
      })

      for (const obj of listaDeOrdenacaoDePrecedencia) {
        const pessoa = objPessoaList.find((e) => e.pessoaId === obj.id)
        pessoasFreqList.push(pessoa!)
        if (pessoa?.representantes) {
          const representantes = pessoa.representantes
          representantes.sort((a, b) => {
            if (Number(a.precedencia) < Number(b.precedencia)) {
              return 1
            } else {
              return -1
            }
          })
          for (const representante of representantes) {
            const newPessoa: PessoaFrequenciaEvento = {
              ...representante,
              titulo: '',
              pessoaEventos: [
                {
                  compoeMesa: undefined,
                  eventoId: Evento.data.eventoId,
                  pessoaId: representante.pessoaId,
                  profissionalEventoId: undefined,
                  responsavel: undefined,
                  vinculo: null,
                },
              ],
            }
            pessoasFreqList.push(newPessoa)
          }
        }
      }

      const objList = responseFrequencia.data

      handleAlterName(
        `Frequência do  - ${Evento.data.nome} | N° ${Evento.data.eventoId}`,
      )
      handleChangeUrlRoute('/eventosCadastrados')
      const event = localStorage.getItem('EventRegister')
      const pessoaRegistradaEvento = localStorage.getItem('pessoaIdEvent')
      if (pessoaRegistradaEvento !== null) {
        const localePeople = pessoasFreqList.filter(
          (e) => e.pessoaId === Number(pessoaRegistradaEvento),
        )
        notify(localePeople[0], 'pessoa', Evento.data.nome)
        localStorage.removeItem('pessoaIdEvent')
      }

      if (event !== null) {
        localStorage.removeItem('EventRegister')
      }

      for (const i in pessoasFreqList) {
        const locale = objList.filter(
          (e: any) => e.pessoaId === pessoasFreqList[i].pessoaId,
        )

        pessoasFreqList[i].mesa = false

        if (locale.length !== 0) {
          pessoasFreqList[i].status =
            locale[locale.length - 1].status === 1
              ? 'Nominado'
              : locale[locale.length - 1].status === 2
              ? 'Presente'
              : locale[locale.length - 1].status === 3
              ? 'Ausente'
              : null
        }
      }

      if (responseFrequencia.status === 200) {
        setDataList(
          pessoasFreqList
            .filter((e) => e.status === 'Presente')
            .sort((a, b) => b.precedencia - a.precedencia)
            .concat(pessoasFreqList.filter((e) => e.status !== 'Presente')),
        )
        setSearchPeopleInput(
          pessoasFreqList
            .filter((e) => e.status === 'Presente')
            .sort((a, b) => b.precedencia - a.precedencia)
            .concat(pessoasFreqList.filter((e) => e.status !== 'Presente')),
        )

        // const objList =
      }
    }
  }, [])

  useEffect(() => {
    const Table = document.getElementById('TableContainer') as HTMLElement

    setWidthTable(Table.offsetWidth - 1)
  }, [DataList])

  useEffect(() => {
    setDataList(DataList)
    setSearchPeopleInput(DataList)
    setAlterarMesa(false)
  }, [alterarMesa])

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return
    }

    const { source, destination } = result

    // Reordena as linhas com base na posição final
    const reorderedRows = Array.from(DataList)
    const [removed] = reorderedRows.splice(source.index, 1)
    reorderedRows.splice(destination.index, 0, removed)

    // Atualiza o estado com as linhas reordenadas
    setDataList(reorderedRows)
    setSearchPeopleInput(reorderedRows)
  }
  const handleDragStart = (e: any, id: any) => {}

  const handleStatusChange = (
    pessoa: PessoaFrequenciaEvento,
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { value } = event.target

    const currentPessoaEventoIndex = DataList.findIndex((item) => {
      return item.pessoaId === pessoa.pessoaId
    })

    if (currentPessoaEventoIndex < 0) {
      return DataList
    }

    DataList[currentPessoaEventoIndex].status = value
    const statusOfPresence = DataList.filter((e) => e.status === value)
    let x = DataList.length
    x = 100 / x
    x = x * statusOfPresence.length

    value === 'Presente' && setProgressBar({ presente: x })
    value === 'Ausente' && setProgressBar({ ausente: x })
    value === 'Nominado' && setProgressBar({ nominado: x })

    const List: any[] = []
    for (const i in DataList) {
      if (DataList[i].status !== undefined) {
        const obj = {
          eventoId: Number(idEvento),
          pessoaId: DataList[i].pessoaId,
          status:
            DataList[i].status === 'Nominado'
              ? '1'
              : DataList[i].status === 'Presente'
              ? '2'
              : DataList[i].status === 'Ausente' && '3',
        }
        List.push(obj)
      }
    }
    setDataList(DataList)
    setSearchPeopleInput(DataList)

    setStatusAtt(List)
  }

  function handleClass(row: any) {
    return row.nome
  }

  const ColorNome = (row: any) => {
    switch (row.status) {
      case 'Ausente':
        return { color: 'red' }
      case '':
        return { color: '#f5a851' }
    }
    return row.nome
  }

  const countPresentes = DataList.reduce(
    (count: number, pessoa: PessoaFrequenciaEvento) => {
      if (pessoa.status === 'Presente') {
        return (count += 1)
      }
      return count
    },
    0,
  )

  const countNominados = DataList.reduce((count, pessoa) => {
    if (pessoa.status === 'Nominado') {
      return (count += 1)
    }
    return count
  }, 0)

  const countAusentes = DataList.reduce((count, pessoa) => {
    // let x = DataList.length

    if (pessoa.status === 'Ausente') {
      return (count += 1)
    }

    return count
  }, 0)

  const handleFinalizarChamadaOuRetornarChamada = async () => {
    setFinalizarChamada(!finalizarChamada)

    const ChangeStatus = [...searchPeopleInput].flat()
    const statusListSend: any[] = []
    ChangeStatus.map((e) => {
      if (e.status === 'Presente') {
        e.status = 'Nominado'
      }
      const obj = {
        eventoId: Number(idEvento),
        pessoaId: e.pessoaId,
        status:
          e.status === 'Nominado'
            ? '1'
            : e.status === 'Presente'
            ? '2'
            : e.status === 'Ausente'
            ? '3'
            : '0',
      }
      if (obj.status !== '0') {
        statusListSend.push(obj)
      }
      return e
    })

    for (const i in statusListSend) {
      await PostPessoaEvento(statusListSend[i])

      if (statusListSend[i].status === '2') {
        const currentPessoaEventoIndex = DataList.findIndex((item) => {
          return item.pessoaId === statusListSend[i].pessoaId
        })
        DataList[currentPessoaEventoIndex].status = 'Nominado'

        setDataList(DataList)
      }
    }

    setSearchPeopleInput(ChangeStatus)
    handleModalCreate(false)
    notify(undefined, 'chamada')
  }

  const NotifySuccessText = (
    row: PessoaFrequenciaEvento | undefined,
    descricao: string,
    eventName?: string,
  ) => {
    return (
      <ContainerTost>
        <h6>
          <BiInfoCircle />{' '}
          {evento?.nome === undefined ? eventName : evento?.nome}
        </h6>
        <p style={{ margin: 0 }}>
          {row !== undefined ? (
            <>
              {descricao === 'pessoa' && `${row.nome} foi adicionado.`}
              {descricao === 'mesa' && `${row.nome} foi adicionado à mesa.`}
            </>
          ) : (
            <>{descricao === 'chamada' && `Chamada finalizada.`}</>
          )}
        </p>
      </ContainerTost>
    )
  }

  const NotifyErrorText = (row: PessoaFrequenciaEvento, eventName?: string) => {
    return (
      <ContainerTost>
        <h6 style={{ color: '#ff2d55' }}>
          <BiInfoCircle />{' '}
          {evento?.nome === undefined ? eventName : evento?.nome}
        </h6>
        <p style={{ margin: 0 }}>
          {row.titulo} {row.nome} foi removido da mesa.
        </p>
      </ContainerTost>
    )
  }

  const notify = (
    row: PessoaFrequenciaEvento | undefined,
    desc: string,
    eventName?: string,
  ) =>
    toast.success(() => NotifySuccessText(row, desc, eventName), {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    })

  const notifyRemove = (row: PessoaFrequenciaEvento, eventName?: string) =>
    toast.error(NotifyErrorText(row, eventName), {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    })

  const handleSortStatus = () => {
    setTituloSort('')
    setPrecedenciaSort('')
    setMesaSort('')
    setPronomeSort('')
    const nome = DataList.sort((a, b) => {
      const elementoA =
        a.status === undefined || a.status === null ? '' : a.status
      const elementoB =
        b.status === undefined || b.status === null ? '' : b.status
      let Sort = ''
      if (statusSort === 'asc') {
        setStatusSort('desc')
        Sort = 'desc'
      } else {
        setStatusSort('asc')
        Sort = 'asc'
      }

      if (elementoA < elementoB) {
        return Sort === 'asc' ? -1 : 1
      } else if (elementoA > elementoB) {
        return Sort === 'asc' ? 1 : -1
      } else {
        return 0
      }
    })

    setDataList(nome)
    setSearchPeopleInput(nome)
  }

  const handleSortName = () => {
    setTituloSort('')
    setPrecedenciaSort('')
    setMesaSort('')
    setStatusSort('')
    setPronomeSort('')


    // return

    const nome = DataList.sort((a, b) => {
      const elementoA = a.nome?.toLowerCase()
      const elementoB = b.nome?.toLowerCase()
      let Sort = ''
      if (nomeSort === 'asc') {
        setNomeSort('desc')
        Sort = 'desc'
      } else {
        setNomeSort('asc')
        Sort = 'asc'
      }

      let nome:any = []

      if(Sort === 'asc'){
        return elementoA.localeCompare(elementoB);
      } else if (Sort === 'desc') {
        return elementoB.localeCompare(elementoA);
      } else {
        return 0
      }

      // return

      if (elementoA < elementoB) {
        return Sort === 'asc' ? -1 : 1
      } else if (elementoA > elementoB) {
        return Sort === 'asc' ? 1 : -1
      } else {
        return 0
      }
    })

    setDataList(nome)
    setSearchPeopleInput(nome)
  }

  const handleSortPronome = () => {
    setNomeSort('')
    setPrecedenciaSort('')
    setMesaSort('')
    setStatusSort('')
    setTituloSort('')
    const nome = DataList.sort((a, b) => {
      const elementoA = a.pronomeTratamento?.toLowerCase()
      const elementoB = b.pronomeTratamento?.toLowerCase()
      if (pronomeSort === 'asc') {
        setPronomeSort('desc')
      } else {
        setPronomeSort('asc')
      }

      if (elementoA < elementoB) {
        return pronomeSort === 'asc' ? -1 : 1
      } else if (elementoA > elementoB) {
        return pronomeSort === 'asc' ? 1 : -1
      } else {
        return 0
      }
    })

    setDataList(nome)
    setSearchPeopleInput(nome)
  }

  const handleSortCargo = () => {
    setNomeSort('')
    setPrecedenciaSort('')
    setMesaSort('')
    setStatusSort('')
    setPronomeSort('')

    const nome = DataList.sort((a, b) => {
      const elementoA = a.cargo?.toLowerCase()
      const elementoB = b.cargo?.toLowerCase()
      if (tituloSort === 'asc') {
        setTituloSort('desc')
      } else {
        setTituloSort('asc')
      }

      if (elementoA < elementoB) {
        return tituloSort === 'asc' ? -1 : 1
      } else if (elementoA > elementoB) {
        return tituloSort === 'asc' ? 1 : -1
      } else {
        return 0
      }
    })

    setDataList(nome)
    setSearchPeopleInput(nome)
  }

  const handleSortPrecedencia = () => {
    setNomeSort('')
    setTituloSort('')
    setMesaSort('')
    setStatusSort('')
    setPronomeSort('')

    const nome = DataList.sort((a, b) => {
      const elementoA = Number(a.precedencia)
      const elementoB = Number(b.precedencia)
      if (precedenciaSort === 'asc') {
        setPrecedenciaSort('desc')
      } else {
        setPrecedenciaSort('asc')
      }

      if (elementoA < elementoB) {
        return precedenciaSort === 'asc' ? -1 : 1
      } else if (elementoA > elementoB) {
        return precedenciaSort === 'asc' ? 1 : -1
      } else {
        return 0
      }
    })

    setDataList(nome)
    setSearchPeopleInput(nome)
  }

  const handleSortMesa = () => {
    setNomeSort('')
    setTituloSort('')
    setPrecedenciaSort('')
    setPronomeSort('')

    const nome = DataList.sort((a, b) => {
      const EventoA = a.pessoaEventos.findIndex(
        (e) => e.eventoId === evento?.eventoId,
      )
      const EventoB = b.pessoaEventos.findIndex(
        (e) => e.eventoId === evento?.eventoId,
      )

      const elementoA = a.pessoaEventos[EventoA].compoeMesa
      const elementoB = b.pessoaEventos[EventoB].compoeMesa
      if (mesaSort === 'asc') {
        setMesaSort('desc')
      } else {
        setMesaSort('asc')
      }

      if (mesaSort === 'asc') {
        return elementoA === elementoB ? 0 : elementoA ? -1 : 1
      } else {
        return elementoA === elementoB ? 0 : elementoB ? -1 : 1
      }
    })

    setDataList(nome)
    setSearchPeopleInput(nome)
  }

  const optionsSelect = pessoas.map((e) => {
    return {
      value: e.pessoaId,
      label: e.nome,
    }
  })

  const handleBuscarPessoa = (event: ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value

    setSearchPeopleInput((row) => {
      const nome = DataList.filter((e) =>
        e.nome?.toLowerCase().includes(search?.toLowerCase()),
      )
      if (nome.length > 0) {
        return nome
      }
      const titulo = DataList.filter((e) =>
        e.titulo?.toLowerCase().includes(search?.toLowerCase()),
      )

      if (titulo.length > 0) {
        return titulo
      }

      const cargo = DataList.filter((e) =>
        e.cargo?.toLowerCase().includes(search?.toLowerCase()),
      )

      if (cargo.length > 0) {
        return cargo
      }

      // Por cpf primeiro
      let resultadoNumerico = DataList.filter((e) => e.cpf?.includes(search))

      for (const data of DataList) {
        const precedenciaString: string = data.precedencia.toString()
        if (precedenciaString.includes(search)) {
          /// Vamos verificar se o resultado está no array resultado. Se não estiver adicionamos
          let naoExisteEmResultado = true
          for (const valueResultado of resultadoNumerico) {
            if (valueResultado.pessoaId === data.pessoaId) {
              naoExisteEmResultado = false
              break
            }
          }

          if (naoExisteEmResultado) {
            resultadoNumerico = [...resultadoNumerico, data]
          }
        }
      }

      if (resultadoNumerico.length > 0) {
        return resultadoNumerico
      }

      return nome
    })
  }
  console.log(searchPeopleInput)

  const handleAddPessoaAoEvento = async () => {
    const localePeople: any = pessoas.filter((e) => e.nome === value)[0]

    const JaAddNaTabela: any = searchPeopleInput.filter((e) => e.nome === value)

    if (JaAddNaTabela.length !== 0) {
      notifyError(evento?.nome, 'Pessoa ja adicionada ao evento.')

      return
    }

    const obj = {
      compoeMesa: false,
      eventoId: Number(idEvento),
      pessoaId: localePeople.pessoaId,
      precedencia: localePeople.precedencia,
      profissionalEventoId: 0,
      responsavel: false,
      vinculo: '',
    }
    localePeople.pessoaEventos[0] = obj
    await CadastrarPessoaEvento(obj)
    setSearchPeopleInput([...searchPeopleInput, localePeople])
    notify(localePeople, 'pessoa', evento === undefined ? '' : evento.nome)
    setValue(undefined)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
    setValue(inputValue)

    // Filtra os usuários com base no valor do input
    const filteredUsers = pessoas!.filter((user) =>
      user.nome?.toLowerCase().includes(inputValue?.toLowerCase() ?? ''),
    )

    if (inputValue) {
      setSuggestions(filteredUsers)
    } else {
      setSuggestions([])
    }
  }

  const handleSuggestionClick = (suggestion: Pessoa) => {
    setValue(suggestion.nome)
    setSuggestions([])
  }

  return (
    <>
      <Modal
        show={AddPessoaEventoBool}
        onHide={() => {
          setAddPessoaEventoBool(false)
        }}
      >
        <Modal.Header style={{ background: '#D1E7DD' }} closeButton>
          <Modal.Title style={{ color: '#2A664A' }}>
            Adicionar pessoa
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Gostaria de adicionar essa pessoa ao evento?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setAddPessoaEventoBool(false)
            }}
          >
            Fechar
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleAddPessoaAoEvento()
              setAddPessoaEventoBool(false)
            }}
          >
            Adicionar
          </Button>
          <Link
            onClick={() => {
              localStorage.setItem('EventRegister', idEvento)
            }}
            to={`/cadastrar`}
          >
            <Button variant="primary">Cadastrar</Button>
          </Link>
        </Modal.Footer>
      </Modal>

      {/* Modal para finalizar a chamada */}
      <Modal
        show={modalEvent.create}
        onHide={() => {
          handleModalCreate(false)
        }}
      >
        <Modal.Header style={{ background: '#D1E7DD' }} closeButton>
          <Modal.Title style={{ color: '#2A664A' }}>
            Finalizar Chamada
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Deseja mesmo finalizar a chamada?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handleModalCreate(false)
            }}
          >
            Não
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleFinalizarChamadaOuRetornarChamada()
            }}
          >
            Sim
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal para Cadastrar nova pessoa ao evento */}
      <Modal
        show={modalEvent.sucess}
        onHide={() => {
          handleModalSucess(false)
        }}
      >
        <Modal.Header style={{ background: '#D1E7DD' }} closeButton>
          <Modal.Title style={{ color: '#2A664A' }}>Redirecionar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Gostaria de cadastrar uma nova pessoa a esse evento?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handleModalSucess(false)
            }}
          >
            Não
          </Button>
          <Link
            onClick={() => {
              localStorage.setItem('EventRegister', idEvento)
              handleModalSucess(false)
            }}
            to={`/cadastrar`}
          >
            <Button variant="primary">Sim</Button>
          </Link>
        </Modal.Footer>
      </Modal>

      <ContainerFreq>
        <HStack alignItems="top">
          <Box flex={5} h="100%">
            <input
              style={{
                paddingLeft: '10px',
                width: '100%',
                border: '1px solid gray',
                height: '40px',
                borderRadius:
                  suggestions.length > 0
                    ? '5px 5px 0px 0px'
                    : '5px 5px 5px 5px',
              }}
              type="text"
              placeholder="Digite o nome de um usuário"
              value={value}
              onChange={(event) => {
                handleChange(event)
              }}
            />

            <ul
              style={{
                width: '100%',
                maxHeight: '200px',
                overflowY: 'scroll',
                border:
                  suggestions.length > 0 ? '1px solid gray' : '0px solid gray',
                borderRadius: '0px 0px 15px 15px',
              }}
            >
              {suggestions.map((suggestion, index) => (
                <Text
                  key={index}
                  onClick={() => {
                    handleSuggestionClick(suggestion)
                  }}
                >
                  {suggestion.nome}
                </Text>
              ))}
            </ul>
          </Box>
          <Box>
            <Button
              style={{
                background:
                  value === undefined || value === '' ? 'green' : 'blue',
              }}
              onClick={() => {
                value === undefined || value === ''
                  ? handleModalSucess(true)
                  : handleAddPessoaAoEvento()
              }}
            >
              <Text mb="0px">
                {value === undefined || value === ''
                  ? 'Cadastrar nova pessoa'
                  : 'Adicionar'}
              </Text>
            </Button>
          </Box>
          <Spacer />
          <Box>
            <Button
              onClick={() => {
                if (!finalizarChamada) {
                  handleModalCreate(true)
                  handleChangeNewNotify(true)
                } else {
                  setFinalizarChamada(false)
                }
                // !finalizarChamada
                //   ? (handleModalCreate(true), handleChangeNewNotify(true))
                //   : setFinalizarChamada(false)
              }}
            >
              {finalizarChamada ? 'Retomar Chamada' : 'Finalizar Chamada'}
            </Button>
          </Box>
        </HStack>
        <Box height="100px"></Box>
        <Form.Control
          placeholder="Buscar por nome ou título na tabela"
          type="text"
          onChange={handleBuscarPessoa}
        />

        <ContainerTable className="tableStyle" WidthContainer={WidthTable}>
          <DragDropContext onDragEnd={onDragEnd}>
            <table id="TableContainer">
              <thead>
                <tr style={{ width: '100%' }}>
                  <th onClick={() => handleSortStatus()}>
                    <div>
                      Status
                      <BsArrowDownShort
                        style={
                          statusSort !== 'asc'
                            ? { color: '#717171' }
                            : { color: '#000' }
                        }
                      />
                      <BsArrowUpShort
                        style={
                          statusSort === 'asc' || statusSort === ''
                            ? { color: '#717171' }
                            : { color: '#000' }
                        }
                      />
                    </div>
                  </th>
                  <th
                    onClick={() => {
                      handleSortName()
                    }}
                  >
                    <div>
                      Nome{' '}
                      <BsArrowDownShort
                        style={
                          nomeSort !== 'asc'
                            ? { color: '#717171' }
                            : { color: '#000' }
                        }
                      />
                      <BsArrowUpShort
                        style={
                          nomeSort === 'asc' || nomeSort === ''
                            ? { color: '#717171' }
                            : { color: '#000' }
                        }
                      />
                    </div>
                  </th>

                  <th
                    onClick={() => {
                      handleSortPronome()
                    }}
                  >
                    <div>
                      Pronome{' '} 
                      <BsArrowDownShort
                        style={
                          pronomeSort !== 'asc'
                            ? { color: '#717171' }
                            : { color: '#000' }
                        }
                      />
                      <BsArrowUpShort
                        style={
                          pronomeSort === 'asc' || pronomeSort === ''
                            ? { color: '#717171' }
                            : { color: '#000' }
                        }
                      />
                    </div>
                  </th>

                  <th
                    onClick={() => {
                      handleSortCargo()
                    }}
                  >
                    <div>
                      Cargo
                      <BsArrowDownShort
                        style={
                          tituloSort !== 'asc'
                            ? { color: '#717171' }
                            : { color: '#000' }
                        }
                      />
                      <BsArrowUpShort
                        style={
                          tituloSort === 'asc' || tituloSort === ''
                            ? { color: '#717171' }
                            : { color: '#000' }
                        }
                      />
                    </div>
                  </th>

                  <th
                    onClick={() => {
                      handleSortPrecedencia()
                    }}
                  >
                    <div>
                      Precedência
                      <BsArrowDownShort
                        style={
                          precedenciaSort !== 'asc'
                            ? { color: '#717171' }
                            : { color: '#000' }
                        }
                      />
                      <BsArrowUpShort
                        style={
                          precedenciaSort === 'asc' || precedenciaSort === ''
                            ? { color: '#717171' }
                            : { color: '#000' }
                        }
                      />
                    </div>
                  </th>

                  <th
                    onClick={() => {
                      handleSortMesa()
                    }}
                  >
                    <div>
                      Mesa
                      <BsArrowDownShort
                        style={
                          mesaSort !== 'asc'
                            ? { color: '#717171' }
                            : { color: '#000' }
                        }
                      />
                      <BsArrowUpShort
                        style={
                          mesaSort === 'asc' || mesaSort === ''
                            ? { color: '#717171' }
                            : { color: '#000' }
                        }
                      />
                    </div>
                  </th>

                  <th>Representante</th>
                  <th>Editar</th>
                  <th>Mover</th>
                </tr>
              </thead>
              <Droppable droppableId="rows">
                {(provided) => (
                  <tbody
                    style={{ width: '100%' }}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {searchPeopleInput.map((row, index) => (
                      
                      <Draggable
                        key={row.pessoaId}
                        draggableId={String(row.pessoaId)}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <tr
                            style={{
                              width: '100%',
                              border: snapshot.isDragging
                                ? '1px solid #ccc7c7'
                                : '1px solid #ccc7c7',
                            }}
                            {...provided.draggableProps}
                            // {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            className="DragDropCOntainer"
                          >
                            <td>
                              <>
                                {row.representantes !== undefined &&
                                  row.representantes?.length > 0 && (
                                    <p>Sendo Representando</p>
                                  )}
                                {row.representantes?.length === 0 && (
                                  <select
                                    name=""
                                    id={`Status${row.pessoaId}`}
                                    value={
                                      row.status !== null ? row.status : ''
                                    }
                                    onChange={(event) =>
                                      handleStatusChange(row, event)
                                    }
                                  >
                                    <option value=""></option>
                                    <option value="Presente">Presente</option>
                                    <option value="Ausente">Ausente</option>
                                    <option value="Nominado">Nominado</option>
                                  </select>
                                )}
                              </>
                            </td>
                            <td
                              style={
                                row.status === 'Ausente'
                                  ? { color: 'red' }
                                  : row.status === undefined ||
                                    row.status === ''
                                  ? { color: '#f5a851' }
                                  : { color: '#333' }
                              }
                            >
                              {handleClass(row)}
                            </td>
                            <td>{row.pronomeTratamento}</td>
                            <td>{row.cargo}</td>
                            <td>{row.precedencia}</td>
                            <td>
                              {' '}
                              <div
                                style={
                                  row.pessoaEventos.filter(
                                    (e) => e.eventoId === Number(idEvento),
                                  )[0].compoeMesa
                                    ? { color: '#0d6efd' }
                                    : { color: '#29292e' }
                                }
                                onClick={async () => {
                                  const obj = row.pessoaEventos.filter(
                                    (e) => e.eventoId === Number(idEvento),
                                  )[0]

                                  obj.compoeMesa
                                    ? notifyRemove(row)
                                    : notify(row, 'mesa')
                                  obj.compoeMesa = !obj.compoeMesa

                                  const objTable = {
                                    compoeMesa: obj.compoeMesa,
                                    eventoId: Number(idEvento),
                                    pessoaId: row.pessoaId,
                                  }

                                  await RegisterMesa(objTable)

                                  setAlterarMesa(true)
                                }}
                              >
                                <TableIconImage />
                              </div>
                            </td>
                            <td>
                              {' '}
                              <div>
                                <Link
                                  to={`/representante/evento/${evento?.eventoId}/pessoa/${row.pessoaId}`}
                                  tabIndex={-1}
                                >
                                  <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltipAdd}
                                  >
                                    <Button variant="link">
                                      <BsPeopleFill
                                        style={{ color: '#004a80' }}
                                      />
                                    </Button>
                                  </OverlayTrigger>
                                </Link>
                              </div>
                            </td>
                            <td>
                              <Link
                                to={`/pessoa/${row.pessoaId}`}
                                onClick={() =>
                                  localStorage.setItem(
                                    'EventRegister',
                                    idEvento,
                                  )
                                }
                                tabIndex={-1}
                              >
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={renderTooltipEdit}
                                >
                                  <Button variant="link">
                                    <FaEdit style={{ color: '#004a80' }} />
                                  </Button>
                                </OverlayTrigger>
                              </Link>
                            </td>
                            <td>
                              {/* {row.representantes &&
                                row.representantes?.length > 0 && ( */}
                                  <Button
                                    variant="link"
                                    {...provided.dragHandleProps}
                                    onMouseDown={(e) =>
                                      handleDragStart(e, row.pessoaId)
                                    }
                                  >
                                    <GiHamburgerMenu
                                      style={{ color: '#004a80' }}
                                    />
                                  </Button>
                                {/* )} */}
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </table>
          </DragDropContext>
        </ContainerTable>

        <ContainerFooter>
          <div>
            <div>
              {countNominados > 0 && <span>Nominado</span>}
              {countPresentes > 0 && <span>Presentes</span>}
              {countAusentes > 0 && <span>Ausentes</span>}
            </div>

            <div>
              {countNominados > 0 && (
                <span>
                  <ProgressBar
                    className="progrss"
                    now={(countNominados / DataList.length) * 100}
                  />
                </span>
              )}

              {countPresentes > 0 && (
                <span>
                  <ProgressBar
                    className="progrss"
                    now={(countPresentes / DataList.length) * 100}
                  />{' '}
                </span>
              )}

              {countAusentes > 0 && (
                <span>
                  <ProgressBar
                    className="progrss"
                    now={(countAusentes / DataList.length) * 100}
                  />{' '}
                </span>
              )}
            </div>
          </div>

          <Buttons>
            <Link to={'/eventosCadastrados'}>
              <Button>Voltar</Button>
            </Link>
          </Buttons>
        </ContainerFooter>
      </ContainerFreq>
    </>
  )
}

const THead = ['Status', 'Nome', 'Título', 'Precedência', 'Mesa', 'Ações']

export default FrequenciaDoEvento
