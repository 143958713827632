import { Flex, Box, IconButton, Spacer, useToast } from '@chakra-ui/react'
import { BiPaste } from 'react-icons/bi'
interface Props {
  title: string
  data: string | undefined
  showCopyButton?: boolean
}

const InfoField = ({ title, data, showCopyButton }: Props) => {
  const toast = useToast()
  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text)

      toast({
        title: 'Copiado!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch (err) {
      alert('Falha ao copiar o texto')
    }
  }
  return (
    <Flex direction="column" w="100%" mb="5px">
      <Box flex={1} fontFamily="inter-medium" fontSize="18px" color="gray.500">
        {title}
      </Box>
      <Flex
        fontSize="17px"
        fontFamily="inter-light"
        bg="#EEEEFA"
        p="10px"
        mt="5px"
        borderRadius="5px"
        dir="row"
      >
        <Box fontFamily="inter-light" fontSize="16px">
          {data || ''}
        </Box>
        <Spacer />
        <Box w="20px"></Box>
        {showCopyButton && (
          <IconButton
            bg="transparent"
            border="1px solid gray"
            icon={<BiPaste />}
            size="sm"
            aria-label="copy-icon"
            onClick={() => copyToClipboard(data ?? '')}
          />
        )}
      </Flex>
    </Flex>
  )
}

export default InfoField
