import { hover } from '@testing-library/user-event/dist/hover'
import { table } from 'console'
import { wrap } from 'module'
import { relative } from 'path'
import { FormControl } from 'react-bootstrap'
import { text } from 'stream/consumers'
import styled, { css } from 'styled-components'

export const InputFormControl = styled(FormControl)``

interface IAutoComplete {
  primary?: boolean
}

export const AutoComplete = styled.div<IAutoComplete>`
  position: relative;
  width: 80%;
  z-index: 3;
  margin-top: ${(props) => (props.primary ? '55px' : '0')};
  transition: all ease 0.2s;
  > ul {
    position: absolute;
    padding: 0;

    width: 80%;
    z-index: 2;

    box-shadow: 1px 3px 5px #333;
    background: #fff;

    transition: all ease 0.2s;

    /* overflow: hidden;
    overflow-y: auto; */
    min-height: 0px;
    max-height: ${(prop) => (prop.primary ? '100px' : '0px')};
    opacity: ${(prop) => (prop.primary ? '1' : '0')};

    border-radius: 0 0 5px 5px;

    list-style-type: none;
    li:nth-last-child(n + 2) {
      border-bottom: 1px solid #ccc7c7;
    }
    li {
      padding: 5px 10px;
    }
  }
`

export const ContainerFrequencia = styled.div`
  .header {
    display: flex;
    justify-content: space-between;

    .container {
      display: flex;
      flex-direction: column;

      /* width: 100%; */
      padding: 0px;

      > div:first-child {
        display: flex;
        align-items: center;
        width: 80%;
        gap: 8px;

        /* input {
          width: 80%;
        } */

        .button-search {
          display: flex;
          align-items: center;
          width: 35px;
          height: 35px;
          justify-content: center;
          padding: 2px 4px;
          background-color: #007bff;
          color: #fff;
          border: none;
          border-radius: 4px;
          cursor: pointer;
        }

        .search {
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 15px;
            height: 15px;
            color: #fff;
            vertical-align: middle;
          }
        }

        .button-add {
          display: flex;
          align-items: center;
          width: 35px;
          height: 35px;
          background: transparent;
          justify-content: center;
          padding: 2px 4px;
          /* background-color: #007bff; */
          color: #fff;
          border: none;
          border-radius: 4px;
          cursor: pointer;
        }

        span {
          /* border: 1px solid #0B5ED7; */
          border-radius: 8px;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          > svg {
            width: 30px;
            height: 30px;
            color: #0b5ed7;
          }
        }
      }

      /* > div:last-child {
        display: flex;
        align-items: center;
        width: 60%;
      } */

      /* > div:last-child:focus {
        margin-top: -10px;
        transition: all ease 0.2s;

        overflow: hidden;
        overflow-y: auto;
        min-height: 0px;
      } */
    }

    > div:last-child {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      width: 35%;

      @media screen(max-width: 735px) {
        button {
          font-size: 10px;
        }
      }

      /* input:button {
        width: 30%;
      } */
    }
  }

  .tableStyle {
    flex: 1;
    overflow: auto;
    margin-top: 2rem;
    .react-bootstrap-table {
      table {
        width: 100%;
        vertical-align: middle;
        border-collapse: collapse;
        min-width: 600px;

        td {
          :nth-child(5) {
            svg {
              width: 40px;
              height: 40px;
              transition: all ease 0.2s;

              &:hover {
                color: #0d6efd;
              }
            }
          }
        }

        thead {
          tr > th {
            text-align: center;
            :nth-last-child(n-2) {
              width: 100px;
            }
            :last-child {
              width: 135px;
            }
          }
        }

        th {
          background-color: rgba(0, 0, 0, 0.05);
          padding: 0.525rem;
          text-align: left;
          color: #000;
          font-size: 0.875rem;
          line-height: 1.6;
        }

        .representante-row {
          font-size: 0.875rem;
          /* --bs-table-striped-bg: rgba(0, 0, 0, 0.05); */
          background-color: rgba(0, 0, 0, 0.05);
          color: #000;
          line-height: 1.6;

          &:hover {
            --bs-table-hover-bg: rgba(0, 0, 0, 0.025);
          }

          tr > th {
            :nth-last-child(n-1) {
              border-top-color: #000;
              border-color: none;
            }
          }
        }

        /* .status-pessoa-presente {
          .status-pessoa-nome-realcado {
            color: #ff7f00;
          }

          /* &:hover {
            --bs-table-hover-bg: rgba(0, 0, 0, 0.025);
          } */
        //} */

        .status-pessoa-ausente {
          .status-color-red {
            span {
              color: #ff0000;
              text-decoration: underline;
            }
          }
        }

        tr {
          --bs-table-striped-bg: none;

          &:hover {
            --bs-table-hover-bg: #f8f9fa;
          }
        }
        thead {
          tr > th {
            text-align: center;
            :nth-last-child(n-2) {
              width: 100px;
            }
            :last-child {
              width: 135px;
            }
          }
        }

        td {
          padding: 1rem;
          padding: 0.525rem;
          line-height: 1.6;
          text-align: center;
          text-decoration: none;

          &:first-child {
            width: 20%;
            p {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: center;
              text-align: center;
              font-size: 1rem;
              margin: 0 auto;
            }

            select {
              width: 100px;
              height: 30px;
              border-radius: 5px;
              border: 1px solid rgb(208, 213, 220);
              width: 60%;
            }
          }

          &:last-child {
            input {
              width: 70%;
            }
          }
        }
      }
    }
  }
`

export const Buttons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const ContainerFooter = styled.div`
  /* margin-top: 20px; */
  display: flex;
  > div {
    display: flex;
    margin: 0 auto;

    > div:first-child {
      display: flex;
      justify-content: center;
      flex-direction: column;

      width: 100px;
      height: 42px;

      > span {
        height: 21px;
        display: flex;
        flex-direction: column;
      }
    }

    > div:last-child {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100px;
      height: 42px;
      /* height: 20px; */

      > span {
        height: 21px;
      }

      .progrss {
        width: 100px;
        height: 10px;
        margin: 5px auto;
      }
      /* span > div {
        width: 100px;
        height: 10px;
      } */
    }

    /* > div {
      span {
        width: 100px;
        height: 20px;

        > div {
          width: 100px;
          height: 10px;
        }
      }
    } */
  }
`

export const ContainerGif = styled.div`
  position: relative;
  width: 330px;
  height: 200px;
  overflow: hidden;
  /* position: relative;
  width: 300px;
  height: 200px;
  overflow: hidden;
  /* Defina as coordenadas de recorte: clip(top, right, bottom, left) */

  img {
    margin-top: -85px;
  }
`

type WidthContainerTable = {
  WidthContainer?: number
}

export const ContainerTable = styled.div<WidthContainerTable>`
  width: 100%;
  overflow-y: scroll;
  table {
    width: 100%;
    border: 1px solid rgb(208, 213, 220);

    /* th {
      text-align: center;
    } */

    td,
    th {
      >div{
        display: flex;
        justify-content: center;
        align-items: center;
      }
      border: 1px solid rgb(208, 213, 220);
      text-align: center;
      /* ${(prop) =>
        prop.WidthContainer === 0
          ? `width:calc(100% / 6)`
          : `width:calc(${prop.WidthContainer} / 6)`} */
      /* width: calc(1029px / 6); */
      width: ${(prop) =>
        prop.WidthContainer === 0
          ? `calc(100% / 9)`
          : `calc(${prop.WidthContainer}px / 6)`};
      height: 38px;
    }

    th {
      line-height: 1.6;
      padding: 0.525rem;
      background: rgba(0, 0, 0, 0.05);
      :nth-child(2) {
        white-space: nowrap;
      }
    }
    td {
      padding: 0.525rem;
      line-height: 1.6;
      :nth-child(6) {
        svg {
          width: 20px;
          height: 40px;

          @media (max-width: 480px) {
            width: 25px;
            height: 25px;
          }
          transition: all ease 0.2s;

          &:hover {
            color: #0d6efd;
          }
        }
      }
    }

    tbody,
    thead {
      width: 100%;
    }

    tbody > tr > tr {
      width: 100%;
    }

    .DragDropCOntainer {
      width: 100% !important;
    }
  }
`

/// //// Nova versao styled

export const ContainerFreq = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .FirstContainer {
    .SelectLib {
      width: 100%;
    }

    width: 100%;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap-reverse;

    > div {
      display: flex;
      :first-child {
        width: 60%;
        @media (max-width: 480px) {
          width: 100%;
        }

        > span {
          display: flex;
        }
      }
      :last-child {
        width: 30%;
        justify-content: flex-end;
        white-space: nowrap;
        @media (max-width: 480px) {
          margin-bottom: 10px;
          width: 100%;
          margin-left: auto;
          span {
            width: 100%;
          }

          button {
            width: 100%;
          }
        }
      }
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  > div:nth-child(2) {
    select {
      border-radius: 2px;
      border-color: rgb(208, 213, 220);
    }
  }

  > div:last-child {
    /* margin-top: 15px; */
  }
`

export const ContainerTost = styled.div`
  h6 {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #07bc0c;
    font-weight: 500;
  }

  p {
    width: 245px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
