import { border, Text, useToast } from '@chakra-ui/react'
import React, { ChangeEventHandler, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import {
  BuscarPessoa,
  GetAllUser,
  PostCreateRepresentante,
} from '../../service'
import { AxiosError } from 'axios'

interface Props {
  pessoaID: number
  loadPage: () => void
}

const AdicionarPessoaEvento = ({ pessoaID, loadPage }: Props) => {
  const toast = useToast()
  const [value, setValue] = useState('')
  const [suggestions, setSuggestions] = useState<IStateReducerNONOptional[]>([])
  const [pessoas, setPessoas] = useState<IStateReducerNONOptional[]>()
  useEffect(() => {
    BuscarPessoa().then((res) => {
      const newPessoas: IStateReducerNONOptional[] = res.data
      setPessoas(newPessoas)
    })
  }, [])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
    setValue(inputValue)

    // Filtra os usuários com base no valor do input
    const filteredUsers = pessoas!.filter((user) =>
      user.nome?.toLowerCase().includes(inputValue?.toLowerCase() ?? ''),
    )

    filteredUsers.sort((a, b) => {
      const nomeA = a.nome.toUpperCase()
      const nomeB = b.nome.toUpperCase()

      if (nomeA < nomeB) {
        return -1
      }
      if (nomeA > nomeB) {
        return 1
      }

      return 0
    })

    if (inputValue) {
      setSuggestions(
        filteredUsers.filter((pessoa) => pessoa.pessoaId !== pessoaID),
      )
    } else {
      setSuggestions([])
    }
  }

  const handleSuggestionClick = (suggestion: IStateReducerNONOptional) => {
    setValue(suggestion.nome)
    setSuggestions([])
  }

  async function addRepresentante(pessoaID: number, representateNome: string) {
    const representanteID = pessoas?.find(
      (pessoa) => pessoa.nome === representateNome,
    )?.pessoaId
    const representante: Representante = {
      pessoaId: pessoaID,
      representantes: [Number(representanteID)],
    }

    const res = await PostCreateRepresentante(representante)
      .then((res) => {
        loadPage()
        toast({
          title: `${representateNome} foi adicionado`,
          status: 'success',
          position: 'bottom-right',
        })
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          toast({
            title: `Algo deu errado`,
            description: error.response?.data.message,
            status: 'error',
            position: 'bottom-right',
          })
        }
      })

    setValue('')
  }
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div>
        <Text fontFamily="inter-light" fontSize="20px">
          Adicionar Representante
        </Text>
        <input
          style={{
            padding: '20px 20px 20px 20px',
            width: '100%',
            border: '1px solid gray',
            height: '40px',
            borderRadius:
              suggestions.length > 0
                ? '10px 10px 0px 0px'
                : '10px 10px 10px 10px',
          }}
          type="text"
          placeholder="Digite o nome de um usuário"
          value={value}
          onChange={(event) => handleChange(event)}
        />

        <ul
          style={{
            width: '100%',
            maxHeight: '200px',
            overflowY: 'scroll',

            border:
              suggestions.length > 0 ? '1px solid gray' : '0px solid gray',
            borderRadius: '0px 0px 15px 15px',
          }}
        >
          {suggestions.map((suggestion, index) => (
            <Text key={index} onClick={() => handleSuggestionClick(suggestion)}>
              {suggestion.nome}
            </Text>
          ))}
        </ul>
        {value && (
          <Button
            style={{ marginBottom: '20px', backgroundColor: 'green' }}
            onClick={() => addRepresentante(pessoaID, value)}
          >
            Adicionar
          </Button>
        )}
      </div>
    </div>
  )
}

export default AdicionarPessoaEvento
