/* eslint-disable @typescript-eslint/no-unsafe-return */
import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  body{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  body, input, textarea, button{
    font-family: 'Sora', sans-serif;
    font-weight: 400;
    font-size: .75rem !important;
  }

  th{
    color: rgb(0, 74, 128);
  }

  .card {
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.text};
    border-color: ${(props) => props.theme.colors.border};
    border-width: 0.5px;
    margin: 10px 0;
  }

  .card-header {
    border-color: ${(props) => props.theme.colors.border};
    border-width: 0.5px;
    font-size: 18px;
    border-top: 3px solid rgb(0, 74, 128);
    padding: 0.40rem 1.25rem;
    background-color: ${(props) =>
      (props.theme.title === 'dark' && 'black') || 'white'};
  }

  .ContainerPrimary{
    width: 80vw;
    margin: auto;
    @media (max-width: 480px) {
      width: 100vw;
    }
  }

  .active {
    border-color: #28a745 !important;
    padding-right: calc(1.5em + 0.75rem) !important;
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%278%27 height=%278%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%2328a745%27 d=%27M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e');
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }

  .inactive {
    border-color: #dc3545 !important;
    padding-right: calc(1.5em + 0.75rem) !important;
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27 viewBox=%270 0 12 12%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e');
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
`

type IPropStyle = {
  isPrivet: boolean
}

export const ContainerCard = styled.div<IPropStyle>`
  width: ${(prop) => (prop.isPrivet ? '80%' : '100%')};
  margin: auto;
  height: 88vh;

  @media (max-width: 768px) {
    width: ${(prop) => (prop.isPrivet ? '95%' : '100%')};
  }
`

interface IPropEventos {
  openModal: boolean
  newNotify: boolean
}

export const COntainerNotfy = styled.div<IPropEventos>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  position: relative;

  > span {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 90%;

    @media (max-width: 480px) {
      font-size: 12px;
    }
  }

  a {
    text-decoration: none;
    color: #333;
  }

  > div {
    button {
      z-index: 11;
      cursor: pointer;

      padding: 2px 0 0;
    }
  }

  #handleDeleteContainer {
    z-index: 150;
  }

  .iconNotification {
    position: relative;
    width: 41px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* > span {
      position: absolute;
      z-index: 12;
      font-size: 8px;
      right: 0;
      width: 12px;
      height: 12px;

      background: #fff;
      border: 1px solid #adb5bd;
      border-radius: 50%;

      display: grid;
      place-content: center;

      margin-right: -3px;
      margin-top: -4px;
    } */

    svg {
      width: 20px;
      height: 20px;
      color: #fff;
      path {
        width: 20px;
        height: 20px;
      }
      transition: all ease 0.3s;
      animation: ${(prop) =>
        prop.newNotify ? 'Notification 1s infinite alternate;' : ''};
    }
  }

  @keyframes Notification {
    from {
      color: #fff;
    }

    to {
      color: #ffd75e;
    }
  }
  svg {
    width: 20px;
    height: 20px;
  }
  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;

    .containerModalSimple {
      z-index: 99;
      font-size: 12px;
      margin-top: 25px;
      margin-right: -20px;
      /* position: relative; */
      position: absolute;
      transition: all ease 0.3s;
      width: ${(prop) => (prop.openModal ? '80vw' : '305px')};

      .containerULModalSimple {
        width: 100%;
        /* 
        ${(prop) =>
          prop.openModal ? '80vw' : 'max-height: 200px; min-height: 0px;'} */
        max-height: 200px;
        min-height: 0px;

        margin: 0;
        padding: 0;

        list-style-type: none;

        background: #fff;
        box-shadow: 1px 3px 3px 3px #adb5bd;
        border-radius: 3px;

        overflow-y: auto;

        li {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 2px 5px;
          height: 60px;
          transition: all ease 0.2s;

          :hover {
            background: #adb5bd43;
          }

          svg {
            color: #ffd75e;
          }

          p {
            margin: 0;
            width: 185px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          > a:first-child {
            height: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0 10px 0 0px;
            div {
              display: flex;
              align-items: center;
              gap: 10px;
              color: #fdc312;
            }
            span {
              /* width: 10px; */
              height: 20px;
              p {
                width: auto;
                font-size: 8px;
                color: #333;
              }
            }
          }

          > div:last-child {
            a {
              height: 100%;

              display: flex;
              align-items: center;
            }
            display: flex;
            justify-content: space-between;
            align-items: center;
            svg {
              color: #333;
              width: 20px;
              height: 20px;
            }
            > div {
              display: flex;
              gap: 5px;

              span {
                padding: 5px;
                :hover {
                  background: 1px solid #333;
                }
              }
            }
          }
        }
      }

      .containerULModalComplete {
        width: 100%;
        max-height: 80vh;
        min-height: 0px;

        margin: 0;
        padding: 0;

        list-style-type: none;

        background: #fff;
        box-shadow: 1px 3px 3px 3px #adb5bd;
        border-radius: 3px;

        overflow-y: auto;

        li {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 2px 5px;
          height: 55px;
          transition: all ease 0.2s;

          :hover {
            background: #adb5bd43;
          }

          :nth-child(n + 2) {
            border-top: 1px solid #adb5bd;
          }

          svg {
            color: #ffd75e;
          }

          p {
            margin: 0;
            width: 185px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          > a:first-child {
            display: flex;
            width: 100%;
            justify-content: space-between;
            div {
              width: calc(100% / 3);
              display: flex;
              align-items: center;
              gap: 10px;
              color: #fdc312;
            }
            span {
              width: calc(100% / 3);

              :nth-child(2) {
                display: flex;
                justify-content: center;
              }
              /* width: 10px; */
              height: 20px;
              p {
                width: auto;
                font-size: 8px;
              }
            }
          }

          > div:last-child {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            a {
              display: flex;
              width: calc((100% / 3) * 2);

              > div {
                width: calc((100% / 3) * 2);

                :nth-child(2) {
                  display: flex;
                  justify-content: center;
                  p {
                    width: auto;
                  }
                }

                :last-child {
                  display: flex;
                  justify-content: center;
                  p {
                    width: auto;
                  }
                }
              }
            }

            svg {
              color: #333;
              width: 20px;
              height: 20px;
            }
            > div {
              display: flex;
              gap: 5px;
            }
          }
        }
      }

      a {
        :nth-child(n + 2) {
          border-top: 1px solid #adb5bd;
        }
      }

      .liHeader {
        height: 100px;
        padding: 10px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        > div {
          display: flex;
          align-items: center;
          gap: 10px;

          input {
            width: 135px;
          }

          button {
            border: 1px solid #fdc21250;
            color: #fdc312;
          }
        }
      }
    }

    .liVerMais {
      /* display: flex !important; */
      flex-direction: row !important;
      height: 30px;
      align-items: center;
      border-bottom: 1px solid #adb5bd84;
      padding: 10px !important;
      /* color: #fdc312;
      text-decoration: underline; */

      > div:first-child {
        width: 50%;
        color: #333;
        font-size: 15px !important;
      }

      > div:last-child {
        width: 25%;
      }
      :hover {
        background: #fff !important;
      }

      button {
        font-size: 10px !important;
        width: 100%;
      }
    }
  }
`
