import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { Button, Card, Form, OverlayTrigger } from 'react-bootstrap'
import { FaEdit, FaSearch, FaTrash } from 'react-icons/fa'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'

// import paginationFactory from "react-bootstrap-table2-paginator";

import { Container } from './styled'
import { Link } from 'react-router-dom'
import { renderTooltipDelete, renderTooltipEdit } from '../../utils/Tooltip'
import { BuscarPessoa, DeletePessoa } from '../../service'
import { DetalheDoEvento } from '../../components/Modal'
import { customTotal } from '../../utils/functions'
import { ContextAPI } from '../../hooks/useContextApi'
import WebShare from '../../components/WebShare'
import { AiOutlineLink } from 'react-icons/ai'
import VincularPopup from './VincularPopup'

export function ListCadaster() {
  const [showVincularPopup, setShowVincularPopup] = useState(false)
  const [vincularPessoa, setvincularPessoa] = useState<Pessoa>()
  const {
    modalEvent,
    pessoas,
    handleModalCreate,
    handleModalDelete,
    handleModalSucess,
    handleAlterName,
    handleChangeReload,
  } = useContext(ContextAPI)

  const [pessoasList, setPessoasList] = useState<Pessoa[]>([])
  const [pessoasListFixed, setPessoasListFixed] = useState<Pessoa[]>([])
  const [reloadPage, setReloadPage] = useState(false)
  const [search, setSearch] = useState<string>('')
  const [teste, setTeste] = useState<boolean>(false)

  const [id, setId] = useState<number>(0)

  async function getPessoas() {
    const response = await BuscarPessoa()

    const pessoas: Pessoa[] = response.data

    pessoas.sort((a, b) => {
      const nomeA = a.nome?.toUpperCase()
      const nomeB = b.nome?.toUpperCase()

      if (nomeA < nomeB) {
        return -1
      }
      if (nomeA > nomeB) {
        return 1
      }

      return 0
    })
    setPessoasList(pessoas)
    setPessoasListFixed(pessoas)
  }

  useEffect(() => {
    handleAlterName('Lista de Convidados')
    handleChangeReload()
    getPessoas()
  }, [])

  function HandleSearch(e: string) {
    setSearch(e)
    if (e === '') {
      setPessoasList(pessoasListFixed)
      return
    }

    setPessoasList((prevState) => {
      return pessoasListFixed.filter((event) => {
        const nome = event.nome?.toLowerCase().includes(e?.toLowerCase())
        const cargo = event.cargo?.toLowerCase().includes(e?.toLowerCase())
        const cpf = event.cpf
          ?.replaceAll('.', '')
          ?.replaceAll('-', '')
          ?.includes(e?.replaceAll('.', '')?.replaceAll('-', ''))

        return nome || cargo || cpf
      })
    })
  }

  const HandleDeleteOpenModal = (id: number) => {
    handleModalDelete(true)
    setId(id)
    getPessoas()
  }

  const HandleDelete = async () => {
    const response = await DeletePessoa(id).finally(() => handleChangeReload())

    if (response.status === 200) {
      handleModalDelete(false)
      handleModalSucess(true)
      const totalPessoa = pessoasList.filter((e) => e.pessoaId !== id)
      setPessoasList(totalPessoa)
      setPessoasListFixed(totalPessoa)
    } else {
      handleModalCreate(true)
    }
  }

  const ColumnsFontes = [
    {
      dataField: 'nome',
      text: 'Nome',
      sort: true,
      formatter: (
        column: any,
        row: Pessoa,
        rowIndex: any,
        formatExtraData: any,
      ) => {
        return (
          <>
            {row.pessoaEventos.length ? (
              <>{row.nome}</>
            ) : (
              <p style={{ margin: '0', color: '#f5a851' }}>{row.nome}</p>
            )}
          </>
        )
      },
    },
    { dataField: 'cargo', text: 'Cargo', sort: true },
    {
      dataField: 'editar',
      text: 'Editar',
      formatter: (
        column: any,
        row: Pessoa,
        rowIndex: any,
        formatExtraData: any,
      ) => {
        return (
          <div>
            <Link to={`/pessoa/${row.pessoaId}`} tabIndex={-1}>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltipEdit}
              >
                <Button variant="link">
                  <FaEdit style={{ color: '#004a80' }} />
                </Button>
              </OverlayTrigger>
            </Link>
          </div>
        )
      },
    },
    {
      dataField: 'excluir',
      text: 'Excluir',
      formatter: (
        column: any,
        row: Pessoa,
        rowIndex: any,
        formatExtraData: any,
      ) => {
        return (
          <div>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltipDelete}
            >
              <Button
                variant="link"
                onClick={() => HandleDeleteOpenModal(row.pessoaId)}
              >
                <FaTrash style={{ color: '#ac0505' }} />
              </Button>
            </OverlayTrigger>
          </div>
        )
      },
    },
    {
      dataField: 'link',
      text: 'Vincular',
      formatter: (
        column: any,
        row: Pessoa,
        rowIndex: any,
        formatExtraData: any,
      ) => {
        return (
          <div>
            <Button
              variant="link"
              onClick={() => {
                setShowVincularPopup(true)
                setvincularPessoa(row)
              }}
            >
              <AiOutlineLink style={{ color: '#004a80' }} />
            </Button>
          </div>
        )
      },
    },
  ]
  const options = {
    paginationSize: 5,
    pageStartIndex: 1,
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '30',
        value: 30,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: 'Todos',
        value: pessoasList.length,
      },
    ],
  }
  return (
    <div>
      {vincularPessoa && (
        <VincularPopup
          isOpen={showVincularPopup}
          pessoa={vincularPessoa!}
          onSave={() => {
            setShowVincularPopup(false)
            setvincularPessoa(undefined)
          }}
          onClose={() => {
            setShowVincularPopup(false)
            setvincularPessoa(undefined)
          }}
        />
      )}

      <DetalheDoEvento
        sucessOrError={false}
        showModal={modalEvent.delete}
        handleClose={() => handleModalDelete(false)}
        mensageHeader="Deletar"
        mensageBody="Gostaria de deletar essa pessoa?"
        nextFunction={true}
        handleNextFunction={() => HandleDelete()}
      />

      <DetalheDoEvento
        sucessOrError={true}
        showModal={modalEvent.sucess}
        handleClose={() => handleModalSucess(false)}
        mensageHeader="Deletado"
        mensageBody="Pessoa deletada com sucesso."
        nextFunction={true}
      />

      <DetalheDoEvento
        sucessOrError={false}
        showModal={modalEvent.create}
        handleClose={() => handleModalCreate(false)}
        mensageHeader="Erro"
        mensageBody="Tivemos um erro ao deletar a pessoa. Tente novamente."
        nextFunction={true}
      />

      <Container>
        <div>
          <Link to={`cadastrar`} tabIndex={-1}>
            <Button style={{ whiteSpace: 'nowrap' }} variant="primary">
              Novo cadastro
            </Button>
          </Link>
          <Link to={`eventosCadastrados`} tabIndex={-1}>
            <Button style={{ whiteSpace: 'nowrap' }} variant="primary">
              Lista de eventos
            </Button>
          </Link>
          {/* <span></span> */}
        </div>
        <Form.Control
          placeholder="Pesquise por nome, CPF ou cargo"
          value={search}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            HandleSearch(e.target.value)
          }
        />
        <div>
          <BootstrapTable
            // loading={true}
            bordered={true}
            bootstrap4
            striped
            hover
            condensed
            keyField="pessoaId"
            columns={ColumnsFontes}
            data={pessoasList}
            pagination={paginationFactory(options)}
            defaultSortDirection="asc"
          />
        </div>
      </Container>
    </div>
  )
}
