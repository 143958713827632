import styled from 'styled-components'

export const COntainerLogin = styled.div`
  width: 100%;
  height: 92vh;
  background: #cccccc81;
  display: flex;
  justify-content: center;
  align-items: center;

  form {
    /* background: #fff; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;

    width: 300px;
    height: 300px;

    padding: 0px 15px;

    margin-top: -55px;

    span {
      display: flex;
      justify-content: center;
      img {
        height: 80px;
      }
    }

    h1 {
      text-align: center;
      color: #4a4a4a;
    }

    h3 {
      text-align: center;
      color: #4a4a4a;
      font-size: 1.2rem;
    }

    .ErrorContainer {
      width: 100%;
      background: #f8d7da;
      color: rgb(116, 31, 39);
      padding: 10px;
      border: 1px solid rgba(116, 31, 39, 0.76);
      border-radius: 3px;
      text-align: center;
    }

    .ButtonLogin {
      display: flex;
      a {
        width: 50%;
        button {
          width: 100%;
        }
      }
      button {
        width: 50%;
      }
      gap: 10px;
      width: 100%;
    }
  }
`
