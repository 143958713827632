// import React, {  } from 'react'
import React, { useEffect, useState, ChangeEvent, useContext } from 'react'
import io from 'socket.io-client'
import { ContextAPI } from '../../hooks/useContextApi'
import {
  IoIosClose,
  IoIosNotifications,
  IoIosShareAlt,
  IoIosNotificationsOutline,
} from 'react-icons/io'
import { BiInfoCircle } from 'react-icons/bi'
import { Button, Card, Form } from 'react-bootstrap'
import { BsArrowLeftShort, BsCalendar3 } from 'react-icons/bs'
import WebShare from '../WebShare'
import { COntainerNotfy } from '../../assets/styles/global'
import { deleteNotfy, responseNotfy } from '../../service'
import { Link } from 'react-router-dom'
import Stomp from 'stompjs'
import SockJS from 'sockjs-client'
import WebSocketComponent from './ws'
// import WebSocket from 'websocket'
// import { Container } from './styles';

const HaderNotification: React.FC = () => {
  const [dataList, setDataList] = React.useState<any[]>([])
  const {
    nameHeader,
    MaskFormatBR,
    notificacao,
    eventos,
    handleChangeReload,
    reloadTeste,
    handleChangeNewNotify,
    newNotificacao,
    urlRouter,
  } = React.useContext(ContextAPI)

  const [open, setOpen] = React.useState<boolean>(false)
  const [openNotify, setOpenNotify] = React.useState<boolean>(false)
  const [dateSearch, setDateSearch] = React.useState<string>('')
  const isNotRefrash = false
  const [socket, setSocket] = useState<any>(null)
  const [messages, setMessages] = useState<any[]>([])

  React.useEffect(() => {
    // Função de tratamento de clique fora do container
    const handleClickOutside = (event: Event) => {
      const container = document.getElementById(
        'ContainerDropDownClick',
      ) as HTMLInputElement // Substitua "container" pelo ID do seu container
      const containerInput = document.getElementById(
        'OpenNot',
      ) as HTMLInputElement // Substitua "container" pelo ID do seu container

      if (container && !container.contains(event.target as Node)) {
        if (containerInput && !containerInput.contains(event.target as Node)) {
          setOpen(false)
          setOpenNotify(false)
        }
      }
    }
    // Adiciona o event listener global para detectar os cliques fora do container
    const addEvent = document.addEventListener(
      'click',
      handleClickOutside,
    ) as void
    // Função de limpeza ao desmontar o componente
    return () => {
      const removeEvent = document.removeEventListener(
        'click',
        handleClickOutside,
      ) as void
    }
  }, [])

  React.useEffect(() => {
    async function response() {
      const response = await responseNotfy(0)
      setDataList(response.data)
    }

    response()
    // if (!isNotRefrash) {
    //   isNotRefrash = true
    // }
  }, [reloadTeste])

  const handleDeleteItem = (id: number) => {
    const remove = dataList.filter((e) => e.notificacaoId !== id)
    setTimeout(() => {
      setDataList(remove)
    }, 100)
    setOpen(true)
  }

  const shareData = {
    title: 'MDN',
    text: 'Learn web development on MDN!',
    url: 'https://developer.mozilla.org',
  }

  const handleOpenWebShare = async () => {
    return await navigator.share(shareData)
  }

  const handleLocaleName = (idEvent: number) => {
    const eventosLocale = eventos.filter((e) => e.eventoId === idEvent)
    return eventosLocale[0] === undefined
      ? 'Nome nao encontrado'
      : eventosLocale[0].nome
  }

  // #adb5bd84

  return (
    <>
      <Card.Header>
        <COntainerNotfy newNotify={newNotificacao} openModal={openNotify}>
          <span>
            {location.pathname !== '/' && (
              <Link
                onClick={() => {
                  localStorage.removeItem('EventRegister')
                }}
                to={urlRouter}
              >
                <BsArrowLeftShort />
              </Link>
            )}
            {nameHeader}
          </span>
        </COntainerNotfy>
      </Card.Header>

      {/* <WebSocketComponent /> */}
      {/* <WebSocketComponent /> */}
    </>
  )
}

export default HaderNotification
