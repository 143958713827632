import styled from 'styled-components'

export const Container = styled.div`
  .form-control {
    margin-bottom: 10px;
  }
  > div {
    :first-child {
      display: flex;
      justify-content: space-between;
      @media (max-width: 768px) {
        flex-wrap: wrap;
      }
      span {
        width: 30%;
        display: flex;
        gap: 10px;
        @media (max-width: 768px) {
          width: 100%;
          margin-top: 10px;
        }
      }
      margin-bottom: 10px;
    }

    :last-child {
      table {
        thead {
          tr > th {
            vertical-align: middle;
            width: calc(85% / 2);
            text-align: center;

            @media (max-width: 768px) {
              width: calc(60% / 2);
            }
            height: 38px;

            :nth-child(4) {
              width: 50px;
            }

            :nth-child(5) {
              width: 60px;
            }

            :last-child {
              text-align: center;

              width: 65px;

              @media (max-width: 768px) {
                width: 18%;
              }
              @media (max-width: 480px) {
                width: 30%;
              }
            }
          }
        }
        tbody {
          tr {
            td {
              vertical-align: middle;
              text-align: center;

              height: 38px;
              :last-child {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
`
