import styled, { css } from 'styled-components'
import { FormControl, Button } from 'react-bootstrap'

export const InputFormControl = styled(FormControl)`
  ${({ error }) =>
    error &&
    css`
      color: #fc5050;
      border: 1px solid #fff;
      border-color: #fc5050 !important;
    `}
`
export const ButtonContainer = styled(Button)`
  width: 100px;

  &:disabled {
    opacity: 0.5;
  }
`

export const StyleContainer = styled.div`
  width: 100%;
  margin: auto;

  table {
    width: 100%;
    vertical-align: middle;
  }

  td,
  th {
    height: 38px;

    border: 1px solid #ced4da;
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .ContainerButton {
    display: flex;
    justify-content: center;
    gap: 12px;
  }

  .FatherTableStyle {
    min-height: 10px;
    max-height: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  .tableStyle {
    width: 50%;
    @media (max-width: 768px) {
      width: 80%;
    }
    @media (max-width: 480px) {
      width: 100%;
    }
    tr,
    td {
      p {
        margin: 0;
        width: 170px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: 768px) {
          width: 100px;
        }
      }
      border: 1px solid #ced4da;
      border-radius: 5px;
      padding: 10px;
      td:last-child {
        text-align: center;
      }
    }
  }
`

export const ContainerCadaster = styled.form`
  .active {
    border-color: #28a745 !important;
    padding-right: calc(1.5em + 0.75rem) !important;
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%278%27 height=%278%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%2328a745%27 d=%27M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e');
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }

  .inactive {
    border-color: #dc3545 !important;
    padding-right: calc(1.5em + 0.75rem) !important;
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27 viewBox=%270 0 12 12%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e');
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
  svg {
    /* color: #dc3545; */
    width: 20px;
    height: 20px;
  }
  display: flex;
  position: relative;

  /* form {
    :nth-child(n-3) {
      margin-top: 10px;
    }
  } */
  > div {
    :first-child {
      border-right: 1px solid #3d3d3d19;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (max-width: 765px) {
        border-right: 0px solid #3d3d3d19;
      }
    }
    :nth-child(2) {
      padding: 10px;
    }
  }

  .LeftContainer {
    width: 0%;
    @media (max-width: 765px) {
      width: 0%;
      /* height */
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > div:first-child {
      display: grid;
      place-items: center;
      background: #6a6a6a37;
      border-radius: 50%;
      width: 160px;
      height: 160px;
      cursor: pointer;
      position: relative;

      > span {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        > svg {
          width: 50px;
          height: 50px;
          color: #3d3d3da9;
        }
        > strong {
          margin: 0;
          color: #3d3d3da9;
        }
      }

      img {
        width: 160px;
        border-radius: 50%;
        height: 100%;
      }

      input {
        position: absolute;
        height: 160px;
        width: 160px;
        opacity: 0;
        border-radius: 50%;
        top: 0;
      }

      @media (max-width: 765px) {
        position: absolute;
        right: 0;
        left: 0;
        margin: auto;
      }
    }
  }

  .RightContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    @media (max-width: 765px) {
      margin-top: 160px;
      width: 100%;
    }

    .Visualizar {
      display: grid;
      place-items: center;
      background: #6a6a6a37;
      border-radius: 50%;
      width: 160px;
      height: 160px;
      cursor: pointer;
      position: relative;
      /* border: 5px solid #333; */

      > span {
        border: 5px solid #333;
        border-radius: 50%;

        /* height: 100%; */
        display: flex;
        flex-direction: column;
        align-items: center;
        > svg {
          width: 50px;
          height: 50px;
          color: #3d3d3da9;
        }
        > strong {
          margin: 0;
          color: #3d3d3da9;
        }

        button {
          position: absolute;
          top: 0;
          left: 120px;
          border-radius: 50%;
          border: 0px;
        }
      }

      img {
        width: 150px;
        border-radius: 50%;
        height: 100%;
      }

      input {
        position: absolute;
        height: 160px;
        width: 160px;
        opacity: 0;
        border-radius: 50%;
        top: 0;
      }

      @media (max-width: 765px) {
        position: absolute;
        right: 0;
        left: 0;
        margin: auto;
        margin-top: -180px;
      }
    }
  }

  .addContact {
    display: flex;
    flex-direction: column;
    width: 100%;
    > div {
      display: flex;
      gap: 10px;

      :last-child {
        flex-direction: column;
        gap: 0;
      }
    }

    label {
      text-align: center;
      border: 1px solid #ced4da;
      width: 100%;
      border-radius: 3px 3px 0px 0;
      margin-top: 5px;
      /* height:40px; */
    }
    ul {
      min-height: 0px;
      max-height: 100px;
      overflow-y: scroll;
      padding: 0;
      li {
        padding: 10px;
        list-style-type: none;
        border: 1px solid #ced4da;
        height: 35px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        svg {
          color: #dc3545;
        }
        padding: 0px 5px;

        p {
          margin: 0;
          display: flex;
          gap: 15px;
          align-items: center;

          svg {
            color: #0d6efd;
          }
        }
      }
    }
  }

  .EderecoCOntainer {
    margin-bottom: 30px;
    label {
      width: 100%;
      text-align: center;
    }
    border: 1px solid #ced4da;
    padding: 5px;
    border-radius: 5px;
    width: 100%;
    > div {
      :first-child > div {
        gap: 10px;
        :nth-child(n + 1) {
          display: flex;
          margin-top: 10px;
        }
        :nth-child(n + 4) {
          display: flex;
          margin-top: 10px;
          flex-direction: column !important;
        }
      }

      button {
        margin-top: 10px;
        width: 100%;
      }

      :last-child {
        width: 100%;
        text-align: center;
        ul {
          margin-top: 10px;
          width: 100%;
          min-height: 0;
          max-height: 100px;
          overflow-y: scroll;
          padding: 0;
          li {
            text-align: center;
            border: 1px solid #ced4da;

            width: 100%;
            display: flex;
            border-bottom: 1px solid #ced4da;
            justify-content: space-between;
            p:nth-child(n + 1) {
              width: calc(85% / 3);
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              border-left: 1px solid #ced4da;
            }
            p {
              margin: 0;
            }
            span {
              border-left: 1px solid #ced4da;
              width: 15%;
              text-align: center;
            }
            svg {
              width: 37px;
            }
          }
        }
      }
    }
  }

  .eventosContainer {
    border: 1px solid #ced4da;
    border-radius: 5px;

    width: 100%;

    text-align: center;
    padding: 5px;
    > label {
      margin: 10px 0;
    }
    > div:nth-child(2) {
      display: flex;
      gap: 5px;

      > div {
        display: flex;
        flex-direction: column;
      }
      .OpenModal {
        position: relative;

        > select {
          border: 1px solid #bdc3c9;
          border-radius: 5px;
          height: 38px;
          width: 100%;
          text-decoration: none;
          /* position: absolute;
          width: 100%;

          box-shadow: 1px 3px 5px #bdc3c9;
          border-radius: 0 0 3px 3px;

          background: #fff; */
        }
      }
    }

    .tableStyle {
      width: 100%;

      th {
        :first-child {
          width: 70%;
        }
      }
      tr,
      td {
        border: 1px solid #ced4da;
        border-radius: 5px;
        td:first-child {
          padding: 10px;

          p {
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: flex;
            align-items: center;
          }
          width: 80%;
        }

        td:nth-child(2) {
          svg {
            /* color: #0d6efd; */
            @media (max-width: 768px) {
              width: 15px;
            }
          }
        }

        td:last-child {
          text-align: center;
          svg {
            color: #dc3545;

            @media (max-width: 768px) {
              width: 12px;
            }
          }
        }
      }
    }

    > div:last-child {
    }
  }

  ul {
    width: 100%;
    /* color: #dc3545; */

    li {
      font-size: 10px;
      /* color: #dc3545; */
    }
  }
  /* > input {
    width: 50%;
  } */

  select {
    width: 100%;
    height: 34px;
    border-color: #dee2e6;
    border-radius: 5px;
  }
`

export const TableContainer = styled.table`
  width: 100%;
  vertical-align: middle;

  td,
  th {
    border: 1px solid #ced4da;
    width: calc(100% / 4);
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;

      /* max-width: 250px; */

      margin: 0;
      @media (min-width: 1326px) {
        width: 250px;
      }
    }

    svg {
      width: 37px;
      color: #dc3545;
      @media (max-width: 768px) {
        width: 12px;
        height: 20px;
      }
    }
  }
`

export const ContainerGif = styled.div`
  position: relative;
  width: 330px;
  height: 200px;
  overflow: hidden;
  /* position: relative;
  width: 300px;
  height: 200px;
  overflow: hidden;
  /* Defina as coordenadas de recorte: clip(top, right, bottom, left) */

  img {
    margin-top: -85px;
  }
`

export const Form = styled.form``
