import React, {
  useEffect,
  useState,
  ChangeEvent,
  useContext,
  useRef,
} from 'react'
import io from 'socket.io-client'
import { ContextAPI } from '../../hooks/useContextApi'
import {
  IoIosClose,
  IoIosNotifications,
  IoIosShareAlt,
  IoIosNotificationsOutline,
  IoMdNotifications,
} from 'react-icons/io'
import { BiInfoCircle } from 'react-icons/bi'
import { Button, Card, Form } from 'react-bootstrap'
import { BsArrowLeftShort, BsCalendar3 } from 'react-icons/bs'
import WebShare from '../WebShare'
import { COntainerNotfy } from '../../assets/styles/global'
import { deleteNotfy, responseNotfy } from '../../service'
import { Link } from 'react-router-dom'
import Stomp from 'stompjs'
import SockJS from 'sockjs-client'

const WebSocketComponent = () => {
  const [dataList, setDataList] = React.useState<any[]>([])
  const {
    nameHeader,
    MaskFormatBR,
    notificacao,
    eventos,
    handleChangeReload,
    reloadTeste,
    handleChangeNewNotify,
    newNotificacao,
    urlRouter,
    handleModalCreate,

    HandleRetornoWS,
    RetornoWS,
  } = React.useContext(ContextAPI)
  const [open, setOpen] = React.useState<boolean>(false)
  const [openNotify, setOpenNotify] = React.useState<boolean>(false)
  const [dateSearch, setDateSearch] = React.useState<string>('')
  const isFetch = useRef(false)
  useEffect(() => {
    // Estabelece a conexão com o servidor WebSocket
    const socket = new WebSocket(
      'wss://eventos-dsv-backend-dsv.apps.ocp.tjgo.jus.br:8080/ws',
    ) // ou const socket = io('http://localhost:3000');
    const StompCli = Stomp.over(socket)

    StompCli.connect(
      {},
      function (frame) {
        StompCli.subscribe('/topic/1/notifications', function (notification) {
          HandleRetornoWS(notification.body)
        })
      },
      function (error) {
        
      },
    )

    return () => {
      socket.close()
    }
  }, [])

  React.useEffect(() => {
    // Função de tratamento de clique fora do container
    const handleClickOutside = (event: Event) => {
      const container = document.getElementById(
        'ContainerDropDownClick',
      ) as HTMLInputElement // Substitua "container" pelo ID do seu container
      const containerInput = document.getElementById(
        'OpenNot',
      ) as HTMLInputElement // Substitua "container" pelo ID do seu container

      if (container && !container.contains(event.target as Node)) {
        if (containerInput && !containerInput.contains(event.target as Node)) {
          setOpen(false)
          setOpenNotify(false)
        }
      }
    }
    // Adiciona o event listener global para detectar os cliques fora do container
    const addEvent = document.addEventListener(
      'click',
      handleClickOutside,
    ) as void
    // Função de limpeza ao desmontar o componente
    return () => {
      const removeEvent = document.removeEventListener(
        'click',
        handleClickOutside,
      ) as void
    }
  }, [])

  React.useEffect(() => {
    async function response() {
      const response = await responseNotfy(0)
      setDataList(response.data)
    }

    response()
  }, [RetornoWS])

  React.useEffect(() => {
    if (!isFetch.current) {
      isFetch.current = true
      return
    }
    handleChangeNewNotify(true)
  }, [RetornoWS])

  const handleDeleteItem = (id: number) => {
    const remove = dataList.filter((e) => e.notificacaoId !== id)
    setTimeout(() => {
      setDataList(remove)
    }, 100)
    setOpen(true)
  }

  const shareData = {
    title: 'MDN',
    text: 'Learn web development on MDN!',
    url: 'https://developer.mozilla.org',
  }

  const handleOpenWebShare = async () => {
    return await navigator.share(shareData)
  }

  const handleLocaleName = (idEvent: number) => {
    const eventosLocale = eventos.filter((e) => e.eventoId === idEvent)
    return eventosLocale[0] === undefined
      ? 'Nome nao encontrado'
      : eventosLocale[0].nome
  }
  return (
    <COntainerNotfy newNotify={newNotificacao} openModal={openNotify}>
      <div>
        <Button
          variant="link"
          className="iconNotification"
          id="OpenNot"
          onClick={() => {
            setOpen(!open)
            handleChangeNewNotify(false)
          }}
        >
          {/* <span>{dataList.length}</span> */}
          <IoMdNotifications />
        </Button>

        {open && (
          <div className={'containerModalSimple'} id="ContainerDropDownClick">
            <ul
              className={
                openNotify
                  ? 'containerULModalComplete'
                  : 'containerULModalSimple'
              }
            >
              {openNotify && (
                <div className="liHeader">
                  <div>Historico de notificacoes</div>
                  <div>
                    <BsCalendar3 />
                    <Form.Control
                      type="date"
                      value={dateSearch}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setDateSearch(e.target.value)
                      }
                    />
                    <span
                      onClick={() => {
                        setDateSearch('')
                      }}
                    >
                      <IoIosClose />
                    </span>
                  </div>
                </div>
              )}
              {!openNotify && (
                <li className="liVerMais">
                  <div>
                    <strong>Notificações</strong>
                  </div>
                  <div>
                    <Button
                      onClick={() =>
                        setTimeout(() => {
                          setOpenNotify(true)
                        }, 200)
                      }
                    >
                      Ver todas
                    </Button>
                  </div>
                </li>
              )}
              {!openNotify ? (
                <>
                  {dataList
                    .filter((e) => e.ativo)
                    .sort((a, b) => {
                      return b.notificacaoId - a.notificacaoId
                    })
                    .slice(0, 3)
                    .map((e) => (
                      <li key={e.notificacaoId}>
                        <Link
                          onClick={() => {
                            // setOpenNotify(false)
                            setTimeout(async () => {
                              setOpen(false)
                              handleChangeReload()
                              await deleteNotfy(e.notificacaoId)
                            }, 100)
                            handleDeleteItem(e.notificacaoId)
                          }}
                          to={`/frequenciasevento/${e.eventoId}`}
                        >
                          <div>
                            <div>
                              <BiInfoCircle />
                              <p>{handleLocaleName(e.eventoId)}</p>
                            </div>
                            <span>
                              <p>{e.hora}</p>
                            </span>
                          </div>
                        </Link>
                        <div>
                          <Link
                            onClick={() => {
                              // setOpenNotify(false)
                              setTimeout(async () => {
                                setOpen(false)
                                handleChangeReload()
                                await deleteNotfy(e.notificacaoId)
                              }, 100)
                              handleDeleteItem(e.notificacaoId)
                            }}
                            to={`/frequenciasevento/${e.eventoId}`}
                          >
                            <div>
                              <p>
                                {e.descricao === undefined
                                  ? 'Testeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'
                                  : e.descricao}
                              </p>
                            </div>
                          </Link>
                          <div>
                            <Button variant="link">
                              <IoIosShareAlt
                                onClick={() => handleOpenWebShare()}
                              />
                            </Button>
                            <Button
                              id="handleDeleteContainer"
                              onClick={() => handleDeleteItem(e.notificacaoId)}
                              variant="link"
                            >
                              <IoIosClose id="handleDeleteContainer" />
                            </Button>
                          </div>
                        </div>
                      </li>
                    ))}
                </>
              ) : (
                <>
                  {dataList
                    .filter((e) => e.ativo !== false)
                    .filter((e) => {
                      return dateSearch === '' ? e : e.data === dateSearch
                    })
                    .map((e) => (
                      <li key={e.notificacaoId}>
                        <Link
                          onClick={() => {
                            setTimeout(async () => {
                              setOpen(false)
                              handleChangeReload()
                              await deleteNotfy(e.notificacaoId)
                            }, 100)
                            handleDeleteItem(e.notificacaoId)
                          }}
                          to={`/frequenciasevento/${e.eventoId}`}
                        >
                          {/* <div> */}
                          <div>
                            <BiInfoCircle />
                            <p>{handleLocaleName(e.eventoId)}</p>
                          </div>
                          <span>
                            <p>{MaskFormatBR(e.data)}</p>
                          </span>
                          <span></span>
                          {/* </div> */}
                        </Link>
                        <div>
                          <Link
                            onClick={() => {
                              setTimeout(async () => {
                                setOpen(false)
                                handleChangeReload()
                                await deleteNotfy(e.notificacaoId)
                              }, 100)
                              handleDeleteItem(e.notificacaoId)
                            }}
                            to={`/frequenciasevento/${e.eventoId}`}
                          >
                            <div>
                              {e.descricao === undefined
                                ? 'Teste'
                                : e.descricao}{' '}
                            </div>
                            <div>
                              <p>{e.hora}</p>
                            </div>
                          </Link>
                          <div>
                            <Button
                              variant="link"
                              onClick={() => {
                                handleOpenWebShare()
                              }}
                            >
                              <IoIosShareAlt />
                            </Button>
                            <Button
                              id="handleDeleteContainer"
                              onClick={() => handleDeleteItem(e.notificacaoId)}
                              variant="link"
                            >
                              <IoIosClose id="handleDeleteContainer" />
                            </Button>
                          </div>
                        </div>
                      </li>
                    ))}
                </>
              )}
            </ul>
          </div>
        )}
      </div>
    </COntainerNotfy>
  )
}

export default WebSocketComponent
