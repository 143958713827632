import { Navigate, useRoutes, Route } from 'react-router-dom'

import { RegisterEvent } from '../pages/registerEvento'
import { WindowCadaster } from '../pages/cadaster'
import { ListCadaster } from '../pages/ListCadaster'
import EventosCadastrados from '../pages/eventosCadastrados'
import { FrequenciaEventos } from '../pages/frequenciaDoEvento'
import { RegisterRepresentante } from '../pages/RegisterRepresentante'
import TesteTable from '../pages/TesteTable'
import FrequenciaDoEvento from '../pages/frequenciaDoEvento/newIndex'
import Login from '../pages/Login'
import { useContext } from 'react'
import { ContextAPI } from '../hooks/useContextApi'
import Usuario from '../pages/usuario'
import { ListaUsuarios } from '../pages/listaUsuarios'
import { ListaPrefil } from '../pages/listaPerfil'
import { Layout } from '../pages/usuario/Layout'

export default function Router() {
  const { isPrivet, ValidarLogin } = useContext(ContextAPI)
  const isAuthenticated = !!sessionStorage.getItem('jwtToken')

  function HandleValidated(Rota: JSX.Element) {
    return isAuthenticated && isPrivet ? Rota : <Navigate to="/login" />
  }

  const routes = useRoutes([
    {
      path: 'login',
      element: isAuthenticated && isPrivet ? <Navigate to="/" /> : <Login />,
    },
    {
      path: 'cadastro-usuarios',
      element: <Layout />,
    },
    {
      path: 'usuario/:id',
      element: HandleValidated(<Usuario />),
    },
    {
      path: 'lista-usuarios',
      element: HandleValidated(<ListaUsuarios />),
    },
    {
      path: 'lista-perfil',
      element: HandleValidated(<ListaPrefil />),
    },
    {
      path: 'registerevento',
      element: HandleValidated(<RegisterEvent />),
    },
    {
      path: 'evento/:id',
      element: HandleValidated(<RegisterEvent />),
    },
    {
      path: 'cadastrar',
      element: HandleValidated(<WindowCadaster />),
    },
    {
      path: 'pessoa/:id',
      element: HandleValidated(<WindowCadaster />),
    },
    {
      path: 'newrepresentante/evento/:eventoId/pessoa/:id',
      element: HandleValidated(<WindowCadaster />),
    },
    {
      path: '/',
      element: HandleValidated(<ListCadaster />),
    },
    {
      path: 'eventosCadastrados',
      element: HandleValidated(<EventosCadastrados />),
    },
    {
      path: 'frequenciaeventos',
      element: HandleValidated(<FrequenciaDoEvento />),
    },
    {
      path: 'frequenciasevento/:id',
      element: HandleValidated(<FrequenciaDoEvento />),
    },
    {
      path: 'representante/evento/:eventoId/pessoa/:pessoaId',
      element: HandleValidated(<RegisterRepresentante />),
    },
    {
      path: 'testTbale',
      element: HandleValidated(<TesteTable />),
    },
    {
      path: 'testTbales/:id',
      element: HandleValidated(<TesteTable />),
    },
  ])

  return routes
}
