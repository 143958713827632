import React, { useContext, useEffect, useState } from 'react'
import { BrowserRouter, Link, useLocation, useNavigate } from 'react-router-dom'
// import Routes from '../../router'
import { ThemeProvider } from 'styled-components'
import { ContainerCard, GlobalStyles } from '../../assets/styles/global'
import usePersistedState from '../../utils/usePersistedState'
import light from '../../assets/styles/themes/light'
import { Button, Card, Dropdown, Modal } from 'react-bootstrap'
import Router from '../../router'

// import IconTJ from '../../assets/images/faviconTJ.ico'

import HaderNotification from '../HaderNotification'
import { ContainerHeader } from './styled'
import iconTJ from '../../assets/images/brasao_cor.svg'
import { GiHamburgerMenu } from 'react-icons/gi'
import { AiFillHome } from 'react-icons/ai'
import { TbLogout } from 'react-icons/tb'
import WebSocketComponent from '../HaderNotification/ws'
import { ContextAPI } from '../../hooks/useContextApi'
import dark from '../../assets/styles/themes/dark'
function App() {
  const [theme, setTheme] = usePersistedState('theme', light)
  const { isPrivet, ValidarLogin } = useContext(ContextAPI)
  const [logout, setLogout] = React.useState(false)

  useEffect(() => {
    const Privet = sessionStorage.getItem('isPrivet')
    if (Privet === '1') {
      ValidarLogin(true)
    } else {
      ValidarLogin(false)
    }
  }, [logout === false])

  return (
    <ThemeProvider theme={theme}>
      <Modal show={logout} onHide={() => setLogout(false)}>
        <Modal.Header style={{ background: '#F8D7DA' }} closeButton>
          <Modal.Title style={{ color: 'rgb(116, 31, 39)' }}>Sair</Modal.Title>
        </Modal.Header>
        <Modal.Body>Gostaria de sair desta conta?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setLogout(false)}>
            Não
          </Button>
          {/* <Link to="/login"> */}
          <Button
            variant="primary"
            onClick={() => {
              sessionStorage.removeItem('isPrivet')
              sessionStorage.removeItem('jwtToken')

              setLogout(false)
            }}
          >
            Sim
          </Button>
          {/* </Link> */}
        </Modal.Footer>
      </Modal>
      {/* {isPrivet ? ( */}
      <BrowserRouter>
        <ContainerHeader>
          <Link to="/">
            <img src={iconTJ} alt="" />

            <h4>Eventos</h4>
          </Link>

          {isPrivet && (
            <div className="ContainerButtonHader">
              <Dropdown>
                <Dropdown.Toggle variant="link" id="dropdown-basic">
                  <GiHamburgerMenu />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to="/">
                    Pessoas
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/eventosCadastrados">
                    Eventos
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/lista-usuarios">
                    Lista de usuários
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/lista-perfil">
                    Lista de perfil
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Link to={'/'}>
                <Button variant="link">
                  <AiFillHome />
                </Button>
              </Link>

              <WebSocketComponent />

              <Button
                onClick={() => {
                  setLogout(true)
                }}
                variant="link"
              >
                <TbLogout />
              </Button>
            </div>
          )}
          {/* <span onClick={() => setTheme('theme', dark)}>T</span> */}
        </ContainerHeader>
        <ContainerCard isPrivet={isPrivet}>
          {isPrivet ? (
            <Card>
              <HaderNotification />
              <Card.Body>
                <Router />
              </Card.Body>
            </Card>
          ) : (
            <Router />
          )}
        </ContainerCard>
      </BrowserRouter>

      <GlobalStyles />
    </ThemeProvider>
  )
}

export default App
