// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/Montserrat/static/Montserrat-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/Inter/static/Inter-ExtraBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/Inter/static/Inter-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./assets/Inter/static/Inter-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./assets/Inter/static/Inter-Light.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"montserrat-medium\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")\n    format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"inter-extrabold\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"inter-bold\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"inter-medium\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"inter-light\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"truetype\");\n}\n", "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC;sBACoB;AACtB;;AAEA;EACE,8BAA8B;EAC9B,+DAAwE;AAC1E;;AAEA;EACE,yBAAyB;EACzB,+DAAmE;AACrE;;AAEA;EACE,2BAA2B;EAC3B,+DAAqE;AACvE;;AAEA;EACE,0BAA0B;EAC1B,+DAAoE;AACtE","sourcesContent":["@font-face {\n  font-family: \"montserrat-medium\";\n  src: url(\"./assets/Montserrat/static/Montserrat-Medium.ttf\")\n    format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"inter-extrabold\";\n  src: url(\"./assets/Inter/static/Inter-ExtraBold.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"inter-bold\";\n  src: url(\"./assets/Inter/static/Inter-Bold.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"inter-medium\";\n  src: url(\"./assets/Inter/static/Inter-Medium.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"inter-light\";\n  src: url(\"./assets/Inter/static/Inter-Light.ttf\") format(\"truetype\");\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
