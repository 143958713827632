import React from 'react'
import {
  VStack,
  HStack,
  Divider,
  Box,
  Flex,
  Spacer,
  Button,
  Icon,
} from '@chakra-ui/react'
import InfoField from './InfoField'
import DataField from './DataField'
import { FaArrowRight } from 'react-icons/fa'
import { telefoneMask } from '../../../utils/mask'

interface Props {
  dataInicial: string
  dataFinal: string
  nome: string
  descricao: string
  complemento: string | undefined
  logradouro: string | undefined
  site: string
  telefones: Telefone[]
  email: string
  horaInicial: string
  horaFinal: string
}
const DadosEventoMobile = ({
  complemento,
  dataFinal,
  dataInicial,
  descricao,
  email,
  logradouro,
  nome,
  site,
  telefones,
  horaInicial,
  horaFinal,
}: Props) => {
  return (
    <VStack align="start">
      <HStack w="100%" justifyContent="space-evenly">
        {dataInicial === dataFinal && (
          <DataField title="Dia" data={dataInicial} hora={horaInicial} />
        )}
        {dataInicial !== dataFinal && (
          <>
            <DataField title="De" data={dataInicial} hora={horaInicial} />
            <DataField title="Até" data={dataFinal} hora={horaFinal} />
          </>
        )}
      </HStack>
      <Divider />

      <InfoField title="Nome do evento" data={nome} />

      <InfoField title="Descrição" data={descricao} />

      <InfoField title="Local" data={complemento} />

      <InfoField title="Endereço" data={logradouro} />

      {email && <InfoField title="Email" data={email} showCopyButton={true} />}
      {telefones &&
        telefones.map((telefone, index) => (
          <InfoField
            key={index}
            title={`telefone ${index + 1}`}
            data={telefoneMask(telefone.ddd + telefone.numero)}
            showCopyButton={true}
          />
        ))}
      {site && (
        <Flex direction="column" w="100%" mb="5px">
          <Box
            flex={1}
            fontFamily="inter-medium"
            fontSize="18px"
            color="gray.500"
          >
            Site
          </Box>
          <Flex
            fontSize="0.9vw"
            fontFamily="inter-light"
            bg="#EEEEFA"
            p="10px"
            mt="5px"
            borderRadius="5px"
            dir="row"
            alignItems="center"
          >
            <Box fontSize="18px">{site}</Box>
            <Spacer />
            <Button
              bg="blue"
              onClick={() => {
                if (site) {
                  const url = site
                  let formattedUrl = site

                  if (
                    !url.startsWith('http://') &&
                    !url.startsWith('https://')
                  ) {
                    formattedUrl = 'https://' + url
                  }

                  window.open(formattedUrl, '_blank')
                }
              }}
            >
              <Icon
                as={FaArrowRight}
                size="sm"
                aria-label="go-to-site"
                color="white"
              />
            </Button>
          </Flex>
        </Flex>
      )}
    </VStack>
  )
}

export default DadosEventoMobile
