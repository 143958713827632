import axios from 'axios'
import { UserPessoa } from '../pages/usuario/types'
import { resolve } from 'path'

type EventTypeProp = {
  eventoId: number
  nome: string
}

const jwtToken = sessionStorage.getItem('jwtToken')

export const api = axios.create({
  baseURL: String(process.env.REACT_APP_API_SERVICE_URL),
  // baseURL: 'https://eventos-dsv-backend-dsv.apps.ocp.tjgo.jus.br',
  // baseURL: 'http://localhost:8081',

  headers: {
    Authorization: `Bearer ${jwtToken}`,
  },
})

export const getAllEventos = async () => {
  return await api.get('eventos')
}

export const postPessoa = async (eventos: EventTypeProp[], data: {}) => {
  let T = ''
  for (const i in eventos) {
    T += `eventos=${eventos[i].eventoId}&`
  }
  return await api.post(`pessoa/cadastrar-pessoa-eventos?${T}`, data)
}

export const PostEvento = (Value: {}) => {
  return api.post('eventos', Value)
}

export const Postpessoa = (Value: any, eventoId: number[]) => {
  return api.post(`pessoa/cadastrar-pessoa`, Value)
}

export const PutPessoa = (data: any, id: string) => {
  for (const i in data.contatos) {
    data.contatos[i].pessoaId = parseInt(id)
  }

  return api.put(`/pessoa/atualizar/${id}`, data)
}

export const BuscarPessoa = () => {
  return api.get('/pessoa')
}

export const BuscarEventoEPessoa = (id: string | number) => {
  return api.get(`/pessoa/get-eventos-by-pessoa-id/${id}`)
}

export const ContatoPessoaDelete = async (idPessoa: any, idContato: any) => {
  return await api.delete(
    `/pessoa/remover-contato/pessoa/${idPessoa}/contato/${idContato}`,
  )
}

export const EnderecoPessoaDelete = async (idPessoa: any, idEndereco: any) => {
  return await api.delete(
    `/pessoa/remover-endereco/pessoa/${idPessoa}/endereco/${idEndereco}`,
  )
}

export const DeleteEndereco = async (idEndereco: any) => {
  return await api.delete(`endereco/${idEndereco}/pessoa`)
}

export const FotoPessoa = (formData: any, id: string) => {
  return api.post(`/pessoa/upload-foto/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const GetFotoPessoa = (formData: any) => {
  return api.get(`/pessoa/imagem/${formData}`, { responseType: 'arraybuffer' })
}

export const BuscarPessoaId = async (id: string) => {
  return await api.get(`/pessoa/find-pessoa-by-id/${id}`)
}

export const DeletePessoa = (id: number) => {
  return api.delete(`/pessoa/remover-pessoa/${id}`)
}

export const DeleteTefone = (idPessoa: number, idTelefone: number) => {
  return api.delete(`/pessoa/${idPessoa}/telefones/${idTelefone}`)
}

export const BuscaCEP = async (cep: string) => {
  const dataCep = await axios.get(
    `https://viacep.com.br/ws/${cep.replaceAll('.', '')}/json/`,
  )
  return dataCep.data
}

export const CadastrarEvento = (
  data: any,
  responsavelList: ResponsavelEvento[],
) => {
  data.responsaveis = responsavelList
  const response = api.post('/eventos/cadastrar-evento', data)
  console.warn(response)
  return response
}

export const GetIdEventos = (id: string | number) => {
  return api.get(`/eventos/${id}`)
}

export const GetEventos = () => {
  return api.get('/eventos/get-all')
}

export const GetOneEventos = (id: string) => {
  return api.get(`/eventos/find-by-id/${id}`)
}

export const PutEventos = (id: any, data: {}) => {
  return api.put(`/eventos/atualizar/${id}`, data)
}

export const DeleteEventos = (id: any) => {
  return api.delete(`/eventos/${id}`)
}

export const UploadFotoEventos = (formData: any, id: string) => {
  return api.post(`/eventos/upload-foto/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const GetFotoEvento = (formData: any) => {
  return api.get(`/eventos/imagem/${formData}`, { responseType: 'arraybuffer' })
}

export const PostCreateRepresentante = (data: Representante) => {
  return api.post(`/representantes/cadastrar-representante`, data)
}

export const DeleteRepresentante = (
  pessoaId: number,
  representanteId: number,
) => {
  return api.delete(
    `/representantes/remover-representante/pessoa/${pessoaId}/representante/${representanteId}`,
  )
}

export const GetRepresentacaoByPessoa = async (pessoaId: number) => {
  return await api.get(`/representantes/find-by-id/${pessoaId}`)
}

export const GetPessoaByNameOrTitulo = (nameOrTitulo: string) => {
  return api.get(`/pessoa/find-pessoa-by-name-or-titulo/${nameOrTitulo}`)
}

export const CadastrarProfissionalEvento = (data: ProfissionalEvento) => {
  return api.post(`/profissional-evento/cadastrar-profissional-evento`, data)
}

export const CadastrarPessoaEvento = (data: any) => {
  return api.post('/pessoa-eventos/cadastrar-pessoa-evento', data)
}

export const DeletePessoaEvento = (pessoaId: number, eventoId: number) => {
  return api.delete(
    `/pessoa-eventos/remover-pessoa-evento/pessoa/${pessoaId}/evento/${eventoId}`,
  )
}

export const GetPessoasByEvento = (eventoId: number) => {
  return api.get(`/pessoa/find-pessoa-by-evento/${eventoId}`)
}

export const PutPessoaEvento = (
  pessoaId: number,
  eventoId: number,
  data: PessoaEvento,
) => {
  return api.put(
    `pessoa-eventos/atualizar-pessoa-evento/pessoa/${pessoaId}/evento/${eventoId}`,
    data,
  )
}

export const PostPessoaEvento = (data: any) => {
  // data.presente = !!(
  //   data.presente === 'Presente' || data.presente === 'Nominado'
  // )
  return api.post(`frequencia/cadastrar-frequencia`, data)
}

export const responseFrequenciByEventos = (id: number) => {
  return api.get(`/frequencia/find-by-evento/${id}`)
}

export const responseNotfy = (id: number | string) => {
  return api.get(`notificacao/get-all`)
}

export const deleteNotfy = (id: number | string) => {
  return api.delete(`notificacao/${id}`)
}

export const RegisterMesa = (data: any) => {
  return api.put(
    `/pessoa-eventos/atualizar/idPessoa/${data.pessoaId}/evento/${data.eventoId}`,
    data,
  )
}

export const SignIn = (data: { username: string; password: string }) => {
  return api.post('/auth/login', data)
}

export const RegisterUser = (data: any) => {
  return api.post('/pessoa/cadastrar-pessoa-user', data)
}

export const GetOneUser = (id: string) => {
  return api.get(`/pessoa/find-pessoa-user-by-id/${id}`)
}

export const GetAllUser = () => {
  return api.get(`/pessoa/get-all-user-pessoas`)
}

export const PatchOneUser = (id: number, data: UserPessoa) => {
  return api.patch(`/pessoa/atualizar-user/${id}`, data)
}
