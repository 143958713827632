import styled from 'styled-components'

type IPropButton = {
  primary?: boolean
}

export const StyleList = styled.div`
  > div {
    margin-bottom: 10px;
  }

  > div:first-child {
    display: flex;
    justify-content: space-between;
  }

  > div:nth-child(2) {
    width: 100%;
    height: 39px;
    gap: 10px;
    display: flex;
    /* justify-content: flex-end; */
    input {
      width: 50%;
      @media (max-width: 480px) {
        width: 100%;
      }
    }
  }

  > div:last-child {
    width: 100%;
    border: 1px solid rgb(208, 213, 220);
    border-radius: 5px;
    min-height: 20px;
    max-height: 70vh;
    overflow-y: auto;

    > ul {
      padding: 8px;
      li {
        display: flex;
        justify-content: space-between;
        border: 1px solid rgb(208, 213, 220);
        padding: 5px;
        margin-bottom: 10px;
        background: rgba(0, 0, 0, 0.05);
        div {
          display: flex;
          flex-direction: column;
          width: calc(100% / 2);
          span {
            height: calc(100% / 2);
            h1 {
              font-size: 1rem;
              font-weight: bold;
              color: rgb(0, 74, 128);
            }
            p {
              font-size: 0.9rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 100%;
              color: #121214;
            }
          }
        }
      }
    }
  }
`

export const DetalhesEventos = styled.div`
  > div:first-child {
    /* width: 100%;
    height: 150px;
    background: rgba(0,0,0,.05); */
    display: flex;
    justify-content: flex-end;
    /* gap: 8px; */

    button {
      color: #0d6efd;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  > div:nth-child(2) {
    width: 100%;
    height: 150px;
    background: rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(208, 213, 220);
    margin: 15px 0;
    img {
      max-width: 100%;
      height: 100%;
    }
  }

  > div:last-child {
    width: 100%;

    display: flex;
    flex-direction: column;
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }
  }
`

export const ContainerNotfy = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    z-index: 11;
  }
  svg {
    width: 20px;
    height: 20px;
  }
`

export const Notify = styled.div<IPropButton>`
  transition: all ease 0.5s;
  position: absolute;
  z-index: 10;

  width: ${(props) => (props.primary ? '90vw' : '0vw')};
  height: ${(props) => (props.primary ? '80vh' : '0vh')};
  opacity: ${(props) => (props.primary ? 'inherit' : '0%')};
  /* margin-top: ${(props) => (props.primary ? 'auto' : '-500px')}; */

  top: 45%;
  left: 41%;
  transform: translate(-50%, -50%);

  background: #fff;
  border: 1px solid #000;

  /* ${(props) =>
    props.primary
      ? `

  `
      : `

  `} */
`
