import styled from 'styled-components'

export const ContainerList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  > div:first-child {
    display: flex;
    gap: 10px;
    width: 50%;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  > div:last-child {
    table {
      vertical-align: middle;

      thead > tr > th,
      tbody > tr > td {
        :first-child {
          padding-left: 10px;
        }
        :nth-child(n + 2) {
          width: 60px;
          text-align: center;
        }
      }
    }
  }
`
