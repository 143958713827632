export const cpfMask = (value: string) => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export const telefoneMask = (v:any) => {
  let r = v.replace(/\D/g, ''); // Remove caracteres não numéricos

  // Aplica a máscara
  if (r.length >= 11) {
    r = r.replace(/^(\d{2})(\d{5})(\d{4}).*/, '($1) $2-$3');
  } else if (r.length >= 7) {
    r = r.replace(/^(\d{2})(\d{4})(\d{0,4}).*/, '($1) $2-$3');
  } else if (r.length >= 3) {
    r = r.replace(/^(\d{2})(\d{0,5})/, '($1) $2');
  } else {
    r = r.replace(/^(\d{0,2})/, '($1');
  }

  // Corrige caso onde um parêntese está sobrando no final
  if (r.endsWith('(')) {
    r = r.slice(0, -1);
  }

  return r;
}



export const cepMask = (value: string) => {
  return value
    .replace(/\D+/g, '') // não deixa ser digitado nenhuma letra
    .replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
    .replace(/(\d{3})(\d)/, '$1-$2')
    .replace(/(\d{3})(\d)/, '$1-$2') // captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
}
