import React, { useContext } from 'react'
import ReactDOM from 'react-dom/client'
import 'bootstrap/dist/css/bootstrap.css'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { ChakraProvider } from '@chakra-ui/react'
import './App.css'
import App from '../src/components/App'
import { ContextProvider } from './hooks/useContextApi'
import { ToastContainer } from 'react-toastify'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  // <React.StrictMode>
  <ChakraProvider>
    <ContextProvider>
      <ToastContainer limit={3} />
      <App />
    </ContextProvider>
  </ChakraProvider>,

  // </React.StrictMode>,
)
