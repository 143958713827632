export const cadastrousuario = [
  {
    name: 'nome',
    label: 'Nome',
    type: 'text',
    require: true,
  },
  {
    name: 'sobrenome',
    label: 'Sobrenome',
    type: 'text',
    require: false,
  },
  {
    name: 'apelido',
    label: 'Apelido',
    type: 'text',
    require: false,
  },
  {
    name: 'pronomeTratamento',
    label: 'Pronome de tratamento',
    type: 'text',
    require: true,
  },
  // {
  //   name: 'titulo',
  //   label: 'Titulo',
  //   type: 'text',
  //   require: true,
  // },
  {
    name: 'cargo',
    label: 'Cargo',
    type: 'text',
    require: true,
  },

  {
    name: 'matricula',
    label: 'matricula',
    type: 'text',
    require: false,
  },

  {
    name: 'cpf',
    label: 'Cpf',
    type: 'text',
    require: true,
  },
  {
    name: 'telefones',
    label: 'Telefone',
    type: 'text',
    require: false,
  },
  {
    name: 'email',
    label: 'Email',
    type: 'email',
    require: false,
  },

  {
    name: 'username',
    label: 'Nome de usuario',
    type: 'text',
    require: true,
  },
  {
    name: 'password',
    label: 'Senha',
    type: 'text',
    require: true,
  },
  {
    name: 'verifyPassword',
    label: 'Confirmar senha',
    type: 'text',
    require: true,
  },
]

export const cadastrarConvidado = [
  {
    name: 'nome',
    label: 'Nome',
    type: 'text',
    require: true,
  },
  {
    name: 'pronomeTratamento',
    label: 'Pronome de tratamento',
    type: 'text',
    require: true,
  },
  {
    name: 'cargo',
    label: 'Cargo',
    type: 'text',
    require: true,
  },
  {
    name: 'cpf',
    label: 'Cpf',
    type: 'text',
    require: false,
  },
  {
    name: 'precedencia',
    label: 'Precendência',
    type: 'text',
    require: true,
  },
  {
    name: 'apelido',
    label: 'Apelido',
    type: 'text',
    require: false,
  },
  {
    name: 'email',
    label: 'Email',
    type: 'email',
    require: false,
  },
]

export const cadastrarEventos = [
  {
    name: 'nome',
    label: 'Nome',
    type: 'text',
    require: true,
  },
  {
    name: 'dataInicial',
    label: 'Data inicial',
    type: 'date',
    require: true,
  },
  {
    name: 'dataFinal',
    label: 'Data final',
    type: 'date',
    require: true,
  },

  {
    name: 'horaInicial',
    label: 'Hora inicial',
    type: 'time',
    require: true,
  },
  {
    name: 'horaFinal',
    label: 'Hora final',
    type: 'time',
    require: true,
  },

  {
    name: 'mestreDeCerimoniaId',
    label: 'Mestre de cerimonia',
    type: 'text',
    require: true,
  },

  {
    name: 'responsaveis',
    label: 'Responsavel',
    type: 'text',
    require: true,
  },
  {
    name: 'logradouro',
    label: 'Endereço',
    type: 'text',
    require: true,
  },
  {
    name: 'complemento',
    label: 'Local',
    type: 'text',
    require: true,
  },
]
