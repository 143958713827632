import React, { useState, useCallback, useEffect, useContext } from 'react'
import { Button, Card, FormControl, OverlayTrigger } from 'react-bootstrap'
import { MdAddCircleOutline, MdOutlineAddCircleOutline } from 'react-icons/md'
import { Link, useParams } from 'react-router-dom'
import { Buttons, Container, Footer } from './styles'
import BootstrapTable from 'react-bootstrap-table-next'

import { FaSearch, FaTrash } from 'react-icons/fa'
import {
  BuscarPessoaId,
  CadastrarPessoaEvento,
  CadastrarProfissionalEvento,
  DeleteRepresentante,
  GetPessoaByNameOrTitulo,
  GetRepresentacaoByPessoa,
  PostCreateRepresentante,
} from '../../service'
import { AxiosResponse } from 'axios'
import { ContextAPI } from '../../hooks/useContextApi'
import { DetalheDoEvento } from '../../components/Modal'
import AdicionarPessoaEvento from './AdicionarPessoaEvento'
import { HStack, useToast } from '@chakra-ui/react'
import { toast } from 'react-toastify'

export function RegisterRepresentante() {
  const { modalEvent, handleModalCreate, handleModalError } =
    useContext(ContextAPI)
  const toast = useToast()
  const [pessoa, setPessoa] = useState<IStateReducerNONOptional>({
    apelido: '',
    cpf: '',
    email: '',
    idCorporativo: '',
    nome: '',
    sobrenome: '',
    cargo: '',
    pronomeTratamento: '',
    precedencia: '',
    titulo: '',
    pessoaId: '',
    foto: '',
    contatos: [],
    endereco: [],
    telefones: [],
  })
  const [formSearch, setFormSearch] = useState<string>('')
  const [representantes, setRepresentantes] = useState<
    IStateReducerNONOptional[]
  >([])
  const [pessoas, setPessoas] = useState<IStateReducerNONOptional[]>([])
  const { eventoId, pessoaId } = useParams()
  const { handleAlterName, handleChangeUrlRoute } = React.useContext(ContextAPI)

  const [loadPage, setLoadPage] = useState(false)
  const handleInputSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()

    setFormSearch(event.target.value)
  }

  useEffect(() => {
    GetRepresentacaoByPessoa(Number(pessoaId)).then((res) =>
      setRepresentantes(res.data),
    )
  }, [loadPage])

  useEffect(() => {
    handleAlterName(`Inclusão de Representante para ${pessoa.nome}`)
    handleChangeUrlRoute(`/frequenciasevento/${eventoId}`)
  }, [])

  useEffect(() => {
    async function response() {
      if (pessoaId !== '') {
        const res = await BuscarPessoaId(String(pessoaId))
        handleAlterName(`Inclusão de Representante para ${res.data.nome}`)
        setPessoa(res.data)
      }
    }
    response()
  }, [pessoaId])

  const handleAddRepresentante = (row: IStateReducerNONOptional) => {
    setRepresentantes((prevState) => [...prevState, row])

    setPessoas((prevState) =>
      prevState.filter((pessoa) => pessoa.pessoaId !== row.pessoaId),
    )
  }

  const handleDeleteRepresentante = (row: IStateReducerNONOptional) => {
    DeleteRepresentante(Number(pessoaId), Number(row.pessoaId))
      .then((res) => {
        toast({
          title: `${row.nome} removido`,
          status: 'success',
          colorScheme: 'green',
          position: 'bottom-right',
        })
      })
      .catch((error) => {
        toast({
          title: `Erro ao remover: ${row.nome}`,
          description: error.message,
          status: 'error',
          colorScheme: 'red',
          position: 'bottom-right',
        })
      })

    setRepresentantes((prevState) =>
      prevState.filter(
        (representante) => representante.pessoaId !== row.pessoaId,
      ),
    )
  }

  const ColumnsSearchRepresentantes = [
    { dataField: 'nome', text: 'Nome' },
    { dataField: 'titulo', text: 'Título' },
    { dataField: 'precedencia', text: 'Precedência' },
    {
      dataField: '#',
      text: 'Ações',
      formatter: (
        column: any,
        row: IStateReducerNONOptional,
        rowIndex: any,
        formatExtraData: any,
      ) => {
        return (
          <div>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={MdOutlineAddCircleOutline}
            >
              <Button
                variant="link"
                onClick={() => handleAddRepresentante(row)}
              >
                <MdAddCircleOutline size={25} color={'#004a80'} />
              </Button>
            </OverlayTrigger>
          </div>
        )
      },
    },
  ]

  const ColumnsRepresentantes = [
    { dataField: 'nome', text: 'Nome' },
    { dataField: 'titulo', text: 'Título' },
    { dataField: 'precedencia', text: 'Precedência' },
    {
      dataField: '#',
      text: 'Ações',
      formatter: (
        column: any,
        row: IStateReducerNONOptional,
        rowIndex: any,
        formatExtraData: any,
      ) => {
        return (
          <div>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={MdOutlineAddCircleOutline}
            >
              <Button
                variant="link"
                onClick={() => handleDeleteRepresentante(row)}
              >
                <FaTrash style={{ color: '#ac0505' }} />
              </Button>
            </OverlayTrigger>
          </div>
        )
      },
    },
  ]

  const CadasterText = (
    <span>
      Falha ao cadastrar o representante.
      <br />
      Tente novamente.
    </span>
  )

  const hasPessoasList = pessoas.length > 0
  return (
    <>
      <DetalheDoEvento
        sucessOrError={true}
        showModal={modalEvent.create}
        handleClose={() => handleModalCreate(false)}
        mensageHeader={'Cadastro'}
        mensageBody={'Representante cadastrado com sucesso.'}
        nextFunction={false}
        urlPath={`/frequenciasevento/${eventoId}`}
      />
      <DetalheDoEvento
        sucessOrError={false}
        showModal={modalEvent.error}
        handleClose={() => handleModalError(false)}
        mensageHeader="Não podemos prosseguir"
        mensageBody={CadasterText}
        nextFunction={true}
        // urlPath={"/eventosCadastrados"}
      />

      <Container>
        <div>
          <HStack></HStack>
          <div style={{ height: '200px' }}>
            <AdicionarPessoaEvento
              pessoaID={Number(pessoaId)}
              loadPage={() => {
                setLoadPage(!loadPage)
              }}
            />
            {/* <FormControl
              placeholder="Buscar por nome ou titulo"
              value={formSearch}
              onChange={handleInputSearch}
            />

            <Button variant="primary" onClick={handleSearchPessoas}>
              <FaSearch />
            </Button> */}
          </div>

          {hasPessoasList && (
            <div>
              <BootstrapTable
                bordered={true}
                bootstrap4
                striped
                hover
                condensed
                keyField="pessoaId"
                columns={ColumnsSearchRepresentantes}
                data={pessoas}
                // pagination={paginationFactory(options)}
                defaultSortDirection="asc"
              />
            </div>
          )}
        </div>

        <div>
          <div>
            <Link
              replace={true}
              to={`/newrepresentante/evento/${eventoId}/pessoa/${pessoaId}`}
              tabIndex={-1}
            >
              <Button>Cadastar nova pessoa</Button>
            </Link>
          </div>
          <span>Representantes Adicionados:</span>

          <BootstrapTable
            bordered={true}
            bootstrap4
            striped
            hover
            condensed
            keyField="pessoaId"
            columns={ColumnsRepresentantes}
            data={representantes}
            // pagination={paginationFactory(options)}
            defaultSortDirection="asc"
          />
        </div>
      </Container>

      {representantes.length > 0 && (
        <Buttons>
          <Link to={`/frequenciasevento/${eventoId}`} tabIndex={-1}>
            <Button onClick={() => {}} variant="danger">
              Voltar
            </Button>
          </Link>
        </Buttons>
      )}

      {representantes.length <= 0 && (
        <Footer>
          <Link to={`/frequenciasevento/${eventoId}`} tabIndex={-1}>
            <Button>Voltar</Button>
          </Link>
        </Footer>
      )}
    </>
  )
}
