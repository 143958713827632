/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useState, useEffect } from 'react'

function usePersistedState(key: string, initialState: any) {
  const [theme, setTheme] = useState(() => {
    const storageValue = localStorage.getItem(key)

    if (storageValue) {
      return JSON.parse(storageValue)
    }

    return initialState
  })

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(theme))
  }, [key, theme])

  return [theme, setTheme]
}

export default usePersistedState
