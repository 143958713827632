import React, { useContext, useEffect, useState } from 'react'
import { ContainerList } from './styled'
import { Button, Form, OverlayTrigger } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import { renderTooltipDelete, renderTooltipEdit } from '../../utils/Tooltip'
import { FaEdit, FaTrash } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { customTotal } from '../../utils/functions'
import { ContextAPI } from '../../hooks/useContextApi'

// import { Container } from './styles';

export const ListaPrefil = () => {
  const [data, setData] = useState<any[]>(Perfil)
  const [nome, setNome] = useState('')

  const {
    modalEvent,
    pessoas,
    handleModalCreate,
    handleModalDelete,
    handleModalSucess,
    handleAlterName,
    handleChangeUrlRoute,
  } = useContext(ContextAPI)

  useEffect(() => {
    handleAlterName('Lista de perfil')
    handleChangeUrlRoute('/')
  }, [])

  const ColumnsFontes = [
    { dataField: 'nome', text: 'Nome', sort: true },
    {
      dataField: 'editar',
      text: 'Editar',
      formatter: (
        column: any,
        row: any,
        rowIndex: any,
        formatExtraData: any,
      ) => {
        return (
          <div>
            <Link to={`/pessoa/${row.pessoaId}`} tabIndex={-1}>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltipEdit}
              >
                <Button variant="link">
                  <FaEdit style={{ color: '#004a80' }} />
                </Button>
              </OverlayTrigger>
            </Link>
          </div>
        )
      },
    },
    {
      dataField: 'excluir',
      text: 'Excluir',
      formatter: (
        column: any,
        row: any,
        rowIndex: any,
        formatExtraData: any,
      ) => {
        return (
          <div>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltipDelete}
            >
              <Button variant="link" onClick={() => handleRemovePerfil(row.id)}>
                <FaTrash style={{ color: '#ac0505' }} />
              </Button>
            </OverlayTrigger>
          </div>
        )
      },
    },
  ]

  const options = {
    paginationSize: 5,
    pageStartIndex: 1,
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '30',
        value: 30,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: 'Todos',
        value: data.length,
      },
    ],
  }

  const handleAddPerfil = () => {
    if (nome === '') {
      return
    }
    const obj = {
      id: data[data.length - 1].id + 1,
      nome,
    }
    setData([...data, obj])
  }

  const handleRemovePerfil = (id: number) => {
    const Removible = data.filter((e) => e.id !== id)
    setData(Removible)
  }

  return (
    <ContainerList>
      <div>
        <Form.Control
          onChange={(e) => setNome(e.target.value)}
          placeholder="Nome"
        />
        <Button onClick={handleAddPerfil}>Incluir</Button>
      </div>
      <div>
        <BootstrapTable
          // loading={true}
          bordered={true}
          bootstrap4
          striped
          hover
          condensed
          keyField="id"
          columns={ColumnsFontes}
          data={data}
          pagination={paginationFactory(options)}
          defaultSortDirection="asc"
        />
      </div>
    </ContainerList>
  )
}

const Perfil = [
  {
    id: 1,
    nome: 'Administrador',
  },
  {
    id: 2,
    nome: 'Gestor',
  },
  {
    id: 3,
    nome: 'Convidado',
  },
]
