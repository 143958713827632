import React, {
  ChangeEvent,
  FocusEvent,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { Button, Card, Form } from 'react-bootstrap'
import { FaTrashAlt } from 'react-icons/fa'
import { Link, useLocation, useParams } from 'react-router-dom'
import { BsTelephone } from 'react-icons/bs'
import {
  AutoComplete,
  ButtonContainer,
  ContainerEvent,
  InputFormControl,
} from './styled'
import {
  BuscarPessoa,
  CadastrarEvento,
  PutEventos,
  CadastrarProfissionalEvento,
  UploadFotoEventos,
  GetFotoEvento,
  GetOneEventos,
  DeletePessoaEvento,
  CadastrarPessoaEvento,
  GetAllUser,
} from '../../service'

import { telefoneMask } from '../../utils/mask'
import { ContextAPI } from '../../hooks/useContextApi'
import { DetalheDoEvento } from '../../components/Modal'
import useErrors from '../../hooks/useErrors'

import { notifyError } from '../../components/Toast/intex'
import { cadastrarEventos } from '../../components/Form/FormFields'
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  useDisclosure,
  Button as CButton,
  AlertDialogFooter,
  Progress,
  Box,
  Text,
  HStack,
  VStack,
} from '@chakra-ui/react'

interface ITypeAddResponsavel {
  nome: string
  id: number
  funcao?: string
  cpf?: string
  responsavle?: boolean
}

interface IPessoa {
  nome: string
  pessoaId?: number
  funcao?: string
  cpf?: string
}

export function RegisterEvent() {
  const { id } = useParams()
  const [newEvent, setNewEvent] = useState(false)
  const [isEmailValid, setEmailIsValid] = useState(true)

  const [imagemSelecionada, setImagemSelecionada] = useState<any>(null)
  const [image, setImage] = useState<FormData>()

  const [AddInList, setAddInList] = useState<ITypeAddResponsavel[]>([])
  const [AddInResponsavelList, setAddInResponsavelList] = useState<
    ITypeAddResponsavel[]
  >([])

  const [newParticipantsList, setnewParticipantsList] = useState<
    ITypeAddResponsavel[]
  >([])

  const [deleteParticipantsIDList, setDeleteParticipantsIDList] = useState<
    number[]
  >([])

  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = React.useRef<HTMLButtonElement>(null)
  const [loadingProgressValue, setLoadingProgressValue] = useState(0)
  const [telefone, setTelefone] = useState('')
  const [telefones, setTelefones] = useState<Telefone[]>([])
  const [endereco, setEndereco] = useReducer(
    (prev: Endereco, next: Partial<Endereco>) => {
      return { ...prev, ...next }
    },
    {
      cep: '',
      cidade: '',
      complemento: '',
      estado: '',
      id: 0,
      logradouro: '',
      numero: '',
    },
  )

  const { errors, setError, removeError, getErrorMessageByFieldName } =
    useErrors()

  const [ListPessoa, setListPessoa] = useState<IStateReducerNONOptional[]>([])

  const [mestreCerimonia, setMestreCerimonia] = useState<IPessoa>({
    nome: '',
    pessoaId: 0,
    cpf: '',
  })
  const [responsavel, setResponsavel] = useState<IPessoa>({
    funcao: '',
    nome: '',
    pessoaId: 0,
    cpf: '',
  })

  const [MestreBool, setMestreBool] = useState<boolean>(false)
  const [responsavelBool, setResponsavelBool] = useState<boolean>(false)

  const [formInput, setFormInput] = useReducer(
    (prev: Evento, next: Partial<Evento>) => {
      return { ...prev, ...next }
    },
    {
      banner: '',
      dataFinal: '',
      dataInicial: '',
      descricao: '',
      email: '',
      enderecos: {},
      eventoId: 0,
      horaFinal: '',
      horaInicial: '',
      local: '',
      mestreDeCerimoniaId: 0,
      nome: '',
      responsaveis: [],
      site: '',
      telefones: [],
    },
  )

  const { pathname } = useLocation()

  const {
    pessoas,
    eventos,
    modalEvent,
    handleModalCreate,
    handleModalError,
    handleAlterName,
    fetchEventos,
    handleChangeReload,
    handleChangeUrlRoute,
  } = useContext(ContextAPI)

  useEffect(() => {
    handleAlterName('Cadastro de Eventos')

    handleChangeUrlRoute('/eventosCadastrados')
    if (pathname !== '/registerevento' && id !== '' && id !== undefined) {
      Response()
      setNewEvent(false)
      PessoaRes()
    } else {
      setNewEvent(true)
      PessoaRes()
    }

    async function PessoaRes() {
      const res = await GetAllUser()
      const pessoas: IStateReducerNONOptional[] = res.data
      pessoas.sort((a, b) => {
        const nomeA = a.nome.toUpperCase()
        const nomeB = b.nome.toUpperCase()

        if (nomeA < nomeB) {
          return -1
        }
        if (nomeA > nomeB) {
          return 1
        }

        return 0
      })

      setListPessoa(pessoas)
      return res.data
    }

    async function Response() {
      // if (id !== '' && id !== undefined) {
      try {
        const { data } = await GetOneEventos(id !== undefined ? id : '0')

        const GetOneEvento: Evento | undefined = data

        setTelefones([...GetOneEvento!.telefones])

        /// Pegando banner do eventp
        if (GetOneEvento !== undefined && GetOneEvento.banner !== '') {
          try {
            // const fotoPessoa = await GetFotoPessoa(objList.foto)

            await GetFotoEvento(GetOneEvento.banner).then((e) => {
              const base64 = btoa(
                new Uint8Array(e.data).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  '',
                ),
              )
              // const teste =
              setImagemSelecionada(
                `data:${e.headers[
                  'content-type'
                ].toLowerCase()};base64,${base64}`,
              )
            })
          } catch (err) {}
        }

        if (
          GetOneEvento !== undefined &&
          GetOneEvento.responsaveis.length > 0
        ) {
          const pesssoasEvento: ResponsavelEvento | undefined =
            GetOneEvento.responsaveis.find((e) => e.isResponsavel === false)

          if (pesssoasEvento) {
            const resObj: Pessoa | undefined = pessoas.find(
              (e: Pessoa) => e.pessoaId === pesssoasEvento.pessoaId,
            )

            if (resObj) {
              const { nome, cpf } = resObj

              const mestreCerimoniaList = {
                id: pesssoasEvento.pessoaId,
                nome,
                funcao: 'teste',
                cpf: cpf !== null ? cpf : '',
                responsavle: false,
              }

              setAddInList([mestreCerimoniaList])
            }
          }
        }

        const responsavelList: ITypeAddResponsavel[] = []
        if (
          GetOneEvento !== undefined &&
          GetOneEvento.responsaveis.length > 0
        ) {
          for (const responsavel of GetOneEvento.responsaveis) {
            // const responsavel: ResponsavelEvento | undefined =
            //   obj.responsaveis.find((e) => e.isResponsavel === true)

            if (responsavel.isResponsavel) {
              const resObj: Pessoa | undefined = pessoas.find(
                (e: Pessoa) => e.pessoaId === responsavel.pessoaId,
              )

              if (resObj) {
                const responsavelObj = {
                  nome: resObj.nome,
                  id: resObj.pessoaId,
                  funcao: 'teste',
                  cpf: resObj.cpf,
                }
                responsavelList.push(responsavelObj)
              }
            }
          }

          setAddInResponsavelList(responsavelList)
        }

        if (GetOneEvento !== undefined && GetOneEvento != null) {
          setFormInput({
            nome: GetOneEvento.nome,
            banner: '',
            dataFinal: handleRevertDate(GetOneEvento.dataFinal),
            dataInicial: handleRevertDate(GetOneEvento.dataInicial),
            descricao: GetOneEvento.descricao,
            email: GetOneEvento.email,
            enderecos: GetOneEvento.enderecos,
            eventoId: GetOneEvento.eventoId,
            horaFinal: GetOneEvento.horaFinal,
            horaInicial: GetOneEvento.horaInicial,
            local: GetOneEvento?.enderecos?.complemento,
            mestreDeCerimoniaId: GetOneEvento.mestreDeCerimoniaId,
            responsaveis: GetOneEvento.responsaveis,
            site: GetOneEvento.site,
          })

          const enderecoList: Endereco[] | undefined = GetOneEvento.enderecos
          if (enderecoList && enderecoList.length > 0) {
            setEndereco({
              complemento: enderecoList[0].complemento,
              logradouro: enderecoList[0].logradouro,
            })
          }
        }
      } catch (err) {
        console.warn(err)
        handleModalError(true)
      }
      // }
    }
  }, [eventos, id, pathname, pessoas])

  const saveMestreCerimonia = (id: number) => {
    const localePeople = ListPessoa.filter((e) => e.pessoaId === id)[0]
    const newMC: ITypeAddResponsavel = {
      nome: localePeople.nome,
      id: Number(localePeople.pessoaId),
      responsavle: false,
      cpf: '',
      funcao: '',
    }
    setnewParticipantsList([...newParticipantsList, newMC])
    setMestreCerimonia({
      nome: localePeople.nome,
      pessoaId: Number(localePeople.pessoaId),
      cpf: localePeople.cpf,
    })
    setFormInput({
      mestreDeCerimoniaId: id,
    })
  }

  const SaveResponsavel = (pessoa: IStateReducerNONOptional) => {
    const localePeople = ListPessoa.filter(
      (e) => e.pessoaId === pessoa.pessoaId,
    )[0]

    const newResponsable: ITypeAddResponsavel = {
      nome: localePeople.nome,
      id: Number(localePeople.pessoaId),
      cpf: '',
      funcao: '',
      responsavle: true,
    }
    setnewParticipantsList([...newParticipantsList, newResponsable])
    const data: IPessoa = {
      nome: localePeople.nome,
      pessoaId: Number(localePeople.pessoaId),
      funcao: localePeople.cargo,
      cpf: String(localePeople.cpf),
    }

    setResponsavel(data)
  }

  function handleAddList() {
    if (formInput.mestreDeCerimoniaId === 0) {
      return
    }

    const MestreCerimonia = ListPessoa.find(
      (e) => e.pessoaId === formInput.mestreDeCerimoniaId,
    )

    const X: ITypeAddResponsavel = {
      nome:
        MestreCerimonia !== undefined
          ? MestreCerimonia?.nome
          : mestreCerimonia.nome,
      id: formInput.mestreDeCerimoniaId,
      cpf: String(mestreCerimonia.cpf),
      responsavle: false,
    }

    if (X.id !== 0) {
      const target = document.getElementById('mestreDeCerimoniaId')
      target?.classList.remove('inactive')
      target?.classList.add('active')
    }

    setMestreCerimonia({
      cpf: '',
      nome: '',
      pessoaId: 0,
    })

    setAddInList([X])
    setFormInput({ mestreDeCerimoniaId: formInput.mestreDeCerimoniaId })
  }

  function handleAddListResponsavel() {
    if (responsavel.pessoaId === 0) {
      return
    }

    const MestreCerimonia = ListPessoa.find(
      (e) => e.pessoaId === responsavel.pessoaId,
    )

    const obj = {
      nome:
        MestreCerimonia !== undefined
          ? MestreCerimonia?.nome
          : responsavel.nome,
      id: Number(responsavel.pessoaId),
      funcao: String(responsavel.funcao),
      cpf: String(responsavel.cpf),
      responsavle: true,
    }

    if (obj.id !== 0) {
      const target = document.getElementById('responsaveis')
      target?.classList.remove('inactive')
      target?.classList.add('active')
    }

    setResponsavel({
      nome: '',
      pessoaId: 0,
      funcao: '',
      cpf: '',
    })

    const verificarDuplicidade = AddInResponsavelList.some(
      (pessoa) => pessoa.id === obj.id,
    )
    if (verificarDuplicidade) {
      return
    }

    setAddInResponsavelList([...AddInResponsavelList, obj])
  }

  const deleteMestreCerimonia = (id: number) => {
    const localePeople = AddInList.filter((e) => e.id !== id)
    const pessoa = pessoas.find((e) => e.pessoaId === id)
    if (pessoa) {
      setDeleteParticipantsIDList([
        ...deleteParticipantsIDList,
        pessoa.pessoaId,
      ])
    }
    setAddInList(localePeople)
    setFormInput({
      mestreDeCerimoniaId: 0,
    })
  }

  const deleteResponsavel = (id: number) => {
    setDeleteParticipantsIDList([...deleteParticipantsIDList, id])
    setAddInResponsavelList((prevState) =>
      prevState.filter((responsavel) => responsavel.id !== id),
    )
  }

  const handleDate = (e: string) => {
    const dia = e.slice(8, 10)
    const mes = e.slice(5, 7)
    const ano = e.slice(0, 4)
    const date = `${dia + '/' + mes + '/' + ano}`
    return date
  }

  const handleRevertDate = (e: string) => {
    const dia = e.slice(0, 2)
    const mes = e.slice(3, 5)
    const ano = e.slice(6, 10)
    // const date = `${dia+"/"+mes+"/"+ano}`
    const date = `${ano + '-' + mes + '-' + dia}`

    return date
  }

  const handleTime = (e: string, bool: boolean) => {
    if (bool) {
      setFormInput({ horaInicial: e })
    } else {
      setFormInput({ horaFinal: e })
    }
  }

  function HandleClean() {
    setFormInput({
      banner: '',
      dataFinal: '',
      dataInicial: '',
      descricao: '',
      email: '',
      enderecos: {},
      eventoId: 0,
      horaFinal: '',
      horaInicial: '',
      local: '',
      mestreDeCerimoniaId: 0,
      nome: '',
      responsaveis: [],
      site: '',
    })
    setEndereco({
      logradouro: '',
      cep: '',
      cidade: '',
      complemento: '',
      estado: '',
      numero: '',
    })
    setImagemSelecionada(undefined)
    if (AddInList.length > 0) {
      deleteMestreCerimonia(AddInList[0].id)
    }
    const idRespostaveisList: number[] = []
    for (const i of AddInResponsavelList) {
      idRespostaveisList.push(i.id)
    }
    for (const i of idRespostaveisList) {
      deleteResponsavel(i)
    }
  }

  async function sendData() {
    const Data = formInput

    const objRequired = cadastrarEventos.filter((e) => e.require === true)
    const keyEmpty: string[] = []
    for (const [key, value] of Object.entries(formInput)) {
      const emptyKey = objRequired.find((e) => e.name === key)
      if (emptyKey) {
        switch (key) {
          case 'mestreDeCerimoniaId':
            if (AddInList.length === 0) {
              notifyError(
                emptyKey.label,
                `O campo ${emptyKey.label} é obrigatorio.`,
              )
              const Verify = document
                .getElementById(key)
                ?.classList.add('inactive')
              keyEmpty.push(emptyKey.name)
            }
            break
          case 'responsaveis':
            if (AddInResponsavelList.length === 0) {
              notifyError(
                emptyKey.label,
                `O campo ${emptyKey.label} é obrigatorio.`,
              )
              const Verify = document
                .getElementById(key)
                ?.classList.add('inactive')
              keyEmpty.push(emptyKey.name)
            }
            break
          default:
            if (value === '') {
              notifyError(
                emptyKey.label,
                `O campo ${emptyKey.label} é obrigatorio.`,
              )
              const Verify = document
                .getElementById(key)
                ?.classList.add('inactive')

              keyEmpty.push(emptyKey.name)
            }
        }
      }
    }

    if (!isEmailValid) {
      notifyError('Email', `Email inválido`)
    }

    if (keyEmpty.length !== 0) {
      return
    }

    Data.dataInicial = handleDate(Data.dataInicial)
    Data.dataFinal = handleDate(Data.dataFinal)
    Data.enderecos = endereco

    try {
      const responsavelList: ResponsavelEvento[] = []
      const concatNewArrayResponsaveis = AddInResponsavelList.concat(AddInList)
      for (const responsavel of concatNewArrayResponsaveis) {
        const pessoaCoprporativo: PessoaCorporativo | null = null

        let data = null

        if (formInput.mestreDeCerimoniaId === responsavel.id) {
          data = {
            funcao: 'Mestre de Cerimõnia',
          }
        } else {
          data = {
            funcao: 'Responsável',
          }
        }

        const resProfissionalEvento = await CadastrarProfissionalEvento(data)

        if (resProfissionalEvento.status === 201) {
          const { profissionalEventoId } = resProfissionalEvento.data
          let responsavelEvento: ResponsavelEvento | null = null

          if (formInput.mestreDeCerimoniaId === responsavel.id) {
            responsavelEvento = {
              isResponsavel: false,
              pessoaId: formInput.mestreDeCerimoniaId,
              profissionalEventoId,
            }
          } else {
            responsavelEvento = {
              isResponsavel: true,
              pessoaId: responsavel.id,
              profissionalEventoId,
            }
          }
          responsavelList.push(responsavelEvento)
        }
      }

      const payload = {
        nome: formInput.nome,
        descricao: formInput.descricao,
        horaInicial: formInput.horaInicial,
        horaFinal: formInput.horaFinal,
        dataInicial: formInput.dataInicial,
        dataFinal: formInput.dataFinal,
        site: formInput.site,
        banner: formInput.banner,
        email: formInput.email,
        enderecos: [
          {
            logradouro: endereco.logradouro,
            complemento: endereco.complemento,
          },
        ],
        telefones,
        responsaveis: responsavelList,
      }

      const res = await CadastrarEvento(payload, responsavelList)
      if (res.status === 201) {
        const IdPessoa = res.data.eventoId
        if (formInput.banner !== '') {
          // image?.append('id', IdPessoa)
          await UploadFotoEventos(image, String(IdPessoa))
        }
        handleModalCreate(true)
      }
      fetchEventos()
      handleChangeReload()
    } catch (err) {
      handleModalError(true)
    }
  }

  async function handleChangeEventData() {
    const payload = {
      eventoId: formInput.eventoId,
      nome: formInput.nome,
      descricao: formInput.descricao,
      horaInicial: formInput.horaInicial,
      horaFinal: formInput.horaFinal,
      dataInicial: formInput.dataInicial,
      dataFinal: formInput.dataFinal,
      site: formInput.site,
      banner: formInput.banner,
      enderecos: [
        {
          logradouro: endereco.logradouro,
          complemento: endereco.complemento,
        },
      ],
      telefones,
      responsaveis: newParticipantsList,
    }

    payload.dataInicial = handleDate(formInput.dataInicial)
    payload.dataFinal = handleDate(formInput.dataFinal)

    const path = pathname.replace('/evento/', '')

    try {
      onOpen()
      const totalDeleteOperations =
        deleteParticipantsIDList.length + newParticipantsList.length
      /// O total de operações para salvar é o número de adições e remoções mais
      /// a requisição para PutEventos
      const progressStepValue = 100 / totalDeleteOperations + 1

      /// Deletar participantes
      for (const deleteID of deleteParticipantsIDList) {
        await DeletePessoaEvento(deleteID, formInput.eventoId)
        setLoadingProgressValue(progressStepValue)
      }
      /// Limpa a lista
      deleteParticipantsIDList.splice(0, deleteParticipantsIDList.length)

      /// Cadastrar novas pessoas ao evento
      for (const newParticipant of newParticipantsList) {
        const pessoaEvento: PessoaEvento = {
          eventoId: formInput.eventoId,
          pessoaId: newParticipant.id,
          compoeMesa: false,
          responsavel: newParticipant.responsavle,
          vinculo: '',
          /// Representantes ID: 1
          /// Mestre de cerimônia: 2
          profissionalEventoId: newParticipant.responsavle ? 1 : 2,
        }
        await CadastrarPessoaEvento(pessoaEvento)
        setLoadingProgressValue(progressStepValue)
      }

      newParticipantsList.splice(0, newParticipantsList.length)

      const res = await PutEventos(path, payload)
      setLoadingProgressValue(0)

      if (res.status === 200) {
        if (formInput.banner !== '') {
          await UploadFotoEventos(image, String(res.data.eventoId))
        }
        onClose()
        handleModalCreate(true)
      }
      fetchEventos()
    } catch (err) {
      console.warn(err, '1')
      onClose()

      handleModalError(true)
    }
  }

  const CadasterText = (
    <span>
      Falha ao cadastrar o evento.
      <br />
      Tente novamente.
    </span>
  )

  const PutText = (
    <span>
      Falha ao cadastrar o evento.
      <br />
      Tente novamente.
    </span>
  )

  function handleNomeEventoChange(e: ChangeEvent<HTMLInputElement>) {
    setFormInput({ nome: e.target.value })

    if (!e.target.value) {
      setError({
        field: 'nomeevento',
        message: 'Nome do Evento é obrigatório.',
      })
    } else {
      removeError('nomeevento')
    }
  }

  function handleDataInicialChange(e: ChangeEvent<HTMLInputElement>) {
    setFormInput({ dataInicial: e.target.value })

    if (!e.target.value) {
      setError({
        field: 'datainicial',
        message: 'Data Inicial é obrigatório.',
      })
    } else {
      removeError('datainicial')
    }
  }

  function handleHoraInicialChange(e: ChangeEvent<HTMLInputElement>) {
    handleTime(e.target.value, true)

    if (!e.target.value) {
      setError({
        field: 'horainicial',
        message: 'Hora Inicial é obrigatório.',
      })
    } else {
      removeError('horainicial')
    }
  }

  function handleAdicionarTelefone() {
    if (telefone) {
      const telefoneString = telefone
        .replaceAll('(', '')
        .replaceAll(')', '')
        .replaceAll('-', '')
        .replaceAll(' ', '')
      const ddd = telefoneString.slice(0, 2)
      const numero = telefoneString.slice(2, telefoneString.length)
      setTelefones([
        ...telefones,
        {
          ddd,
          numero,
          tipo: 'phone',
        },
      ])

      setTelefone('')
    }
  }

  function handleDataFinalChange(e: ChangeEvent<HTMLInputElement>) {
    setFormInput({ dataFinal: e.target.value })

    if (!e.target.value) {
      setError({
        field: 'datafinal',
        message: 'Data Final é obrigatório.',
      })
    } else {
      removeError('datafinal')
    }
  }

  function handleHoraFinalChange(e: ChangeEvent<HTMLInputElement>) {
    handleTime(e.target.value, false)

    if (!e.target.value) {
      setError({
        field: 'horafinal',
        message: 'Hora Final é obrigatório.',
      })
    } else {
      removeError('horafinal')
    }
  }

  function handleMestreCerimoniaChange(e: ChangeEvent<HTMLInputElement>) {
    setMestreCerimonia({
      cpf: '',
      nome: e.target.value,
      pessoaId: 0,
    })
  }

  function handleResponsavelChange(e: ChangeEvent<HTMLInputElement>) {
    setResponsavel((prev) => ({ ...prev, nome: e.target.value }))
  }

  function handleLocalChange(e: ChangeEvent<HTMLInputElement>) {
    setEndereco({ complemento: e.target.value })

    if (!e.target.value) {
      setError({
        field: 'local',
        message: 'Local é obrigatório.',
      })
    } else {
      removeError('local')
    }
  }

  function handleEnderecoChange(e: ChangeEvent<HTMLInputElement>) {
    setEndereco({ logradouro: e.target.value })

    if (!e.target.value) {
      setError({
        field: 'endereco',
        message: 'Endereço é obrigatório.',
      })
    } else {
      removeError('endereco')
    }
  }

  function handleSelecionarImagem(event: any) {
    const arquivo = event.target.files[0]
    const leitor = new FileReader()

    leitor.onload = function () {
      setImagemSelecionada(leitor.result)
    }

    const Teste = new FormData()
    Teste.append('file', event.target.files[0])

    setFormInput({
      banner: arquivo.name,
    })

    setImage(Teste)
    leitor.readAsDataURL(arquivo)
  }

  const handleValidatedForm = (event: FocusEvent<HTMLInputElement>) => {
    const target = event.target

    if (target.value === '' || target.value === ' ') {
      target.classList.remove('active')
      target.classList.add('inactive')
    } else {
      target.classList.remove('inactive')
      target.classList.add('active')
    }
  }

  function isValidEmail(email: string): boolean {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    return emailRegex.test(email)
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = event.target.value
    if (newEmail === '') {
      setEmailIsValid(true)
    } else {
      setEmailIsValid(isValidEmail(newEmail))
    }
  }

  return (
    <ContainerEvent>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>
              <Text fontFamily="inter-bold" color="gray">
                Salvando
              </Text>
            </AlertDialogHeader>
            <AlertDialogBody p="50px 20px 0px 20px">
              <Progress hasStripe value={loadingProgressValue} />
            </AlertDialogBody>

            <AlertDialogFooter>
              <CButton
                ref={cancelRef}
                colorScheme="transparent"
                onClick={onClose}
              ></CButton>
              <CButton
                colorScheme="transparent"
                onClick={onClose}
                ml={3}
              ></CButton>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <DetalheDoEvento
        sucessOrError={true}
        showModal={modalEvent.create}
        handleClose={() => handleModalCreate(false)}
        mensageHeader={newEvent ? 'Cadastro' : 'Edição'}
        mensageBody={
          newEvent
            ? 'Evento cadastrado com sucesso.'
            : 'Evento alterado com sucesso.'
        }
        nextFunction={false}
        urlPath={'/eventosCadastrados'}
      />

      <DetalheDoEvento
        sucessOrError={false}
        showModal={modalEvent.error}
        handleClose={() => handleModalError(false)}
        mensageHeader="Não podemos prosseguir"
        mensageBody={newEvent ? CadasterText : PutText}
        nextFunction={true}
        // urlPath={"/eventosCadastrados"}
      />
      {/* <Card> */}
      {/* <Card.Header>Cadastro de Eventos</Card.Header> */}
      {/* <Card.Body> */}
      <div className="PrimaryContainer">
        <div>
          <InputFormControl
            error={getErrorMessageByFieldName('nomeevento')}
            id="nome"
            placeholder="Nome do Evento"
            value={formInput.nome}
            onBlur={handleValidatedForm}
            onChange={handleNomeEventoChange}
          />
          <span>
            <InputFormControl
              onBlur={handleValidatedForm}
              id="dataInicial"
              error={getErrorMessageByFieldName('datainicial')}
              type="date"
              max={formInput.dataFinal}
              value={formInput.dataInicial}
              onChange={handleDataInicialChange}
            />
            <InputFormControl
              onBlur={handleValidatedForm}
              id="horaInicial"
              error={getErrorMessageByFieldName('horainicial')}
              type="time"
              value={formInput.horaInicial}
              onChange={handleHoraInicialChange}
            />
            até
            <InputFormControl
              onBlur={handleValidatedForm}
              id="dataFinal"
              error={getErrorMessageByFieldName('datafinal')}
              type="date"
              min={formInput.dataInicial}
              value={formInput.dataFinal}
              onChange={handleDataFinalChange}
            />
            <InputFormControl
              onBlur={handleValidatedForm}
              id="horaFinal"
              error={getErrorMessageByFieldName('horafinal')}
              type="time"
              value={formInput.horaFinal}
              onChange={handleHoraFinalChange}
            />
          </span>
          <InputFormControl
            type="text"
            id="complemento"
            error={getErrorMessageByFieldName('local')}
            placeholder="Local"
            value={endereco.complemento}
            onChange={handleLocalChange}
          />
          <InputFormControl
            type="text"
            id="logradouro"
            error={getErrorMessageByFieldName('endereco')}
            placeholder="Endereço"
            value={endereco.logradouro}
            onChange={handleEnderecoChange}
          />
        </div>
        <div className="EventoContainer">
          <div className="Visualizar">
            <span
              style={
                imagemSelecionada
                  ? {
                      // border: '5px solid #333',
                      height: '100%',
                    }
                  : { border: 0 }
              }
            >
              {!imagemSelecionada ? (
                <>
                  <strong>Banner do evento</strong>
                  <strong>(opcional)</strong>
                  <input
                    type="file"
                    accept="image/jpeg"
                    onChange={handleSelecionarImagem}
                  />
                </>
              ) : (
                <>
                  <img src={imagemSelecionada} alt="Select image" />
                  <input
                    type="file"
                    accept="image/jpeg"
                    onChange={handleSelecionarImagem}
                  />
                  {/* <button>X</button> */}
                </>
              )}
            </span>
          </div>
        </div>
      </div>
      <hr />
      <div className="SecondaryContainer">
        <Form.Control
          as="textarea"
          placeholder="Descrição"
          value={formInput.descricao}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            const inputValue = e.target.value
            if (inputValue.length <= 250) {
              setFormInput({ descricao: e.target.value })
            }
          }}
        />
        <p>Caracteres restantes: {250 - formInput.descricao.length}</p>
        <div>
          <InputFormControl
            value={mestreCerimonia.nome}
            maxLength={200}
            id="mestreDeCerimoniaId"
            // className="TdLength"
            onClick={() => setMestreBool(true)}
            onBlur={() => {
              setTimeout(() => {
                setMestreBool(false)
              }, 100)
            }}
            onChange={handleMestreCerimoniaChange}
          />
          <Button
            disabled={AddInList.length !== 0 && true}
            onClick={() => {
              handleAddList()
            }}
          >
            Adicionar
          </Button>
        </div>
        {/* #ccc7c7 */}
        <AutoComplete primary={MestreBool}>
          <ul>
            {ListPessoa.filter((e) =>
              e.nome
                .toLocaleLowerCase()
                .includes(mestreCerimonia.nome.toLocaleLowerCase()),
            ).map((e) => (
              <li
                key={e.pessoaId}
                onClick={() => saveMestreCerimonia(Number(e.pessoaId))}
              >
                {e.nome}
              </li>
            ))}
          </ul>
        </AutoComplete>

        <p>Mestre de Cerimônias adicionado:</p>
        <table className="tableStyle">
          <thead></thead>
          <tbody>
            {AddInList.length > 0 &&
              AddInList.map((e: ITypeAddResponsavel) => (
                <tr key={e.id}>
                  <td>
                    <p>{e.nome}</p>
                  </td>
                  <td>
                    <Button
                      variant="link"
                      onClick={() => deleteMestreCerimonia(e.id)}
                    >
                      <FaTrashAlt
                        style={{ cursor: 'pointer', color: '#dc3545' }}
                      />
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <hr style={{ width: '60%' }} />
      <div className="ThreeContainer">
        <div>
          <InputFormControl
            value={responsavel.nome}
            maxLength={200}
            id="responsaveis"
            // className="TdLengthRes"
            onClick={() => setResponsavelBool(true)}
            onBlur={() => {
              setTimeout(() => {
                setResponsavelBool(false)
              }, 100)
            }}
            onChange={handleResponsavelChange}
          />
          <Button onClick={handleAddListResponsavel}>Adicionar</Button>
        </div>
        <AutoComplete primary={responsavelBool}>
          <ul>
            {ListPessoa.map((e) => (
              <li key={e.pessoaId} onClick={() => SaveResponsavel(e)}>
                {e.nome}
              </li>
            ))}
          </ul>
          {/* )} */}
        </AutoComplete>
        <p>Responsáveis Adicionados:</p>
        <table className="tableStyle">
          <thead></thead>
          <tbody>
            {AddInResponsavelList.length > 0 &&
              AddInResponsavelList.map((e: ITypeAddResponsavel) => (
                <tr key={e.id}>
                  <td>
                    <p>{e.nome}</p>
                  </td>
                  <td>
                    <Button
                      variant="link"
                      onClick={() => deleteResponsavel(e.id)}
                    >
                      <FaTrashAlt
                        style={{ cursor: 'pointer', color: '#dc3545' }}
                      />
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        <HStack w="60%" mt="30px">
          <Form.Control
            placeholder="Telefone"
            value={telefone}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setTelefone(telefoneMask(e.target.value))
            }}
          />
          <Button onClick={handleAdicionarTelefone}>Adicionar</Button>
        </HStack>
        <VStack alignItems="start">
          {telefones.length !== 0 && <Text>Telefones adicionados</Text>}
          {telefones.map((telefone, index) => (
            <HStack key={index} justifyContent="start" alignContent="start">
              <BsTelephone />
              <Text mb="0" fontFamily="inter-medium">
                {telefoneMask(telefone.ddd + telefone.numero)}
              </Text>
            </HStack>
          ))}
        </VStack>
        <Box w="100%">
          <Form.Control
            placeholder="E-mail"
            value={formInput.email}
            onBlur={(e) => {
              if (e.target.value && !isValidEmail(e.target.value)) {
                setEmailIsValid(false)
              } else {
                setEmailIsValid(true)
              }
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setFormInput({ email: e.target.value })
              if (isValidEmail(e.target.value)) {
                setEmailIsValid(true)
              }
            }}
          />
          {!isEmailValid && (
            <Text color="red" ml="10px" mt="10px">
              E-mail inválido
            </Text>
          )}
        </Box>

        <Form.Control
          placeholder="Site de Referência"
          value={formInput.site}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFormInput({ site: e.target.value })
          }
        />
      </div>
      <span className="ContainerButton">
        {newEvent ? (
          <ButtonContainer
            // disabled={!isFormatValid}
            onClick={sendData}
            variant="primary"
          >
            Salvar
          </ButtonContainer>
        ) : (
          <Button onClick={handleChangeEventData} variant="primary">
            Alterar
          </Button>
        )}
        <Button onClick={HandleClean} variant="primary">
          Limpar
        </Button>
        <Link to={'/eventosCadastrados'}>
          <Button variant="danger">Cancelar</Button>
        </Link>
      </span>
      {/* </Card.Body> */}
      {/* </Card> */}
    </ContainerEvent>
  )
}
