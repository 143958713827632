/* eslint-disable react-hooks/rules-of-hooks */
import React, {
  useReducer,
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from 'react'
import {
  Button,
  Card,
  Modal,
  OverlayTrigger,
  ProgressBar,
} from 'react-bootstrap'

import {
  AutoComplete,
  Buttons,
  ContainerFooter,
  ContainerFrequencia,
  ContainerTable,
  InputFormControl,
} from './styled'
import { BsPeopleFill, BsPersonFillAdd } from 'react-icons/bs'
import BootstrapTable from 'react-bootstrap-table-next'
import { Link, useLocation, useParams } from 'react-router-dom'
import {
  renderTooltipAdd,
  renderTooltipDelete,
  renderTooltipEdit,
} from '../../utils/Tooltip'
import { FaEdit, FaExclamationCircle, FaSearch, FaTrash } from 'react-icons/fa'
import {
  CadastrarPessoaEvento,
  CadastrarProfissionalEvento,
  DeletePessoaEvento,
  DeleteRepresentante,
  GetPessoaByNameOrTitulo,
  GetPessoasByEvento,
  PostPessoaEvento,
  PutPessoaEvento,
} from '../../service'
import { AxiosResponse } from 'axios'
import { AiOutlineClose } from 'react-icons/ai'
import { ContextAPI } from '../../hooks/useContextApi'
import { DetalheDoEvento } from '../../components/Modal'
import styled, { css } from 'styled-components'
import { GiHamburgerMenu } from 'react-icons/gi'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import TableIcon from '../../assets/images/table.svg'
import DragTable from './DragTable'

interface StatusProps {
  nome: string
  pessoaId: number
}

type ReducerProp = {
  search: string
  pessoas: PessoaFrequenciaEvento[]
}

type frequenciaEvento = {
  pessoaEventoList: PessoaFrequenciaEvento[]
}

export function FrequenciaEventos() {
  const [button, setButton] = useState<String>('Finalizar Chamada')
  const [registerFrequenciaEvento, setRegisterFrequenciaEvento] =
    useState(false)
  const [errorCadaster, setErrorCadaster] = useState(false)
  const { id: eventoId } = useParams()
  const [AddStatusList, setAddStatusList] = useState<StatusProps[]>([])
  const [evento, setEvento] = useState<Evento | null>(null)
  const [deletePessoa, setDeletePessoa] =
    useState<PessoaFrequenciaEvento | null>(null)

  const [WidthTable, setWidthTable] = useState<number>(0)

  const {
    modalEvent,
    eventos,
    notificacao,
    handleModalCreate,
    handleModalDelete,
    handleModalError,
    handleModalSucess,
    handleAlterName,
    handleChangeReload,
    fetchNotiificacao,
    handleChangeReloadTeste,
    handleChangeNewNotify,
    handleChangeUrlRoute,
  } = useContext(ContextAPI)

  // const [pessoas, setPessoas] = useState<PessoaFrequenciaEvento[]>([])
  const [pessoaBool, setPessoaBool] = useState<boolean>(false)
  const [pessoaSelecionada, setPessoaSelecionada] =
    useState<PessoaFrequenciaEvento | null>(null)

  let isNotRefrash = false

  const [frequenciaEvento, dispatch] = useReducer(
    (
      prev: frequenciaEvento,
      next: { pessoaEventoList: PessoaFrequenciaEvento[] },
    ) => {
      return { ...prev, pessoaEventoList: next.pessoaEventoList }
    },
    {
      pessoaEventoList: [],
    },
  )

  const [state, setState] = useReducer(
    (prev: ReducerProp, next: Partial<ReducerProp>) => {
      return { ...prev, ...next }
    },
    {
      search: '',
      pessoas: [],
    },
  )

  useEffect(() => {
    handleChangeReload()
  }, [])

  // useEffect(() => {

  // }, [DataList])

  const fetchFrequenciaEvento = useCallback(async () => {
    const resPessoasByEvento = await GetPessoasByEvento(Number(eventoId))

    if (resPessoasByEvento.status === 200) {
      const pessoas: PessoaFrequenciaEvento[] = resPessoasByEvento.data

      const pessoasCopy = [...pessoas]

      const updatedPessoa: PessoaFrequenciaEvento[] = pessoas
        .map((pessoa) => {
          if (pessoa.representantes !== undefined) {
            const isRepresentante = pessoasCopy.some((pessoaCopy) => {
              if (
                pessoaCopy.representantes !== undefined &&
                pessoaCopy.representantes.length > 0
              ) {
                const isRepres = pessoaCopy.representantes.find(
                  (p) => p.pessoaId === pessoa.pessoaId,
                )

                if (isRepres) {
                  return true
                }
              }
              return false
            })

            return { ...pessoa, isRepresentante, isPrimeiraChamada: false }
          }

          return pessoa
        })
        .filter((pessoa) => pessoa !== undefined)

      updatedPessoa.forEach((pessoa) => {
        const pessoaEvento = pessoa.pessoaEventos.find(
          (pessoaevento) => String(pessoaevento.eventoId) === eventoId,
        )

        if (pessoaEvento) {
          pessoa.status = pessoaEvento.vinculo
        } else {
          pessoa.status = ''
        }
      })

      // vai organizar a lista:
      // -> 1 da lista como sendo o Representado
      //   -> 2 e logo depois os representantes
      const listaOrganizada = organizarLista(updatedPessoa)

      for (const i in listaOrganizada) {
        if (!isNotRefrash) {
          listaOrganizada[i].jaRegistrado = listaOrganizada[i].status !== null

          isNotRefrash = true
        }
      }

      dispatch({ pessoaEventoList: listaOrganizada })
    }

    if (eventoId !== '' && eventoId !== undefined) {
      // const resOneEvento = await GetOneEventos(String(eventoId))
      const GetOneEvento = eventos.find(
        (evento) => String(evento.eventoId) === eventoId,
      )
      if (GetOneEvento) {
        setEvento(GetOneEvento)
      }
    }
  }, [eventoId, eventos])

  const organizarLista = (pessoas: PessoaFrequenciaEvento[]) => {
    const listaOrganizada: PessoaFrequenciaEvento[] = []

    const processarPessoa = (pessoa: PessoaFrequenciaEvento) => {
      listaOrganizada.push(pessoa)
      if (pessoa.representantes !== undefined) {
        const representantes = pessoa.representantes.map((representante) =>
          pessoas.find((p) => p.pessoaId === representante.pessoaId),
        )
        representantes.forEach((representante) => {
          if (representante !== undefined) {
            processarPessoa(representante)
          }
        })
      }
    }

    pessoas.forEach((pessoa) => {
      if (
        pessoa.representantes !== undefined &&
        pessoa.representantes?.length > 0
      ) {
        processarPessoa(pessoa)
      } else if (!listaOrganizada.includes(pessoa)) {
        listaOrganizada.push(pessoa)
      }
    })

    return listaOrganizada
  }

  useEffect(() => {
    handleAlterName(`Frequência  - ${evento?.nome} | N° ${evento?.eventoId}`)
    handleChangeUrlRoute('/eventosCadastrados')
    fetchNotiificacao(evento?.eventoId === undefined ? '' : evento?.eventoId)

    if (isNotRefrash) {
      isNotRefrash = true
    } else {
      fetchFrequenciaEvento()
    }
  }, [fetchFrequenciaEvento])

  useEffect(() => {
    const Table = document.getElementById('TableContainer') as HTMLElement

    setWidthTable(Table.offsetWidth - 1)
  }, [frequenciaEvento.pessoaEventoList])

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return
    }

    const { source, destination } = result

    // Reordena as linhas com base na posição final
    const reorderedRows = Array.from(frequenciaEvento.pessoaEventoList)
    const [removed] = reorderedRows.splice(source.index, 1)
    reorderedRows.splice(destination.index, 0, removed)

    // Atualiza o estado com as linhas reordenadas
    // setRows(reorderedRows)
    dispatch({
      pessoaEventoList: reorderedRows,
    })
  }
  const handleDragStart = (e: any, id: any) => {
    // Adicione o código necessário para iniciar o arrastar e soltar da linha
    // Neste exemplo, vamos apenas imprimir o ID da linha no console
    //
  }

  const handleDragEnd = () => {
    // Adicione o código necessário para finalizar o arrastar e soltar da linha
    // Neste exemplo, não precisamos fazer nada
  }

  function drag(ev: any) {
    ev.dataTransfer.setData('text', ev.target.id)
  }

  async function handleSearchPessoas() {
    try {
      let response: AxiosResponse<any, any> = [] as any

      if (state.search !== '') {
        response = await GetPessoaByNameOrTitulo(state.search)

        setState({ pessoas: response.data })
        setPessoaBool(true)
      }
    } catch (error) {
      setState({ pessoas: [] })
    }
  }

  const handleInputSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      setState({ search: '' })
      setPessoaSelecionada(null)
      setPessoaBool(false)
      return
    }

    setState({ search: event.target.value })
  }

  function handleInputBlur() {
    if (pessoaSelecionada === null) {
      setPessoaBool(false)
    }
  }

  const savePessoa = (pessoa: PessoaFrequenciaEvento) => {
    setState({
      search: pessoa.nome,
      pessoas: [],
    })
    setPessoaSelecionada(pessoa)
    setPessoaBool(false)
  }

  const HandleDeleteOpenModal = (pessoa: PessoaFrequenciaEvento) => {
    handleModalDelete(true)
    setDeletePessoa(pessoa)
  }

  const HandleDelete = async () => {
    try {
      let res: AxiosResponse<any, any> = [] as any
      if (deletePessoa !== null) {
        res = await DeletePessoaEvento(deletePessoa.pessoaId, Number(eventoId))
        if (res.status === 200) {
          dispatch({
            pessoaEventoList: frequenciaEvento.pessoaEventoList.filter(
              (prevState) => prevState.pessoaId !== deletePessoa.pessoaId,
            ),
          })

          if (
            deletePessoa.representantes !== undefined &&
            deletePessoa.representantes?.length > 0
          ) {
            // remover todos os representantes da Pessoa que está
            // sendo representado
            for (const representante of deletePessoa.representantes) {
              const resDeleteRepresentante = await DeleteRepresentante(
                deletePessoa.pessoaId,
                representante.pessoaId,
              )

              // remove cada representante da pessoa representada do evento
              const res = await DeletePessoaEvento(
                representante.pessoaId,
                Number(eventoId),
              )

              if (res.status === 200) {
                // vai atualizando a lista
                dispatch({
                  pessoaEventoList: frequenciaEvento.pessoaEventoList.filter(
                    (prevState) =>
                      prevState.pessoaId !== representante.pessoaId,
                  ),
                })
              }
            }
          }

          handleModalDelete(false)
          handleModalSucess(true)
          setDeletePessoa(null)
        }
      }
    } catch (error) {
      handleModalDelete(false)

      handleModalError(true)
    }
  }

  async function handleSavePessoaEvento() {
    try {
      if (pessoaSelecionada !== null) {
        const isExistList = frequenciaEvento.pessoaEventoList.find(
          (pessoa) => pessoa.pessoaId === pessoaSelecionada.pessoaId,
        )

        if (isExistList) {
          return
        }

        const data = {
          funcao: 'Participante',
        }

        const resProfissionalEvento = await CadastrarProfissionalEvento(data)

        if (resProfissionalEvento.status === 201) {
          const { profissionalEventoId } = resProfissionalEvento.data
          let pessoaEvento: PessoaEvento | null = null

          pessoaEvento = {
            eventoId: Number(eventoId),
            pessoaId: Number(pessoaSelecionada.pessoaId),
            profissionalEventoId,
            vinculo: 'Presente',
          }

          const res = await CadastrarPessoaEvento(pessoaEvento)

          if (res.status === 201) {
            pessoaSelecionada.status = 'Presente'
            dispatch({
              pessoaEventoList: [
                ...frequenciaEvento.pessoaEventoList,
                pessoaSelecionada,
              ],
            })
            setPessoaSelecionada(null)
            setState({ search: '' })
            handleModalCreate(true)
          }
        }
      }
    } catch (err) {
      handleModalError(true)
    }
  }

  async function handleFinalizarChamada() {
    try {
      if (button === 'Finalizar Chamada') {
        setRegisterFrequenciaEvento(false)

        const ValidarListaObj = frequenciaEvento.pessoaEventoList.filter(
          (e) => {
            const existe = e.jaRegistrado !== true
            // const Representante = e.status !== null
            return existe
          },
        )

        for (const pessoaEvento of ValidarListaObj) {
          const savePessoaEvento = {
            eventoId: Number(eventoId),
            pessoaId: pessoaEvento.pessoaId,
            presente:
              pessoaEvento.status === 'Presente'
                ? 'Nominado'
                : pessoaEvento.status,
          }

          const res = await PostPessoaEvento(savePessoaEvento).finally(() =>
            setTimeout(() => {
              handleChangeReloadTeste()
              handleChangeNewNotify(true)
            }, 200),
          )
          if (res.status === 200) {
            const p: PessoaEvento = res.data
            const currentPessoaEventoIndex =
              frequenciaEvento.pessoaEventoList.findIndex((item) => {
                return item.pessoaId === p.pessoaId
              })
            frequenciaEvento.pessoaEventoList[currentPessoaEventoIndex].status =
              p.vinculo
            dispatch({
              pessoaEventoList: frequenciaEvento.pessoaEventoList,
            })
          }
        }

        setButton('Retomar a Chamada')
      }
    } catch (err) {
      setErrorCadaster(false)
    }
  }

  const handleStatusChange = (
    pessoa: PessoaFrequenciaEvento,
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { value } = event.target

    const currentPessoaEventoIndex =
      frequenciaEvento.pessoaEventoList.findIndex((item) => {
        return item.pessoaId === pessoa.pessoaId
      })

    if (currentPessoaEventoIndex < 0) {
      return frequenciaEvento.pessoaEventoList
    }

    frequenciaEvento.pessoaEventoList[currentPessoaEventoIndex].status = value

    dispatch({ pessoaEventoList: frequenciaEvento.pessoaEventoList })

    setAddStatusList([
      ...AddStatusList,
      { nome: value, pessoaId: pessoa.pessoaId },
    ])
  }

  const rowClasses = (row: PessoaFrequenciaEvento) => {
    let classes = ''

    if (row.isRepresentante) {
      classes += 'representante-row '
    }

    if (row.isPrimeiraChamada) {
      classes += 'status-primeira-chamada '
    }

    if (row.status === 'Ausente') {
      classes += 'status-pessoa-ausente '
    }

    return classes.trim()
  }

  const [clickedRow, setClickedRow] = useState<number | null>(null)

  function handleClass(row: any) {
    const rowClassNames = rowClasses(row)

    const StyledSpan = styled.span`
      ${clickedRow === row.pessoaId &&
      rowClassNames.includes('status-primeira-chamada') &&
      css`
            color: rowClassNames.includes('status-primeira-chamada') ? #ff7f00 : unset;
          `}
    `

    const handleClick = (rowId: number) => {
      if (clickedRow === rowId) {
        setClickedRow(null)
      } else {
        setClickedRow(rowId)
      }
    }

    const content = (
      <>
        {row.isPrimeiraChamada && (
          <FaExclamationCircle style={{ marginRight: '5px' }} />
        )}
        <StyledSpan onClick={() => handleClick(row.pessoaId)}>
          {row.nome}
        </StyledSpan>
      </>
    )

    return content
  }

  function clearText() {
    setState({
      search: '',
      pessoas: [],
    })
    setPessoaSelecionada(null)
    setPessoaBool(false)
  }

  const countPresentes = frequenciaEvento.pessoaEventoList.reduce(
    (count: number, pessoa: PessoaFrequenciaEvento) => {
      if (pessoa.status === 'Presente') {
        return (count += 1)
      }
      return count
    },
    0,
  )

  const countNominados = frequenciaEvento.pessoaEventoList.reduce(
    (count, pessoa) => {
      if (pessoa.status === 'Nominado') {
        return (count += 1)
      }
      return count
    },
    0,
  )

  const countAusentes = frequenciaEvento.pessoaEventoList.reduce(
    (count, pessoa) => {
      if (pessoa.status === 'Ausente') {
        return (count += 1)
      }
      return count
    },
    0,
  )

  function handleSendData() {
    if (button === 'Finalizar Chamada') {
      setRegisterFrequenciaEvento(true)
    } else {
      setButton('Finalizar Chamada')
    }
  }

  return (
    <>
      <Modal
        show={registerFrequenciaEvento}
        onHide={() => setRegisterFrequenciaEvento(false)}
      >
        <Modal.Header style={{ background: '#D1E7DD' }} closeButton>
          <Modal.Title style={{ color: '#2A664A' }}>
            Finalizar Chamada
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Deseja mesmo finalizar a chamada?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setRegisterFrequenciaEvento(false)}
          >
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleFinalizarChamada}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={errorCadaster} onHide={() => setErrorCadaster(false)}>
        <Modal.Header style={{ background: '#F8D7DA' }} closeButton>
          <Modal.Title style={{ color: 'rgb(116, 31, 39)' }}>
            Não podemos prosseguir
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Ocorreu um erro durante a confirmação da chamada. Tente novamente.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setErrorCadaster(false)}>
            Fechar
          </Button>
          <Button variant="primary" onClick={() => setErrorCadaster(false)}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* cadastrar PessoaEvento */}
      <DetalheDoEvento
        sucessOrError={true}
        showModal={modalEvent.create}
        handleClose={() => handleModalCreate(false)}
        mensageHeader="Cadastro"
        mensageBody="Pessoa cadastrada no evento com sucesso."
        nextFunction={true}
        // urlPath={'/'}
      />

      {/* <DetalheDoEvento
        sucessOrError={false}
        showModal={modalEvent.error}
        handleClose={() => handleModalError(false)}
        mensageHeader="Erro"
        mensageBody="Tivemos um erro ao cadastrar a pessoa no evento. Tente novamente."
        nextFunction={true}
      /> */}

      {/* Delete PessoaEvento */}
      <DetalheDoEvento
        sucessOrError={false}
        showModal={modalEvent.delete}
        handleClose={() => handleModalDelete(false)}
        mensageHeader="Deletar"
        mensageBody="Gostaria de deletar essa pessoa do evento?"
        nextFunction={true}
        handleNextFunction={() => HandleDelete()}
      />

      <DetalheDoEvento
        sucessOrError={true}
        showModal={modalEvent.sucess}
        handleClose={() => handleModalSucess(false)}
        mensageHeader="Deletado"
        mensageBody="Pessoa deletada do evento com sucesso."
        nextFunction={true}
      />

      <DetalheDoEvento
        sucessOrError={false}
        showModal={modalEvent.error}
        handleClose={() => handleModalError(false)}
        mensageHeader="Não podemos prosseguir"
        mensageBody="Tivemos um erro. Tente novamente."
        nextFunction={true}
      />

      <ContainerFrequencia>
        <div className="header">
          <div className="container">
            <div>
              <InputFormControl
                placeholder="Buscar por nome ou titulo"
                value={state.search}
                maxLength={200}
                onChange={handleInputSearch}
                onBlur={() => handleInputBlur()}
              />
              {state.search !== '' ? (
                <AiOutlineClose onClick={clearText} />
              ) : (
                ''
              )}
              <Button
                className="button-search"
                variant="primary"
                onClick={handleSearchPessoas}
              >
                <span className="search">
                  <FaSearch />
                </span>
              </Button>
              <Button className="button-add" onClick={handleSavePessoaEvento}>
                <span>
                  <BsPersonFillAdd />
                </span>
              </Button>
            </div>

            {state.search !== '' && state.pessoas.length > 0 && (
              <AutoComplete primary={pessoaBool}>
                <ul>
                  {state.pessoas.map((e) => (
                    <li key={e.pessoaId} onClick={() => savePessoa(e)}>
                      {e.nome}
                    </li>
                  ))}
                </ul>
              </AutoComplete>
            )}
          </div>
          <div>
            <Button onClick={handleSendData}>{button}</Button>
          </div>
        </div>

        <ContainerTable className="tableStyle" WidthContainer={WidthTable}>
          <DragDropContext onDragEnd={onDragEnd}>
            <table id="TableContainer">
              <thead>
                <tr style={{ width: '100%' }}>
                  {THead.map((e: string) => (
                    <th key={e}>{e}</th>
                  ))}
                </tr>
              </thead>
              <Droppable droppableId="rows">
                {(provided) => (
                  <tbody
                    style={{ width: '100%' }}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {frequenciaEvento.pessoaEventoList.map((row, index) => (
                      <Draggable
                        key={row.pessoaId}
                        draggableId={String(row.pessoaId)}
                        index={index}
                      >
                        {(provided) => (
                          <tr
                            style={{ width: '100%' }}
                            {...provided.draggableProps}
                            // {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            className="DragDropCOntainer"
                          >
                            <td>
                              <>
                                {row.representantes !== undefined &&
                                  row.representantes?.length > 0 && (
                                    <p>Sendo Representando</p>
                                  )}
                                {row.representantes?.length === 0 && (
                                  <select
                                    name=""
                                    id=""
                                    value={
                                      row.status !== null ? row.status : ''
                                    }
                                    onChange={(event) =>
                                      handleStatusChange(row, event)
                                    }
                                  >
                                    <option value=""></option>
                                    <option value="Presente">Presente</option>
                                    <option value="Ausente">Ausente</option>
                                    {button.trim() === 'Retomar a Chamada' && (
                                      <option value="Nominado">Nominado</option>
                                    )}
                                  </select>
                                )}
                              </>
                            </td>
                            <td>{handleClass(row)}</td>
                            <td>{row.titulo}</td>
                            <td>{row.precedencia}</td>
                            <td>
                              {' '}
                              <div>
                                <TableIconImage />
                              </div>
                            </td>
                            <td>
                              {' '}
                              <div>
                                <Link
                                  // to={`/representante/evento/${eventoId}/pessoa/${row.pessoaId}`}
                                  to={'#'}
                                  tabIndex={-1}
                                >
                                  <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltipAdd}
                                  >
                                    <Button variant="link">
                                      <BsPeopleFill
                                        style={{ color: '#004a80' }}
                                      />
                                    </Button>
                                  </OverlayTrigger>
                                </Link>
                                <Link
                                  to={`/pessoa/${row.pessoaId}`}
                                  // to={'#'}
                                  tabIndex={-1}
                                >
                                  <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltipEdit}
                                  >
                                    <Button variant="link">
                                      <FaEdit style={{ color: '#004a80' }} />
                                    </Button>
                                  </OverlayTrigger>
                                </Link>

                                <Button
                                  variant="link"
                                  {...provided.dragHandleProps}
                                  onMouseDown={(e) =>
                                    handleDragStart(e, row.pessoaId)
                                  }
                                >
                                  <GiHamburgerMenu
                                    style={{ color: '#004a80' }}
                                  />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </table>
          </DragDropContext>
        </ContainerTable>
      </ContainerFrequencia>

      <ContainerFooter>
        <div>
          <div>
            {button === 'Retomar a Chamada' && <span>Nominado</span>}
            <span>Presentes</span>
            <span>Ausentes</span>
          </div>

          <div>
            {button === 'Retomar a Chamada' && (
              <span>
                <ProgressBar className="progrss" now={countNominados * 10} />
              </span>
            )}
            <span>
              <ProgressBar className="progrss" now={countPresentes * 10} />
            </span>
            <span>
              <ProgressBar className="progrss" now={countAusentes * 10} />
            </span>
          </div>

          {/*
            <span>
              Nominando <ProgressBar now={60} />
            </span>
            <span>
              Presente <ProgressBar now={60} />
            </span> */}
        </div>

        <Buttons>
          <Link to={'/eventosCadastrados'}>
            <Button>Voltar</Button>
          </Link>
        </Buttons>
      </ContainerFooter>
    </>
  )
}

const THead = ['Status', 'Nome', 'Título', 'Precedência', 'Mesa', 'Ações']

export const TableIconImage = () => {
  return (
    <>
      <svg
        width="512px"
        height="512px"
        viewBox="-16 0 512 512"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="currentColor"
          d="M32,448l-16,0c-8.837,0 -16,-7.163 -16,-16l0,-240l160,-128l320,0l0,240c0,8.825 -7.143,15.98 -16,16l-16,0c-8.837,0 -16,-7.163 -16,-16l0,-149.333l-80,71.111l0,206.222c0,8.825 -7.143,15.98 -16,16l-16,0c-8.837,0 -16,-7.163 -16,-16l0,-192l-256,0l0,192c0,8.825 -7.143,15.98 -16,16Zm128,-128l-16,0c-8.837,0 -16,-7.163 -16,-16l0,-48l48,0l0,48c0,8.825 -7.143,15.98 -16,16Z"
        ></path>
      </svg>
    </>
  )
}
