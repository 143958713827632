import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
  FocusEvent,
} from 'react'
import { BuscarPessoa, GetEventos, responseNotfy } from '../service'
import Stomp from 'stompjs'
import { useLocation } from 'react-router-dom'

type TChildrenNode = {
  children: ReactNode
}

export const ContextAPI = createContext({} as IPropContext)

export function ContextProvider({ children }: TChildrenNode) {
  const [eventos, setEventos] = useState<Evento[]>([])
  const [pessoas, setPessoas] = useState<Pessoa[]>([])
  const [notificacao, setNotificacao] = useState<any[]>([])

  const [newNotificacao, setNewNotificacao] = useState<boolean>(false)

  const [nameHeader, setNameHeader] = useState<string>('')
  const [urlRouter, setUrlRouter] = useState<string>('')
  const [URLPath, setURLPath] = useState('')

  const [isPrivet, setIsPrivet] = useState<boolean>(true)

  const [reload, setReload] = useState<boolean>(false)
  const [reloadTeste, setReloadTeste] = useState<boolean>(false)

  const [RetornoWS, setRetornoWS] = useState(null)

  const [RoleAccess, setRoleAccess] = useState<string|null>(()=>{
    const access = sessionStorage.getItem('RoleAccess')
    return access
  })

  function UpadateRoleAccess () {
    const access = sessionStorage.getItem('RoleAccess')
    setRoleAccess(access)
  }


  const [modalEvent, setModalEvent] = useReducer(
    (prev: Reducer, next: Partial<Reducer>) => {
      return { ...prev, ...next }
    },
    {
      create: false,

      delete: false,

      sucess: false,

      alterated: false,

      error: false,
    },
  )

  useEffect(() => {
    // Estabelece a conexão com o servidor WebSocket
    const socket = new WebSocket(
      'wss://eventos-dsv-backend-dsv.apps.ocp.tjgo.jus.br:8080/ws',
    ) // ou const socket = io('http://localhost:3000');
    const StompCli = Stomp.over(socket)

    const auth = sessionStorage.getItem('jwtToken')

    StompCli.connect({ Authorization: auth }, function (frame) {
      StompCli.subscribe('/topic/1/notifications', function (notification) {
        HandleRetornoWS(notification.body)
      })
    })

    return () => {
      socket.close()
    }
  }, [])

  const handleChangeReloadTeste = () => {
    setReloadTeste(!reloadTeste)
  }

  const handleChangeUrlRoute = (url: string) => {
    setUrlRouter(url)
  }

  const handleChangeNewNotify = (bool: boolean) => {
    setNewNotificacao(bool)
  }

  const handleAlterName = (name: string) => {
    setNameHeader(name)
  }

  const handleModalCreate = (objetoAlterated: boolean) => {
    setModalEvent({ create: objetoAlterated })
  }
  const handleModalDelete = (objetoAlterated: boolean) => {
    setModalEvent({ delete: objetoAlterated })
  }
  const handleModalSucess = (objetoAlterated: boolean) => {
    setModalEvent({ sucess: objetoAlterated })
  }
  const handleModalAlterat = (objetoAlterated: boolean) => {
    setModalEvent({ alterated: objetoAlterated })
  }
  const handleModalError = (objetoAlterated: boolean) => {
    setModalEvent({ error: objetoAlterated })
  }

  const HandleRetornoWS = (e: any) => {
    setRetornoWS(e)
  }

  const ValidarLogin = (bool: boolean) => {
    setIsPrivet(bool)
  }

  const verificaDadosDoObjeto = (objeto: any) => {
    for (const chave in objeto) {
      if (objeto[chave] === undefined || objeto[chave] === '') {
        return false
      }
    }
    return true
  }

  const handleValidateElement = (Value: any, TypeOf: string | number) => {
    if (Value === null || Value === undefined) {
      return TypeOf
    } else {
      return Value
    }
  }

  const handleChangeReload = () => {
    setReload(true)
  }

  const fetchEventos = useCallback(async () => {
    const { data } = await GetEventos()
    setReload(false)

    setEventos(data)
  }, [reload === true, isPrivet])

  const fetchPessoas = useCallback(async () => {
    const { data } = await BuscarPessoa()
    setReload(false)

    setPessoas(data)
  }, [reload === true, isPrivet])

  const fetchNotiificacao = useCallback(
    async (id: string | number) => {
      const response = await responseNotfy(id)
      setReload(false)

      setNotificacao(response.data)
    },
    [reload === true],
  )

  const MaskFormatBR = (value: string | number) => {
    // O Value tem q ser no padrao 2022-11-29T00:00:00
    const data = new Date(value)
    const dia = data.getDate().toString().padStart(2, '0')
    const mes = (data.getMonth() + 1).toString().padStart(2, '0')
    const ano = data.getFullYear().toString()
    const dataFormatada = `${dia}/${mes}/${ano}`
    return dataFormatada
  }

  useEffect(() => {
    fetchEventos()
    fetchPessoas()
  }, [fetchEventos, fetchPessoas])

  const handleValidatedForm = (event: FocusEvent<HTMLInputElement>) => {
    const target = event.target

    const isActive = target.value.trim() !== ''
    target.classList.toggle('active', isActive)
    target.classList.toggle('inactive', !isActive)
  }

  return (
    <ContextAPI.Provider
      value={{
        // Stados para modal
        modalEvent,

        eventos,
        pessoas,
        notificacao,
        fetchEventos,
        fetchPessoas,
        fetchNotiificacao,

        // function para abrir/fechar modal
        handleModalCreate,
        handleModalDelete,
        handleModalSucess,
        handleModalAlterat,
        handleModalError,

        // Validar se valores sao nullos ou undefined
        handleValidateElement,
        verificaDadosDoObjeto,

        // alterar nome
        handleAlterName,
        nameHeader,

        urlRouter,
        handleChangeUrlRoute,

        handleChangeReload,

        reloadTeste,
        handleChangeReloadTeste,

        newNotificacao,
        handleChangeNewNotify,

        MaskFormatBR,

        HandleRetornoWS,
        RetornoWS,

        ValidarLogin,
        isPrivet,

        handleValidatedForm,

        RoleAccess,
        UpadateRoleAccess
      }}
    >
      {children}
    </ContextAPI.Provider>
  )
}

export const useVerify = () => {
  const { handleValidateElement } = useContext(ContextAPI)
  return { handleValidateElement }
}

export const useAccess = () => {
  const { RoleAccess, UpadateRoleAccess} = useContext(ContextAPI)
  return { RoleAccess, UpadateRoleAccess }
}

/* export function useContextApi() {
  const context = useContext(ContextAPI)

  return context
} */
