import { ChangeEvent, useContext, useReducer, useState } from 'react'
import { COntainerLogin } from './styled'
import { Button, Form, InputGroup } from 'react-bootstrap'

import iconTJ from '../../assets/images/brasao_cor.svg'
import { SignIn } from '../../service'
import { ContextAPI, useAccess } from '../../hooks/useContextApi'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { notifyError } from '../../components/Toast/intex'

interface IPropLogin {
  username: string
  password: string
}

const Login = () => {
  const [login, setLogin] = useReducer(
    (prev: IPropLogin, next: Partial<IPropLogin>) => {
      return { ...prev, ...next }
    },
    {
      username: '',
      password: '',
    },
  )
  const [loginValidated, setLoginValidated] = useState(true)
  const [showPassword, setShowPassword] = useState(false)

  const { ValidarLogin } = useContext(ContextAPI)
  const {UpadateRoleAccess} = useAccess()
  async function HandleSubmit(event: ChangeEvent<HTMLFormElement>) {
    event.preventDefault()
    const objLogin = {
      username: login.username,
      password: login.password,
    }

    try {
      const { data, status } = await SignIn(objLogin)

      if (data.roles[0] !== "ROLE_CADASTRO_PENDENTE" && status === 200) {
        ValidarLogin(true)
        sessionStorage.setItem('isPrivet', '1')
        sessionStorage.setItem('jwtToken', data.jwtToken)
        sessionStorage.setItem('RoleAccess', data.roles[0])
        UpadateRoleAccess()
      }else{
        notifyError("Não autorizado","Aguarde um administrador autorizar seu usuario")
      }

      // setTimeout(() => {
      //   // navigate('/')
      //   location.reload()
      // }, 500)
    } catch (err) {
      setLoginValidated(false)
    }
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const idTarget = event.target.id
    for (const i in Object.keys(login)) {
      const elementKey = Object.keys(login)
      if (elementKey[i] === idTarget) {
        setLogin({
          [elementKey[i]]: event.target.value,
        })
      }
    }
  }

  return (
    <COntainerLogin>
      <Form onSubmit={HandleSubmit}>
        <h1>
          <h3>Eventos</h3>
        </h1>

        <span>
          <img src={iconTJ} alt="" />
        </span>

        <h3>
          Tribunal de Justiça
          <br /> do Estado de Goiás
        </h3>

        {!loginValidated && (
          <span className="ErrorContainer">
            <strong>
              usuario ou senha não encontrados no sistema.
              <br /> Tente novamente.
            </strong>
          </span>
        )}

        <Form.Control
          id="username"
          onChange={handleChange}
          onBlur={() => {
            setLoginValidated(true)
          }}
          placeholder="Usuario"
        />
        <InputGroup>
          <Form.Control
            id="password"
            onChange={handleChange}
            onBlur={() => {
              setLoginValidated(true)
            }}
            type={showPassword ? 'text' : 'password'}
            placeholder="Senha"
          />
          <InputGroup.Text
            onClick={() => setShowPassword(!showPassword)}
            id="basic-addon1"
          >
            {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
          </InputGroup.Text>
        </InputGroup>
        <div className="ButtonLogin">
          <Link to={'/cadastro-usuarios'}>
            <Button variant="secondary">Registre-se</Button>
          </Link>
          <Button type="submit">Acessar</Button>
        </div>
      </Form>
    </COntainerLogin>
  )
}

export default Login
