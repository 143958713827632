import React from 'react'
import {
  VStack,
  HStack,
  Divider,
  Box,
  Flex,
  Spacer,
  Button,
  Icon,
} from '@chakra-ui/react'
import InfoField from './InfoField'
import DataField from './DataField'
import { FaArrowRight } from 'react-icons/fa'
import { telefoneMask } from '../../../utils/mask'
import Site from './Site'
interface Props {
  dataInicial: string
  dataFinal: string
  nome: string
  descricao: string
  complemento: string | undefined
  logradouro: string | undefined
  site: string
  telefones: Telefone[]
  email: string
  horaInicial: string
  horaFinal: string
}

const DadosEventoDesktop = ({
  complemento,
  dataFinal,
  dataInicial,
  descricao,
  email,
  logradouro,
  nome,
  site,
  telefones,
  horaFinal,
  horaInicial,
}: Props) => {
  return (
    <VStack align="start">
      <HStack w="100%" justifyContent="space-evenly">
        {dataInicial === dataFinal && (
          <DataField title="Dia" data={dataInicial} hora={horaInicial} />
        )}
        {dataInicial !== dataFinal && (
          <>
            <DataField title="De" data={dataInicial} hora={horaInicial} />
            <DataField title="Até" data={dataFinal} hora={horaFinal} />
          </>
        )}
      </HStack>
      <Divider />
      <HStack w="100%" align="start">
        <Box flex={1}>
          <InfoField title="Nome do evento" data={nome} />
        </Box>
        <Box flex={1}>
          <InfoField title="Descrição" data={descricao} />
        </Box>
      </HStack>
      <Divider />
      <HStack w="100%" align="start">
        <Box flex={1}>
          <InfoField title="Local" data={complemento} />
        </Box>
        <Box flex={1}>
          <InfoField title="Endereço" data={logradouro} />
        </Box>
      </HStack>
      <Divider />
      <Box h="20px"> </Box>
      <HStack w="100%" justifyContent="start">
        {telefones &&
          telefones.map((telefone, index) => (
            <Box key={index}>
              <InfoField
                title={`Telefone ${index + 1}`}
                data={telefoneMask(telefone.ddd + telefone.numero)}
                showCopyButton={true}
              />
            </Box>
          ))}
      </HStack>
      <Box h="20px"> </Box>
      <HStack w="100%" justifyContent="center">
        {email && (
          <InfoField title="Email" data={email} showCopyButton={true} />
        )}
        {site && <Site site={site}></Site>}
      </HStack>
    </VStack>
  )
}

export default DadosEventoDesktop
