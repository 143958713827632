import React from 'react'
import { Tooltip } from 'react-bootstrap'

export const renderTooltipEdit = (prop: any) => (
  <Tooltip id="button-tooltip" {...prop}>
    Editar
  </Tooltip>
)

export const renderTooltipReservar = (prop: any) => (
  <Tooltip id="button-tooltip" {...prop}>
    Reservar
  </Tooltip>
)

export const renderTooltipReservado = (prop: any) => (
  <Tooltip id="button-tooltip" {...prop}>
    Reservado
  </Tooltip>
)

export const renderTooltipAdd = (prop: any) => (
  <Tooltip id="button-tooltip" {...prop}>
    Cadastrar
  </Tooltip>
)

export const renderTooltipDelete = (prop: any) => (
  <Tooltip id="button-tooltip" {...prop}>
    Excluir
  </Tooltip>
)
