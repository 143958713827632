import React, { useEffect, useState } from 'react'
import { ContainerTable } from './styled'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { TableIconImage } from '../frequenciaDoEvento'
import { Link } from 'react-router-dom'
import { Button, OverlayTrigger } from 'react-bootstrap'
import { renderTooltipAdd, renderTooltipEdit } from '../../utils/Tooltip'
import { GiHamburgerMenu } from 'react-icons/gi'
import { FaEdit } from 'react-icons/fa'
import { BsPeopleFill } from 'react-icons/bs'

// import { Container } from './styles';

function TesteTable() {
  const [WidthTable, setWidthTable] = useState<number>(0)
  const [rows, setRows] = React.useState([
    { id: '1', order: 0, content: 'Linha 1' },
    { id: '2', order: 1, content: 'Linha 2' },
    { id: '3', order: 2, content: 'Linha 3' },
    // ...
  ])

  useEffect(() => {
    const Table = document.getElementById('TableContainer') as HTMLElement

    setWidthTable(Table.offsetWidth - 1)
  }, [rows])

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return
    }

    const { source, destination } = result

    // Reordena as linhas com base na posição final
    const reorderedRows = Array.from(rows)
    const [removed] = reorderedRows.splice(source.index, 1)
    reorderedRows.splice(destination.index, 0, removed)

    // Atualiza o estado com as linhas reordenadas
    setRows(reorderedRows)
  }
  const handleDragStart = (e: any, id: any) => {
    // Adicione o código necessário para iniciar o arrastar e soltar da linha
    // Neste exemplo, vamos apenas imprimir o ID da linha no console
  }

  const handleDragEnd = () => {
    // Adicione o código necessário para finalizar o arrastar e soltar da linha
    // Neste exemplo, não precisamos fazer nada
  }

  return (
    <ContainerTable WidthContainer={WidthTable}>
      <DragDropContext onDragEnd={onDragEnd}>
        <table id="TableContainer">
          <thead>
            <tr style={{ width: '100%' }}>
              {THead.map((e: string) => (
                <th key={e}>{e}</th>
              ))}
            </tr>
          </thead>
          <Droppable droppableId="rows">
            {(provided) => (
              <tbody
                style={{ width: '100%' }}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {rows.map((row, index) => (
                  <Draggable key={row.id} draggableId={row.id} index={index}>
                    {(provided) => (
                      <tr
                        style={{ width: '100%' }}
                        {...provided.draggableProps}
                        // {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        className="DragDropCOntainer"
                      >
                        <td>
                          <select>
                            <option value=""></option>
                            <option value="Presente">Presente</option>
                            <option value="Ausente">Ausente</option>
                            {/* {button.trim() === 'Retomar a Chamada' && (
                              <option value="Nominado">Nominado</option>
                            )} */}
                          </select>
                        </td>
                        <td>nome</td>
                        <td>titulo</td>
                        <td>precedencia</td>
                        <td>
                          {' '}
                          <div>
                            <TableIconImage />
                          </div>
                        </td>
                        <td>
                          {' '}
                          <div>
                            <Link
                              // to={`/representante/evento/${eventoId}/pessoa/${row.pessoaId}`}
                              to={'#'}
                              tabIndex={-1}
                            >
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltipAdd}
                              >
                                <Button variant="link">
                                  <BsPeopleFill style={{ color: '#004a80' }} />
                                </Button>
                              </OverlayTrigger>
                            </Link>
                            <Link
                              // to={`/pessoa/${row.pessoaId}`}
                              to={'#'}
                              tabIndex={-1}
                            >
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltipEdit}
                              >
                                <Button variant="link">
                                  <FaEdit style={{ color: '#004a80' }} />
                                </Button>
                              </OverlayTrigger>
                            </Link>

                            <Button
                              variant="link"
                              {...provided.dragHandleProps}
                              onMouseDown={(e) => handleDragStart(e, row.id)}
                            >
                              <GiHamburgerMenu style={{ color: '#004a80' }} />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </table>
      </DragDropContext>
    </ContainerTable>
  )
}

const THead = ['Status', 'Nome', 'Título', 'Precedência', 'Mesa', 'Ações']

export default TesteTable
