import React, {
  ChangeEvent,
  FocusEvent,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { Button, Card, FormControl, InputGroup, Modal } from 'react-bootstrap'

import meuGif from '../../assets/images/7efs.gif'

import { BsFillPersonFill } from 'react-icons/bs'
import { AiFillEye, AiFillEyeInvisible, AiOutlinePhone } from 'react-icons/ai'
import { FaTrash } from 'react-icons/fa'
import {
  Form,
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'

import {
  GetFotoPessoa,
  GetOneUser,
  PatchOneUser,
  RegisterUser,
} from '../../service'
import {
  ButtonContainer,
  ContainerCadaster,
  ContainerGif,
  StyleContainer,
} from './styled'
import { cpfMask, telefoneMask } from '../../utils/mask'
import { DetalheDoEvento } from '../../components/Modal'
import { ContextAPI, useAccess } from '../../hooks/useContextApi'
import { cadastrousuario } from '../../components/Form/FormFields'
import { notifyError, notifySuccess } from '../../components/Toast/intex'
import { UserPessoa } from './types'

interface IReducerForm {
  apelido: string
  cpf: string
  // idCorporativo: number | string
  nome: string
  sobrenome: string
  cargo: string
  pronomeTratamento: string
  precedencia: string
  titulo: string
  matricula: string
  pessoaId: number | string
  foto: string
  email: string
  status?: string
  telefones: string
  id: number
  username: string
  password: string
  verifyPassword: string
}
  
interface IPropValidated {
  PassLowerCase: boolean
  PassUppercase: boolean
  UniqueNumber: boolean
  EspecialCaracter: boolean
  MinLength: boolean
  ConfirmPass: boolean
}

export default function Usuario() {
  const { eventoId, id } = useParams()
  const [newUser, setNewUser] = useState(false)
  const [returnData, setReturnData] = useState<UserPessoa>()

  const navigate = useNavigate()

  const [image, setImage] = useState<FormData>()
  const [idPessoa, setIdPessoa] = useState(0)

  const [openModal, setOpenModal] = useState(false)
  const [errorCadaster, setErrorCadaster] = useState(false)
  const [campoVazio, setCampoVazio] = useState(false)
  const [registerPessoa, setRegisterPessoa] = useState(false)

  const [salvando, setSalvando] = useState(false)

  const [ShowPass, setShowPass] = useState(false)
  const [ShowVerifyPass, setShowVerifyPass] = useState(false)

  const [fotoTitle, setFotoTitle] = useState<string>('')

  const [imagemSelecionada, setImagemSelecionada] = useState<any>(null)
  const event = localStorage.getItem('EventRegister')
  const [isCpfValid, setCpfIsValid] = useState(true)
  const {RoleAccess} = useAccess()
  const handlePass = () => {
    setShowPass(!ShowPass)
  }
  const handlePassVerify = () => {
    setShowVerifyPass(!ShowVerifyPass)
  }
  const [formInput, setFormInput] = useReducer(
    (prev: IReducerForm, next: Partial<IReducerForm>) => {
      return { ...prev, ...next }
    },
    {
      apelido: '',
      cargo: '',
      cpf: '',
      email: '',
      foto: '',
      id: 0,
      matricula: '',
      nome: '',
      precedencia: '',
      pronomeTratamento: '',
      sobrenome: '',
      telefones: '',
      titulo: '',

      pessoaId: '',

      username: '',
      password: '',
      verifyPassword: '',
    },
  )
  const [tipoCadastro, setTipoCadastro] = useState('')

  const [validatePass, UpdateValidatePass] = useReducer(
    (prev: IPropValidated, next: Partial<IPropValidated>) => {
      return { ...prev, ...next }
    },
    {
      PassLowerCase: false,
      PassUppercase: false,
      UniqueNumber: false,
      EspecialCaracter: false,
      MinLength: false,
      ConfirmPass: false,
    },
  )

  const [isEmailValid, setEmailIsValid] = useState(true)

  function isValidEmail(email: string): boolean {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    return emailRegex.test(email)
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = event.target.value

    if (newEmail === '') {
      setEmailIsValid(true)
    } else {
      setEmailIsValid(isValidEmail(newEmail))
    }
  }

  const {
    modalEvent,

    handleModalDelete,
    handleModalCreate,
    handleModalAlterat,

    verificaDadosDoObjeto,
    handleAlterName,
    handleChangeUrlRoute,

    isPrivet,

    handleValidatedForm,
  } = useContext(ContextAPI)

  const { pathname } = useLocation()

  function verificaUrlValidaRepresentante(path: string): boolean {
    const padrao = /\/newrepresentante\/evento\/(\d+)\/pessoa\/(\d+)/
    const resultado = path.match(padrao)

    if (resultado) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    handleAlterName(`${newUser ? 'Cadastro' : 'Edição'} de usuário`)
    handleChangeUrlRoute(newUser ? '/login' : '/lista-usuarios')

    const isRepresentante = verificaUrlValidaRepresentante(pathname)
    if (pathname !== '/cadastro-usuarios') {
      setNewUser(false)
      Response()
    } else {
      setNewUser(true)
    }

    async function Response() {
      try {
        const url = pathname.replace('/usuario/', '')
        const res = await GetOneUser(url)
        const objList: UserPessoa = res.data

        if (objList.foto !== '') {
          try {
            // const fotoPessoa = await GetFotoPessoa(objList.foto)

            await GetFotoPessoa(objList.foto).then((e) => {
              const base64 = btoa(
                new Uint8Array(e.data).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  '',
                ),
              )
              // const teste =
              setImagemSelecionada(
                `data:${e.headers[
                  'content-type'
                ].toLowerCase()};base64,${base64}`,
              )
            })
          } catch (err) {}
        }

        if (objList.foto !== '') {
          setFotoTitle(objList.foto)
        }

        setIdPessoa(objList.pessoaId)
        const obj: any = {}
        for (const [key, value] of Object.entries(objList)) {
          const localeValue = Object.keys(formInput).filter((e) => e === key)
          switch (key) {
            case 'telefones':
              setFormInput({
                telefones:
                  Array.isArray(value) && value !== null && value?.length !== 0
                    ? telefoneMask(`${value[0].ddd}${value[0].numero}`)
                    : '',
              })
              obj.telefones =
                Array.isArray(value) && value !== null && value?.length !== 0
                  ? telefoneMask(`${value[0].ddd}${value[0].numero}`)
                  : ''
              break
            case 'cpf':
              setFormInput({
                cpf: value !== null ? cpfMask(String(value)) : '',
              })
              obj.cpf = value !== null ? cpfMask(String(value)) : ''
              break
            case 'user':
              setFormInput({
                username:
                  typeof value === 'object' &&
                  value !== null &&
                  'username' in value
                    ? value.username
                    : '',
              })
              obj.user = {
                username:
                  typeof value === 'object' &&
                  value !== null &&
                  'username' in value
                    ? value.username
                    : '',
              }
              break
            default:
              setFormInput({
                [key]: value !== null ? value : '',
              })
              obj[key] = value !== null ? value : ''
          }
        }
        setReturnData(obj)
        const Select = ((
          document.getElementById('SelectTipoCadastro') as HTMLSelectElement
        ).value = String(objList.user.tipoUserId))

        const pass = ((
          document.getElementById('password') as HTMLInputElement
        ).value = '')
        const verifyPass = ((
          document.getElementById('verifyPassword') as HTMLInputElement
        ).value = '')
      } catch (err) {}
    }
  }, [eventoId, id, pathname])

  function handleSelecionarImagem(event: any) {
    const arquivo = event.target.files[0]
    const leitor = new FileReader()

    leitor.onload = function () {
      setImagemSelecionada(leitor.result)
    }

    const Teste = new FormData()
    Teste.append('file', event.target.files[0])

    setFormInput({
      foto: arquivo.name,
    })

    setImage(Teste)
    leitor.readAsDataURL(arquivo)
  }

  function compareObjects(objReturn: any, obj2: any): UserPessoa {
    const differences: any = {}

    const obj1 = {...objReturn, ...objReturn.user}

    for (const key in obj1) {
      if (
        Object.prototype.hasOwnProperty.call(obj1, key) &&
        Object.prototype.hasOwnProperty.call(obj2, key)
      ) {
        if (obj1[key] !== obj2[key]) {
          differences[key] = obj2[key]
        }
      }
    }

    const obj: any = {}

    for (const [key, value] of Object.entries(differences)) {
      switch (key) {
        case 'telefones':
          obj.telefones = [
            {
              ddd: formInput.telefones.slice(1, 3),
              numero: formInput.telefones.slice(5, 15),
              tipo: 'phone',
            },
          ]
          break
        case 'username' || 'password':
          obj.user = {
            password: formInput.password,
            tipoUserId: 1,
            username: formInput.username,
          }
          break
        default:
          obj[key] = value
      }
    }

    return obj
  }

  async function Alterar() {
    const {password, verifyPassword, username} = formInput
    let objRequired = cadastrousuario
      .filter((e) => e.require === true)

    if(password === ''){
      const removePass = ['password', 'verifyPassword']
      objRequired = objRequired.filter(e => !removePass.map(event => event).includes(e.name) )
    }


    // return

    const keyEmpty: string[] = []
    for (const [key, value] of Object.entries(formInput)) {
      const emptyKey = objRequired.find((e) => e.name === key)
      if (emptyKey) {
        switch (emptyKey.name) {
          case '':
        }
        if (value === '') {
          notifyError(
            emptyKey.label,
            `O campo ${emptyKey.label} é obrigatorio.`,
          )
          const Verify = document.getElementById(key)?.classList.add('inactive')
          keyEmpty.push(emptyKey.name)
        } 
      }
    }
    if (keyEmpty.length !== 0) {
      return
    }

    let objPatch: any = compareObjects(
      returnData === undefined ? {} : returnData,
      formInput,
    )

    if(password !== ''){
      objPatch = {...objPatch, user:{password:password, username:username}}
    }
  
    if (tipoCadastro !== '') {

      // if(!objPatch.user){
      //   notifyError('Erro ao atualizar', 'Para mudar tipo de cadastro, precisa informa senha.')
      //   return
      // }


      objPatch.user = {
        ...objPatch.user,
        tipoUserId: Number(tipoCadastro),
      }
    }

    try {
      const res = await PatchOneUser(idPessoa, objPatch)
      // if()

      if (res.status === 200) {
        notifySuccess(
          'Usuario alterado',
          `Usuario ${formInput.username} alterado com sucesso.`,
        )

        navigate('/lista-usuarios')
      }
    } catch (err) {
      notifyError('Erro ao atualizar', 'Ocorreu um erro ao atualizaros dados.')
    }
  }

  async function handleSendData() {
    try {
      const objRequired = cadastrousuario.filter((e) => e.require === true)
      const keyEmpty: string[] = []
      for (const [key, value] of Object.entries(formInput)) {
        const emptyKey = objRequired.find((e) => e.name === key)
        if (emptyKey) {
          if (value === '') {
            notifyError(
              emptyKey.label,
              `O campo ${emptyKey.label} é obrigatorio.`,
            )
            const Verify = document
              .getElementById(key)
              ?.classList.add('inactive')
            keyEmpty.push(emptyKey.name)
          }
        }
      }
      if (!isCpfValid) {
        notifyError('CPF', `CPF precisar conter 11 dígitos`)
        return
      }

      if (!isEmailValid) {
        notifyError('Email', `O email inserido é inválido`)
        return
      }
      if (
        !validatePass.PassLowerCase ||
        !validatePass.PassUppercase ||
        !validatePass.UniqueNumber ||
        !validatePass.EspecialCaracter ||
        !validatePass.MinLength
      ) {
        notifyError('Senha', `Senha não segue as regras citadas.`)
        return
      }

      if (
        !(
          formInput.verifyPassword === formInput.password &&
          formInput.verifyPassword !== ''
        )
      ) {
        notifyError('Senha', `Senhas não conferem`)
        return
      }
      if (keyEmpty.length !== 0) {
        return
      }

      setRegisterPessoa(true)
    } catch (err) {
      notifyError(
        'Erro',
        `Ocorreu um erro ao cadastrar. Tente novamente mais tarde.`,
      )
    }
  }

  async function handleCadaster() {
    setRegisterPessoa(false)
    setSalvando(true)

    const obj = {
      apelido: formInput.apelido,
      cadastroValidado: true,
      cargo: formInput.cargo,
      cpf: formInput.cpf,
      email: formInput.email,
      foto: formInput.foto,
      id: formInput.id,
      matricula: formInput.matricula,
      nome: formInput.nome,
      precedencia: formInput.precedencia,
      pronomeTratamento: formInput.pronomeTratamento,
      sobrenome: formInput.sobrenome,
      telefones: [
        {
          ddd: formInput.telefones.slice(1, 3),
          numero: formInput.telefones.slice(5, 15),
          tipo: 'phone',
        },
      ],
      titulo: formInput.titulo,
      user: {
        password: formInput.password,
        tipoUserId: 3,
        username: formInput.username,
      },
    }

    try {
      const res = await RegisterUser(obj).finally(() => setSalvando(false))
      switch (res.status) {
        case 201:
          notifySuccess(obj.nome, `Usuário cadastrado com sucesso.`)
          break
        case 200:
          notifySuccess(obj.nome, `Usuário cadastrado com sucesso.`)
          break
      }

      handleModalCreate(true)
    } catch (err) {
      notifyError('Usuário cadastrado', `Verifique o CPF e o nome do usuário.`)
    }
  }

  const CleanStateFormInput = () => {
    setImagemSelecionada(null)
    setFormInput({
      apelido: '',
      cpf: '',
      // idCorporativo: '1',
      nome: '',
      pronomeTratamento: '',
      precedencia: '',
      cargo: '',
    })

    // selectEventTable.map((e) => handleDeleteEvent(e.eventoId));
  }

  const validadeCpf = (input: string) => {
    if (input.length > 1 && input.length < 14) {
      setCpfIsValid(false)
    } else {
      setCpfIsValid(true)
    }
  }

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value
    for (const i in Object.keys(formInput)) {
      const key = Object.keys(formInput)[i]

      if (key === event.target.id) {
        setFormInput({
          [key]: input,
        })

        switch (key) {
          case 'matricula':
            setFormInput({
              matricula: (event.target.value.replace(/\D+/g, '')),
            })
            break
          case 'cpf':
            validadeCpf(input)
            setFormInput({
              cpf: cpfMask(event.target.value.replace(/\D+/g, '')),
            })
            break
          case 'telefones':
            setFormInput({
              telefones: telefoneMask(event.target.value),
            })
            break
          case 'password':
            setFormInput({
              password: validatePassword(event.target.value),
            })
            break
          case 'email':
            handleEmailChange(event)
            setFormInput({
              email: event.target.value,
            })
        }
      }
    }
  }

  const validatePassword = (pass: string) => {
    const lowercaseRegex = /[a-z]/
    const uppercaseRegex = /[A-Z]/
    const numberRegex = /[0-9]/
    const specialCharRegex = '!@#$%^&*()_+{}[]:;<>,.?~-'

    const hasLowercase = lowercaseRegex.test(pass)
    const hasUppercase = uppercaseRegex.test(pass)
    const hasNumber = numberRegex.test(pass)
    const hasSpecialChar = Array.from(specialCharRegex).some((e) =>
      pass.includes(e),
    )
    const isLengthValid = pass.length >= 6
    UpdateValidatePass({
      PassLowerCase: hasLowercase,
      PassUppercase: hasUppercase,
      UniqueNumber: hasNumber,
      EspecialCaracter: hasSpecialChar,
      MinLength: isLengthValid,
      ConfirmPass: formInput.password === formInput.verifyPassword,
    })

    return pass
  }

  return (
    <StyleContainer>
      <Modal show={openModal} onHide={() => setOpenModal(false)}>
        <Modal.Header style={{ background: '#D1E7DD' }} closeButton>
          <Modal.Title style={{ color: '#2A664A' }}>
            Contato deletado com sucesso
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ display: 'flex', alignItems: 'center', gap: '15px' }}
        >
          <ContainerGif>
            <img src={meuGif} alt="" />
          </ContainerGif>
          {/* <Spinner animation="border" variant="primary" /> Carregando... */}
        </Modal.Body>
      </Modal>

      <Modal show={registerPessoa} onHide={() => setRegisterPessoa(false)}>
        <Modal.Header style={{ background: '#D1E7DD' }} closeButton>
          <Modal.Title style={{ color: '#2A664A' }}>Cadastro</Modal.Title>
        </Modal.Header>
        <Modal.Body>Gostaria de cadastrar essa pessoa?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setRegisterPessoa(false)}>
            Não
          </Button>
          <Button variant="primary" onClick={handleCadaster}>
            Cadastrar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={errorCadaster} onHide={() => setErrorCadaster(false)}>
        <Modal.Header style={{ background: '#F8D7DA' }} closeButton>
          <Modal.Title style={{ color: 'rgb(116, 31, 39)' }}>
            Não podemos prosseguir
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Ocorreu um erro durante o cadastro. Tente novamente.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setErrorCadaster(false)}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={campoVazio} onHide={() => setCampoVazio(false)}>
        <Modal.Header style={{ background: '#F8D7DA' }} closeButton>
          <Modal.Title style={{ color: 'rgb(116, 31, 39)' }}>
            Não podemos prosseguir
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Preencha todos os campos.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setCampoVazio(false)}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={modalEvent.create} onHide={() => handleModalCreate(false)}>
        <Modal.Header style={{ background: '#D1E7DD' }} closeButton>
          <Modal.Title style={{ color: '#2A664A' }}>Cadastro</Modal.Title>
        </Modal.Header>
        <Modal.Body>Pessoa cadastrada com sucesso.</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              navigate(isPrivet ? '/lista-usuarios' : `/`)
              handleModalCreate(false)
            }}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <DetalheDoEvento
        sucessOrError={false}
        showModal={modalEvent.delete}
        handleClose={() => handleModalDelete(false)}
        mensageHeader="Error"
        mensageBody="CEP não localizado."
        nextFunction={true}
      />

      <DetalheDoEvento
        sucessOrError={true}
        showModal={modalEvent.alterated}
        handleClose={() => handleModalAlterat(false)}
        mensageHeader="Alterado"
        mensageBody="Pessoa alterada com sucesso."
        nextFunction={false}
        urlPath={'/'}
        // handleNextFunction={() => handleDeleteContato()}
      />

      <ContainerCadaster autoComplete="off">
        <div className="LeftContainer"></div>
        <div className="RightContainer">
          <div className="Visualizar">
            <span
              style={
                imagemSelecionada
                  ? {
                      border: '5px solid #333',
                      height: '100%',
                    }
                  : { border: 0 }
              }
            >
              {!imagemSelecionada ? (
                <>
                  <BsFillPersonFill />
                  <strong>Adicionar foto</strong>
                  <strong>(opcional)</strong>
                  <input
                    type="file"
                    accept="image/jpeg"
                    onChange={handleSelecionarImagem}
                  />
                </>
              ) : (
                <>
                  <img src={imagemSelecionada} alt="Select image" />
                  <input
                    type="file"
                    accept="image/jpeg"
                    onChange={handleSelecionarImagem}
                  />
                  {/* <button>X</button> */}
                </>
              )}
            </span>
          </div>
          {cadastrousuario.map((e: IUserProps, i: number) => (
            <>
              {e.name === 'password' || e.name === 'verifyPassword' ? (
                <InputGroup style={{ margin: 0 }} key={i}>
                  {e.name === 'password' ? (
                    <FormControl
                      id={e.name}
                      placeholder={e.label}
                      type={ShowPass ? 'text' : 'password'}
                      value={formInput[e.name as keyof IReducerForm]}
                      // autoComplete="off"
                      onChange={handleChangeInput}
                      onBlur={(event: FocusEvent<HTMLInputElement>) =>{
                        const {password, verifyPassword} = formInput
                        const whrite = password === verifyPassword
                        if(newUser){
                          e.require && handleValidatedForm(event)
                        }else{
                          if(password.length){
                            e.require && handleValidatedForm(event)
                          }
                        }
                        newUser
                      }}
                    />
                  ) : (
                    <FormControl
                      id={e.name}
                      placeholder={e.label}
                      type={ShowVerifyPass ? 'text' : 'password'}
                      // autoComplete="off"
                      value={formInput[e.name as keyof IReducerForm]}
                      onChange={handleChangeInput}
                      onBlur={(event: FocusEvent<HTMLInputElement>) =>
                        e.require && handleValidatedForm(event)
                      }
                    />
                  )}
                  {e.name === 'password' ? (
                    <InputGroup.Text onClick={handlePass} id="basic-addon1">
                      {ShowPass ? <AiFillEye /> : <AiFillEyeInvisible />}
                    </InputGroup.Text>
                  ) : (
                    <InputGroup.Text
                      onClick={handlePassVerify}
                      id="basic-addon1"
                    >
                      {ShowVerifyPass ? <AiFillEye /> : <AiFillEyeInvisible />}
                    </InputGroup.Text>
                  )}
                </InputGroup>
              ) : (
                <>
                  <FormControl
                    key={i}
                    id={e.name}
                    placeholder={e.label}
                    value={formInput[e.name as keyof IReducerForm]}
                    onChange={handleChangeInput}
                    onBlur={(event: FocusEvent<HTMLInputElement>) =>
                      e.require && handleValidatedForm(event)
                    }
                  />
                  {e.name === 'email' && !isEmailValid && (
                    <div style={{ width: '100%' }}>
                      <p style={{ color: 'red' }}>
                        Por favor, insira um email válido.
                      </p>
                    </div>
                  )}
                  {e.name === 'cpf' && !isCpfValid && (
                    <div style={{ width: '100%' }}>
                      <p style={{ color: 'red' }}>
                        CPF precisa conter 11 dígitos.
                      </p>
                    </div>
                  )}
                </>
              )}
            </>
          ))}
          {newUser ? (
            <ul>
              <li
                style={
                  validatePass.PassLowerCase
                    ? { color: '#28a745' }
                    : { color: '#dc3545' }
                }
              >
                {' '}
                Pelo menos uma letra minúscula;
              </li>
              <li
                style={
                  validatePass.PassUppercase
                    ? { color: '#28a745' }
                    : { color: '#dc3545' }
                }
              >
                {' '}
                Pelo menos uma letra maiúscula;
              </li>
              <li
                style={
                  validatePass.UniqueNumber
                    ? { color: '#28a745' }
                    : { color: '#dc3545' }
                }
              >
                {' '}
                Pelo menos um número;
              </li>
              <li
                style={
                  validatePass.EspecialCaracter
                    ? { color: '#28a745' }
                    : { color: '#dc3545' }
                }
              >
                {' '}
                Pelo menos um caractere especial;
              </li>
              <li
                style={
                  validatePass.MinLength
                    ? { color: '#28a745' }
                    : { color: '#dc3545' }
                }
              >
                {' '}
                Conter no mínimo 6 caracteres;
              </li>
              <li
                style={
                  formInput.verifyPassword === formInput.password &&
                  formInput.verifyPassword !== ''
                    ? { color: '#28a745' }
                    : { color: '#dc3545' }
                }
              >
                Campo Confirmar senha deve ser semelhante ao campo senha.{' '}
              </li>
            </ul>
          ) : (
            <select
              style={{border:'1px solid #dee2e6'}}
              id="SelectTipoCadastro"
              onChange={(e) => setTipoCadastro(e.target.value)}
              disabled={RoleAccess !== 'ROLE_ADMIN'}
            >
              <option value="3">Cadastro Pendente</option>
              <option value="1">Administrador</option>
              <option value="2">Gestor</option>
            </select>
          )}
          <hr />
        </div>
      </ContainerCadaster>

      <span className="ContainerButton">
        {newUser ? (
          <ButtonContainer
            disabled={salvando}
            onClick={handleSendData}
            variant="primary"
          >
            Salvar
          </ButtonContainer>
        ) : (
          <ButtonContainer onClick={Alterar} variant="primary">
            Alterar
          </ButtonContainer>
        )}
        <Button onClick={CleanStateFormInput} variant="primary">
          Limpar
        </Button>
        <Link
          onClick={() => localStorage.removeItem('EventRegister')}
          to={
            isPrivet
              ? '/lista-usuarios'
              : newUser
              ? '/login'
              : `/lista-usuarios`
          }
        >
          <Button variant="danger">Cancelar</Button>
        </Link>
      </span>
      {/* </Card.Body> */}
      {/* </Card> */}
    </StyleContainer>
  )
}
