import { toast } from 'react-toastify'
import { ContainerTost } from '../../pages/frequenciaDoEvento/styled'
import { BiInfoCircle } from 'react-icons/bi'

const NotifySuccessText = (eventName?: string, descricao?: string) => {
  return (
    <ContainerTost>
      <h6>
        <BiInfoCircle /> {eventName}
      </h6>
      <p style={{ margin: 0 }}>{descricao}</p>
    </ContainerTost>
  )
}

const NotifyErrorText = (eventName?: string, descricao?: string) => {
  return (
    <ContainerTost>
      <h6 style={{ color: '#ff2d55' }}>
        <BiInfoCircle /> {eventName}
      </h6>
      <p style={{ margin: 0 }}>{descricao}</p>
    </ContainerTost>
  )
}

export const notifySuccess = (eventName?: string, desc?: string) =>
  toast.success(() => NotifySuccessText(eventName, desc), {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
  })

export const notifyError = (eventName?: string, descricao?: string) =>
  toast.error(NotifyErrorText(eventName, descricao), {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
  })
