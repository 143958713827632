import React from 'react'
import { ContextAPI } from '../../hooks/useContextApi'
import { Card } from 'react-bootstrap'
import Usuario from '.'

// import { Container } from './styles';

export const Layout = () => {
  const { isPrivet } = React.useContext(ContextAPI)
  return (
    <>
      {!isPrivet ? (
        <Card style={{ width: '80%', margin: 'auto', marginTop: '10px' }}>
          <Card.Header>Cadastrar-se</Card.Header>
          <Card.Body>
            <Usuario />
          </Card.Body>
        </Card>
      ) : (
        <Usuario />
      )}
    </>
  )
}
