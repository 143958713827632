import React, {
  ChangeEvent,
  FocusEvent,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { Button, Card, FormControl, InputGroup, Modal } from 'react-bootstrap'

import meuGif from '../../assets/images/7efs.gif'

import { BsFillPersonFill } from 'react-icons/bs'
import { AiOutlinePhone } from 'react-icons/ai'
import { FaTrash } from 'react-icons/fa'
import {
  Form,
  Link,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'

import {
  BuscaCEP,
  BuscarEventoEPessoa,
  BuscarPessoaId,
  ContatoPessoaDelete,
  FotoPessoa,
  GetEventos,
  PostCreateRepresentante,
  Postpessoa,
  PutPessoa,
  RegisterMesa,
  GetFotoPessoa,
  CadastrarPessoaEvento,
  DeletePessoaEvento,
  DeleteTefone,
  DeleteEndereco,
} from '../../service'
import {
  ButtonContainer,
  ContainerCadaster,
  ContainerGif,
  InputFormControl,
  StyleContainer,
  TableContainer,
} from './styled'
import { cepMask, cpfMask, telefoneMask } from '../../utils/mask'
import { DetalheDoEvento } from '../../components/Modal'
import { ContextAPI, useVerify } from '../../hooks/useContextApi'
import useErrors from '../../hooks/useErrors'
import { TableIconImage } from '../frequenciaDoEvento'
import { ContainerTost } from '../frequenciaDoEvento/styled'
import { BiInfoCircle } from 'react-icons/bi'
import { toast } from 'react-toastify'
import { cadastrarConvidado } from '../../components/Form/FormFields'
import { notifyError } from '../../components/Toast/intex'
import { type } from 'os'
import { AxiosError } from 'axios'
import {
  Box,
  HStack,
  Input,
  Text,
  VStack,
  InputGroup as ChInputGroup,
  InputRightElement,
  Divider,
  Spacer,
} from '@chakra-ui/react'

interface IPropCadastroPessoa {
  nome: string
  apelido: string
  cpf: string
  pronomeTratamento: string
  email: string
  cargo: string
  precedencia: string
}

export function WindowCadaster() {
  const { eventoId, id } = useParams()

  const navigate = useNavigate()

  const [image, setImage] = useState<FormData>()
  const [idPessoa, setIdPessoa] = useState(0)

  const [openModal, setOpenModal] = useState(false)

  const [errorCadaster, setErrorCadaster] = useState(false)

  const [cadasterErrorMsg, setCadasterErrorMsg] = useState('')

  const [campoVazio, setCampoVazio] = useState(false)

  const [registerPessoa, setRegisterPessoa] = useState(false)
  const [newPessoa, setNewPessoa] = useState(false)

  const [fotoTitle, setFotoTitle] = useState<string>('')
  const [selectEventTable, setSelectEventTable] = useState<number[]>([])

  const [contactDelete, setContactDelete] = useState<number[]>([])
  const [EventCadaster, setEventCadaster] = useState<Evento[]>([])

  const [contactDeleteOBJ, setContactDeleteOBJ] = useState<any>({})

  const [imagemSelecionada, setImagemSelecionada] = useState<any>(null)
  const event = localStorage.getItem('EventRegister')
  const [enderecoHasNumero, setEnderecoHasNumero] = useState(true)
  const [enderecoHasCep, setEnderecoHasCep] = useState(true)
  const [enderecoHasLogradouro, setEnderecoHasLogradouro] = useState(true)
  const [enderecoHasEstado, setEnderecoHasEstado] = useState(true)
  const [enderecoHasCidade, setEnderecoHasCidade] = useState(true)
  const [isEmailValid, setEmailIsValid] = useState(true)
  const [isCpfValid, setCpfIsValid] = useState(true)
  const [enderecos, setEnderecos] = useState<NewEndereco[]>([])
  const [searchEventInput, setSearchEventInput] = useState('')
  const [searchEventSuggestion, setSearchEventSuggestion] = useState<Evento[]>(
    [],
  )

  function isValidEmail(email: string): boolean {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    return emailRegex.test(email)
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = event.target.value
    if (newEmail === '') {
      setEmailIsValid(true)
    } else {
      setEmailIsValid(isValidEmail(newEmail))
    }
  }

  const handleCpfChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const cpf = event.target.value

    if (cpf === '' || cpf.length >= 14) {
      setCpfIsValid(true)
    } else {
      setCpfIsValid(false)
    }
  }

  const [ArrayList, setArrayList] = useReducer(
    (prev: typeListing, next: Partial<typeListing>) => {
      return { ...prev, ...next }
    },
    {
      telefoneAdicionado: [],

      emaildAdiconado: [],

      arrayEventos: [],

      arrayEventSelect: [],

      // arrayEventListResponsaveis: [],
    },
  )

  const [formInput, setFormInput] = useReducer(
    (
      prev: IStateReducerNONOptional,
      next: Partial<IStateReducerNONOptional>,
    ) => {
      return { ...prev, ...next }
    },
    {
      nome: '',
      apelido: '',
      cpf: '',
      pronomeTratamento: '',
      email: '',
      idCorporativo: '',
      sobrenome: '',
      cargo: '',
      precedencia: '',
      titulo: '',
      pessoaId: '',
      foto: '',
      contatos: [],
      endereco: [],
      telefones: [],
    },
  )

  const [formInputOption, setFormInputOption] = useReducer(
    (prev: IStateReducerOptional, next: Partial<IStateReducerOptional>) => {
      return { ...prev, ...next }
    },
    {
      Cargo: '',
      Email: '',
      Telefone: '',
      Evento: [],
    },
  )

  const [endereco, setEndereco] = useReducer(
    (prev: Endereco, next: Partial<Endereco>) => {
      return { ...prev, ...next }
    },
    {
      cep: '',
      cidade: '',
      complemento: '',
      estado: '',
      id: 0,
      logradouro: '',
      numero: '',
    },
  )

  const {
    modalEvent,

    handleModalDelete,
    handleModalCreate,
    handleModalSucess,
    handleModalAlterat,

    verificaDadosDoObjeto,
    handleAlterName,
    handleChangeUrlRoute,

    handleValidatedForm,
  } = useContext(ContextAPI)

  const { pathname } = useLocation()

  function verificaUrlValidaRepresentante(path: string): boolean {
    const padrao = /\/newrepresentante\/evento\/(\d+)\/pessoa\/(\d+)/
    const resultado = path.match(padrao)

    if (resultado) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    handleAlterName('Pessoa')
    handleChangeUrlRoute(event === null ? '/' : `/frequenciasevento/${event}`)

    const isRepresentante = verificaUrlValidaRepresentante(pathname)
    if (pathname !== '/cadastrar' && !isRepresentante) {
      setNewPessoa(false)
      Response()
    } else {
      setNewPessoa(true)
    }
    responseEvento()

    async function responseEvento() {
      const resEventos = await GetEventos()
      const url = window.location.href
      const regex = /newrepresentante\/evento/
      if (regex.test(url)) {
        const matches = url.match(/\/(\d+)\/pessoa\/(\d+)/)
        if (matches && matches.length === 3) {
          const eventoID = parseInt(matches[1])
          const pessoaID = parseInt(matches[2])
          const events: Evento[] = resEventos.data
          const newEvent = events.find((e) => e.eventoId === eventoID)
          if (newEvent) {
            ArrayList.arrayEventSelect.push(newEvent)
          }
          handleChangeUrlRoute(
            `/representante/evento/${eventoID}/pessoa/${pessoaID}`,
          )
        }
      }
      setArrayList({ arrayEventos: resEventos.data })

      if (event !== null) {
        const Obj = resEventos.data.filter(
          (e: any) => `${e.eventoId}` === event,
        )[0]

        setArrayList({
          arrayEventSelect: [...ArrayList.arrayEventSelect, Obj],
        })
      }
    }

    async function Response() {
      try {
        const url = pathname.replace('/pessoa/', '')
        const res = await BuscarPessoaId(url)
        console.log(res.data)
        const objList: Pessoa = res.data

        const resEvent = await BuscarEventoEPessoa(objList.pessoaId)

        if (objList.foto !== '') {
          try {
            // const fotoPessoa = await GetFotoPessoa(objList.foto)

            await GetFotoPessoa(objList.foto).then((e) => {
              const base64 = btoa(
                new Uint8Array(e.data).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  '',
                ),
              )
              // const teste =
              setImagemSelecionada(
                `data:${e.headers[
                  'content-type'
                ].toLowerCase()};base64,${base64}`,
              )
            })
          } catch (err) {}
        }

        const eventoMesa = []
        // for (const i in resEvent.data) []

        setArrayList({
          arrayEventSelect: resEvent.data.map((e: any) => {
            const local = res.data.pessoaEventos.filter(
              (event: any) => event.eventoId === e.eventoId,
            )
            e.compoeMesa = local[0].compoeMesa
            return e
          }),
        })
        if (objList.foto !== '') {
          setFotoTitle(objList.foto)
        }

        setIdPessoa(objList.pessoaId)
        setFormInput({
          apelido: objList.apelido,
          cpf: objList.cpf,
          email: objList.email,
          idCorporativo: objList.idCorporativo,
          nome: objList.nome,
          sobrenome: objList.sobrenome,
          cargo: objList.cargo,
          pronomeTratamento: objList.pronomeTratamento,
          precedencia: objList.precedencia === null ? '' : objList.precedencia,
          pessoaId: objList.pessoaId,
          foto: objList.foto,
          contatos: res.data.telefones !== undefined ? res.data.telefones : [],
          endereco: res.data.enderecos !== undefined ? res.data.enderecos : [],
        })
        setFormInputOption({
          //  Cargo: objList.cargo,
          Email: objList.email,
        })
      } catch (err) {}
    }
  }, [eventoId, id, pathname])

  const addItemEndereco = () => {
    let hasMissingField = false
    if (endereco.cep === '') {
      setEnderecoHasCep(false)
      hasMissingField = true
      notifyError('CEP', `O campo CEP é obrigatorio.`)
    }
    if (endereco.numero === '') {
      setEnderecoHasNumero(false)
      hasMissingField = true
      notifyError('Número', `O campo número é obrigatorio.`)
    }

    if (endereco.cidade === '') {
      setEnderecoHasCidade(false)
      hasMissingField = true
      notifyError('Cidade', `O campo cidade é obrigatorio.`)
    }

    if (endereco.estado === '') {
      setEnderecoHasEstado(false)
      hasMissingField = true
      notifyError('Estado', `O campo estado é obrigatorio.`)
    }

    if (endereco.logradouro === '') {
      setEnderecoHasLogradouro(false)
      hasMissingField = true
      notifyError('Logradouro', `O campo logradouro é obrigatorio.`)
    }

    if (hasMissingField) {
      return
    }

    if (formInput.endereco) {
      endereco.id = formInput.endereco.length + 1
    } else {
      endereco.id = 0
    }

    const updatedEndereco = Array.isArray(formInput.endereco)

    if (updatedEndereco) {
      setFormInput({ endereco: [...formInput.endereco, endereco] })
    } else {
      setFormInput({ endereco: [endereco] })
    }

    const newEndereco: NewEndereco = {
      cep: endereco.cep,
      cidade: endereco.cidade,
      complemento: endereco.complemento,
      estado: endereco.estado,
      logradouro: endereco.logradouro,
      numero: endereco.numero,
    }
    setEnderecos([...enderecos, newEndereco])

    setEndereco({
      cep: '',
      cidade: '',
      complemento: '',
      estado: '',
      id: 0,
      logradouro: '',
      numero: '',
    })
  }

  const removeItemEndereco = (id: number) => {
    const t = formInput.endereco.filter((e) => e.id !== id)
    setFormInput({
      endereco: t,
    })

    DeleteEndereco(id)
      .then((res) => console.log(res.data))
      .catch((error) => console.warn(error))
  }

  const BuscaCEPFunc = async () => {
    const Response = await BuscaCEP(endereco.cep)
    if (Response.erro) {
      handleModalDelete(true)
      return
    }
    setEndereco({
      cidade: Response.localidade,
      logradouro: Response.logradouro,
      estado: Response.uf,
    })
  }

  function handleSelecionarImagem(event: any) {
    const arquivo = event.target.files[0]
    const leitor = new FileReader()

    leitor.onload = function () {
      setImagemSelecionada(leitor.result)
    }

    const Teste = new FormData()
    Teste.append('file', event.target.files[0])

    setFormInput({
      foto: arquivo.name,
    })

    setImage(Teste)
    leitor.readAsDataURL(arquivo)
  }

  async function Alterar() {
    const objRequired = cadastrarConvidado.filter((e) => e.require === true)
    const keyEmpty: string[] = []
    for (const [key, value] of Object.entries(formInput)) {
      const emptyKey = objRequired.find((e) => e.name === key)
      if (emptyKey) {
        if (value === '') {
          notifyError(
            emptyKey.label,
            `O campo ${emptyKey.label} é obrigatorio.`,
          )
          const Verify = document.getElementById(key)?.classList.add('inactive')
          keyEmpty.push(emptyKey.name)
        }
      }
    }

    if (keyEmpty.length !== 0) {
      return
    }

    formInput.email = formInputOption.Email
    formInput.telefones = formInput.contatos

    const payload = {
      apelido: formInput.apelido,
      cadastroValidado: true,
      cargo: formInput.cargo,
      cpf: formInput.cpf,
      email: formInput.email,
      enderecos: formInput.endereco,
      foto: formInput.foto,
      nome: formInput.nome,
      precedencia: formInput.precedencia,
      pronomeTratamento: formInput.pronomeTratamento,
      sobrenome: formInput.sobrenome,
      telefones: formInput.telefones,
    }

    const res = await PutPessoa(payload, pathname.replace('/pessoa/', ''))

    if (contactDelete.length !== 0) {
      contactDelete.map(async (e) => {
        return await ContatoPessoaDelete(pathname.replace('/pessoa/', ''), e)
      })
    }

    const IdPessoa = res.data.pessoaId
    if (fotoTitle !== formInput.foto) {
      if (formInput.foto !== '') {
        await FotoPessoa(image, String(IdPessoa))
      }
    }

    if (selectEventTable.length !== 0) {
      for (const i in selectEventTable) {
        await DeletePessoaEvento(IdPessoa, selectEventTable[i])
      }
    }

    /// Adicionar os eventos
    if (ArrayList.arrayEventSelect.length !== 0) {
      for (const i in ArrayList.arrayEventSelect) {
        const evento = ArrayList.arrayEventSelect[i]

        const obj = {
          compoeMesa: evento.compoeMesa,
          eventoId: evento.eventoId,
          pessoaId: IdPessoa,
          precedencia: formInput.precedencia,
          profissionalEventoId: 0,
          responsavel: false,
          vinculo: '',
        }
        await CadastrarPessoaEvento(obj)
      }
    }

    if (res.status === 200) {
      handleModalAlterat(true)
    }
  }

  async function handleSendData() {
    const objRequired = cadastrarConvidado.filter((e) => e.require === true)
    const keyEmpty: string[] = []

    if (!isEmailValid) {
      notifyError('Email  inválido', `O email ${formInput.email} não é válido`)
      keyEmpty.push('email')
    }

    if (!isCpfValid) {
      notifyError('CPF inválido', `CPF precisa ter 11 dígitos`)
      keyEmpty.push('cpf')
    }

    for (const [key, value] of Object.entries(formInput)) {
      const emptyKey = objRequired.find((e) => e.name === key)
      if (emptyKey) {
        if (value === '') {
          notifyError(
            emptyKey.label,
            `O campo ${emptyKey.label} é obrigatorio.`,
          )
          const Verify = document.getElementById(key)?.classList.add('inactive')
          keyEmpty.push(emptyKey.name)
        }
      }
    }

    if (keyEmpty.length !== 0) {
      return
    }
    setRegisterPessoa(true)
  }

  async function handleCadaster() {
    setRegisterPessoa(false)

    formInput.telefones = formInput.contatos
    const telefones = []
    for (const telefone of formInput.contatos) {
      telefones.push({
        ddd: telefone.ddd,
        numero: telefone.numero,
        tipo: 'cell',
      })
    }
    try {
      const payload = {
        nome: formInput.nome,
        apelido: formInput.apelido,
        cpf: formInput.cpf,
        pronomeTratamento: formInput.pronomeTratamento,
        email: formInput.email,
        idCorporativo: '',
        sobrenome: formInput.sobrenome,
        cargo: formInput.cargo,
        precedencia: formInput.precedencia,
        titulo: '',
        foto: '',
        contatos: formInput.contatos,
        enderecos,
        telefones,
      }

      console.log(payload)
      const res = await Postpessoa(payload, [])
        .catch((error: AxiosError) => {
          if (error.response && error.response.data) {
            const responseData: any = error.response.data
            if (responseData.message) {
              const message = responseData.message
              setCadasterErrorMsg(message)
            }
          }

          throw error
        })
        .finally(() => {
          setOpenModal(false)
        })

      if (res.status === 201) {
        const IdPessoa = res.data.pessoaId
        setIdPessoa(IdPessoa)
        if (formInput.foto !== '') {
          await FotoPessoa(image, String(IdPessoa))
        }

        if (IdPessoa !== '' && id !== '' && id !== undefined) {
          const representanteId: number = IdPessoa

          const representantes: number[] = []
          representantes.push(representanteId)

          const data: Representante = {
            pessoaId: Number(id),
            representantes,
          }

          const resCreateRepresentante = await PostCreateRepresentante(data)

          if (
            resCreateRepresentante.status === 201 &&
            eventoId !== '' &&
            eventoId !== undefined
          ) {
            navigate(`/frequenciasevento/${eventoId}`)
          }
        }

        // Cadastrar o usuário recém criado nos eventos que ele escolheu
        if (ArrayList.arrayEventSelect.length !== 0) {
          const ArrayEvento = ArrayList.arrayEventSelect
          for (const evento of ArrayEvento) {
            const obj = {
              compoeMesa: false,
              eventoId: evento.eventoId,
              pessoaId: res.data.pessoaId,
              precedencia: formInput.precedencia,
              profissionalEventoId: 1,
              responsavel: false,
              vinculo: '',
            }

            await CadastrarPessoaEvento(obj)
          }
        }

        handleModalCreate(true)
      }
    } catch (err) {
      setErrorCadaster(true)
    }
  }

  const handleSelectEvent = async (value: string) => {
    if (value === '') {
      return
    }
    // objeto evento
    const Obj = ArrayList.arrayEventos.filter(
      (e) => `${e.eventoId}` === value,
    )[0]
    setArrayList({
      arrayEventSelect: [...ArrayList.arrayEventSelect, Obj],
    })

    Obj.compoeMesa = false

    if (!newPessoa) {
      setEventCadaster([...EventCadaster, Obj])
    }

    const t = ((
      document.getElementById('eventSelect') as HTMLSelectElement
    ).value = '')
  }

  const handleDeleteEvent = (value: number) => {
    const Obj = ArrayList.arrayEventSelect.filter((e) => e.eventoId !== value)
    setArrayList({
      arrayEventSelect: Obj,
    })

    setSelectEventTable([...selectEventTable, value])

    if (!newPessoa) {
      const teste =
        EventCadaster.length !== 0
          ? EventCadaster.filter((e) => e.eventoId !== value)
          : []
      setEventCadaster(teste)
    }

    notify('Evento', 'Evento', false)
  }

  const verifyDuplicated = (value: string, array: any[]) => {
    const Filtro = array.filter((e) => e === value)

    if (Filtro.length === 0) {
      return value
    }
    return ''
  }

  const handleSelectContato = (value: string) => {
    const findEvent = verifyDuplicated(value, ArrayList.telefoneAdicionado)
    if (findEvent === '') {
      return
    }

    const SemMask = findEvent
      .replaceAll('(', '')
      .replaceAll(')', '')
      .replaceAll(' ', '')
      .replaceAll('-', '')
    if (SemMask.length < 10) {
      toast.error('Número incompleto')
      return
    }
    const OBJ = {
      ddd: SemMask.slice(0, 2),
      numero: SemMask.slice(2, SemMask.length),
      tipo: 'cell',
      pessoaId: 0,
      id: 0,
    }

    const FlatListContato = [...formInput.contatos, OBJ].flat()

    setFormInput({
      contatos: FlatListContato,
    })

    setArrayList({
      telefoneAdicionado: [...ArrayList.telefoneAdicionado, findEvent],
    })

    setFormInputOption({ Telefone: '' })
  }

  const OpenModalAndExclude = (telefone: Contato) => {
    handleModalSucess(true)
    setContactDeleteOBJ(telefone)

    DeleteTefone(idPessoa, telefone.id)
  }

  const handleDeleteContato = () => {
    handleModalSucess(false)

    const remove = formInput.contatos.filter(
      (e) =>
        `${e.ddd} ${e.numero}` !==
        `${contactDeleteOBJ.ddd} ${contactDeleteOBJ.numero}`,
    )
    setFormInput({ contatos: remove })
    if (contactDeleteOBJ.id !== 0) {
      setContactDelete([...contactDelete, contactDeleteOBJ.id])
    }
    // setOpenModal(true)
    // setTimeout(() => setOpenModal(false), 1600)
    notify('Telefone', 'Telefone', false)
  }

  const CleanStateFormInput = () => {
    setEmailIsValid(true)
    setImagemSelecionada(null)
    setFormInput({
      apelido: '',
      cpf: '',
      idCorporativo: '1',
      nome: '',
      pronomeTratamento: '',
      precedencia: '',
      cargo: '',
      email: '',
      endereco: [],
      telefones: [],
      contatos: [],
    })
    setFormInputOption({
      Cargo: '',
      Email: '',
      Telefone: '',
      Evento: [],
    })
    setEndereco({
      cep: '',
      cidade: '',
      complemento: '',
      estado: '',
      id: 0,
      logradouro: '',
      numero: '',
    })
    ArrayList.arrayEventSelect = []
    // ArrayList.arrayEventos = []

    // selectEventTable.map((e) => handleDeleteEvent(e.eventoId));
  }

  const NotifyText = (
    header: string,
    descricao: string,
    AddOrRemove: boolean,
  ) => {
    return (
      <ContainerTost>
        <h6 style={!AddOrRemove ? { color: '#ff2d55' } : {}}>
          <BiInfoCircle /> {header}
        </h6>
        <p style={{ margin: 0 }}>
          {descricao} foi {AddOrRemove ? 'adicionado' : 'removido'} com sucesso.
        </p>
      </ContainerTost>
    )
  }

  const notify = (header: string, descricao: string, AddOrRemove: boolean) => {
    AddOrRemove
      ? toast.success(() => NotifyText(header, descricao, AddOrRemove), {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      : toast.error(NotifyText(header, descricao, AddOrRemove), {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
  }

  const handleChange = (event: any) => {
    // Utilizando uma regex para permitir somente letras, números e espaços
    return event//.replace(/[^a-zA-Z0-9 ]/g, '')
  }

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value
    for (const i in Object.keys(formInput)) {
      const key = Object.keys(formInput)[i]
      if (key === event.target.id) {
        switch (key) {
          case 'precedencia':
            setFormInput({
              precedencia: event.target.value.replace(/\D+/g, ''),
            })
            break
          case 'cpf':
            handleCpfChange(event)
            setFormInput({
              cpf: cpfMask(event.target.value.replace(/\D+/g, '')),
            })
            break
          case 'email':
            handleEmailChange(event)
            setFormInput({
              email: input,
            })

            break
          default:
            const regex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜãÃàÀâêîôûÂÊÎÔÛäëïöÄËÏÖçÇ\s]*$/;
            // if (regex.test(e.target.value)) {
            //   updateEvent({nome: (e.target.value)})
            // }
            if (regex.test(input)) {
              setFormInput({
                [key]: handleChange(input),
              })
            }
        }
      }
    }
  }

  return (
    <StyleContainer>
      <Modal show={openModal} onHide={() => setOpenModal(false)}>
        <Modal.Header style={{ background: '#D1E7DD' }} closeButton>
          <Modal.Title style={{ color: '#2A664A' }}>
            Contato deletado com sucesso
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ display: 'flex', alignItems: 'center', gap: '15px' }}
        >
          <ContainerGif>
            <img src={meuGif} alt="" />
          </ContainerGif>
          {/* <Spinner animation="border" variant="primary" /> Carregando... */}
        </Modal.Body>
      </Modal>

      <Modal show={registerPessoa} onHide={() => setRegisterPessoa(false)}>
        <Modal.Header style={{ background: '#D1E7DD' }} closeButton>
          <Modal.Title style={{ color: '#2A664A' }}>Cadastro</Modal.Title>
        </Modal.Header>
        <Modal.Body>Gostaria de cadastrar essa pessoa?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setRegisterPessoa(false)}>
            Não
          </Button>
          <Button variant="primary" onClick={handleCadaster}>
            Cadastrar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={errorCadaster} onHide={() => setErrorCadaster(false)}>
        <Modal.Header style={{ background: '#F8D7DA' }} closeButton>
          <Modal.Title style={{ color: 'rgb(116, 31, 39)' }}>
            Não podemos prosseguir
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Ocorreu um erro durante o cadastro. Tente novamente.
          <Text fontFamily="inter-bold">{cadasterErrorMsg}</Text>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setErrorCadaster(false)}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={campoVazio} onHide={() => setCampoVazio(false)}>
        <Modal.Header style={{ background: '#F8D7DA' }} closeButton>
          <Modal.Title style={{ color: 'rgb(116, 31, 39)' }}>
            Não podemos prosseguir
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Preencha todos os campos.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setCampoVazio(false)}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={modalEvent.create} onHide={() => handleModalCreate(false)}>
        <Modal.Header style={{ background: '#D1E7DD' }} closeButton>
          <Modal.Title style={{ color: '#2A664A' }}>Cadastro</Modal.Title>
        </Modal.Header>
        <Modal.Body>Pessoa cadastrada com sucesso.</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              if (event !== null) {
                localStorage.removeItem('EventRegister')
                localStorage.setItem('pessoaIdEvent', String(idPessoa))
              }

              navigate(event === null ? '/' : `/frequenciasevento/${event}`)
              handleModalCreate(false)
            }}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <DetalheDoEvento
        sucessOrError={false}
        showModal={modalEvent.delete}
        handleClose={() => handleModalDelete(false)}
        mensageHeader="Error"
        mensageBody="CEP não localizado."
        nextFunction={true}
      />

      <DetalheDoEvento
        sucessOrError={false}
        showModal={modalEvent.sucess}
        handleClose={() => handleModalSucess(false)}
        mensageHeader="Deletar item"
        mensageBody="Gostaria de deletar este item?"
        nextFunction={true}
        handleNextFunction={() => handleDeleteContato()}
      />

      <DetalheDoEvento
        sucessOrError={true}
        showModal={modalEvent.alterated}
        handleClose={() => handleModalAlterat(false)}
        mensageHeader="Alterado"
        mensageBody="Pessoa alterada com sucesso."
        nextFunction={false}
        urlPath={event === null ? '/' : `/frequenciasevento/${event}`}
        // handleNextFunction={() => handleDeleteContato()}
      />

      <ContainerCadaster>
        <div className="LeftContainer"></div>
        <div className="RightContainer">
          <div className="Visualizar">
            <span
              style={
                imagemSelecionada
                  ? {
                      border: '5px solid #333',
                      height: '100%',
                    }
                  : { border: 0 }
              }
            >
              {!imagemSelecionada ? (
                <>
                  <BsFillPersonFill />
                  <strong>Adicionar foto</strong>
                  <strong>(opcional)</strong>
                  <input
                    type="file"
                    accept="image/jpeg"
                    onChange={handleSelecionarImagem}
                  />
                </>
              ) : (
                <>
                  <img src={imagemSelecionada} alt="Select image" />
                  <input
                    type="file"
                    accept="image/jpeg"
                    onChange={handleSelecionarImagem}
                  />
                  {/* <button>X</button> */}
                </>
              )}
            </span>
          </div>
          {cadastrarConvidado?.map((e: IUserProps, i: number) => (
            <div key={i} style={{ width: '100%' }}>
              <FormControl
                key={i}
                id={e.name}
                type={e.type}
                placeholder={e.label}
                value={formInput[e.name as keyof IPropCadastroPessoa]}
                onChange={handleChangeInput}
                onBlur={(event: FocusEvent<HTMLInputElement>) =>
                  e.require && handleValidatedForm(event)
                }
              />
              {e.name === 'email' && !isEmailValid && (
                <p style={{ color: 'red', paddingTop: '5px' }}>
                  Por favor, insira um email válido.
                </p>
              )}
              {e.name === 'cpf' && !isCpfValid && (
                <p style={{ color: 'red', paddingTop: '5px' }}>
                  O CPF precisa ter 11 dígitos
                </p>
              )}
            </div>
          ))}

          <div className="addContact">
            <div>
              <FormControl
                value={formInputOption.Telefone}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setFormInputOption({
                    Telefone: telefoneMask(e.target.value),
                  })
                  // handleSelectContato(telefoneMask(e.target.value), "phone")
                }}
                placeholder="Telefone (Opcional)"
              />
              <Button
                onClick={() => handleSelectContato(formInputOption.Telefone)}
              >
                Adicionar
              </Button>
            </div>
            <div>
              <label htmlFor="">Telefones adicionados</label>
              <ul>
                {formInput.contatos?.map((e, i: number) => (
                  <li key={i}>
                    <p>
                      <AiOutlinePhone />
                      {telefoneMask(`${e.ddd + e.numero}`)}
                    </p>
                    <span onClick={() => OpenModalAndExclude(e)}>
                      <FaTrash />
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="EderecoCOntainer">
            <div>
              <label htmlFor="">Endereço</label>
              <div>
                <FormControl
                  type="text"
                  value={endereco.cep}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setEndereco({ cep: cepMask(e.target.value) })
                    setEnderecoHasCep(!(e.target.value === ''))
                  }}
                  maxLength={10}
                  onBlur={() => {
                    BuscaCEPFunc()
                  }}
                  placeholder="CEP"
                  style={{
                    borderColor: enderecoHasCep ? undefined : 'red',
                  }}
                />
                <FormControl
                  type="text"
                  value={endereco.numero}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setEndereco({ numero: e.target.value })
                    setEnderecoHasNumero(!(e.target.value === ''))
                  }}
                  placeholder="Número"
                  style={{
                    borderColor: enderecoHasNumero ? undefined : 'red',
                  }}
                />
              </div>
              <div>
                <FormControl
                  type="text"
                  value={endereco.estado}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setEndereco({ estado: e.target.value })
                    setEnderecoHasEstado(!(e.target.value === ''))
                  }}
                  placeholder="Estado"
                  style={{
                    borderColor: enderecoHasEstado ? undefined : 'red',
                  }}
                />
                <FormControl
                  type="text"
                  value={endereco.cidade}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setEndereco({ cidade: e.target.value })
                    setEnderecoHasCidade(!(e.target.value === ''))
                  }}
                  placeholder="cidade"
                  style={{
                    borderColor: enderecoHasCidade ? undefined : 'red',
                  }}
                />
              </div>
              <div>
                <FormControl
                  type="text"
                  value={endereco.logradouro}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setEndereco({ logradouro: e.target.value })
                    setEnderecoHasLogradouro(!(e.target.value === ''))
                  }}
                  placeholder="Logradouro"
                  style={{
                    borderColor: enderecoHasLogradouro ? undefined : 'red',
                  }}
                />
                <FormControl
                  type="text"
                  value={endereco.complemento}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setEndereco({ complemento: e.target.value })
                  }}
                  placeholder="Complemento (Opcional)"
                />
              </div>

              <Button onClick={() => addItemEndereco()}>Adicionar</Button>
            </div>
            <div>
              <label htmlFor="">Endereços adicionados</label>

              <TableContainer>
                <thead>
                  <tr>
                    <th>CEP</th>
                    <th>Cidade</th>
                    <th>Estado</th>
                    {/* <th>Numero</th> */}
                    <th>Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {formInput.endereco?.map((e: Endereco) => (
                    <tr key={e.id}>
                      <td>
                        <p>{e.cep}</p>
                      </td>
                      <td>
                        <p>{e.cidade}</p>
                      </td>
                      <td>
                        <p>{e.estado}</p>
                      </td>
                      {/* <td><p>{e.numero}</p></td> */}
                      <td>
                        <span onClick={() => removeItemEndereco(e.id)}>
                          <FaTrash />
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </TableContainer>
            </div>
          </div>

          <div className="eventosContainer">
            <label htmlFor="">Lista de eventos:</label>
            <div>
              <InputGroup>
                {/* <div>
                      <Form.Control />
                    </div> */}
                <div className="OpenModal">
                  <select
                    onChange={(e: any) => handleSelectEvent(e.target.value)}
                    name=""
                    id="eventSelect"
                  >
                    <option value=""></option>
                    {ArrayList.arrayEventos
                      .filter(
                        (e) =>
                          !ArrayList.arrayEventSelect
                            .map((e) => e.eventoId)
                            .includes(e.eventoId),
                      )
                      .map((e: Evento) => (
                        <option key={e.eventoId} value={e.eventoId}>
                          {e.nome}
                        </option>
                      ))}
                  </select>
                </div>
              </InputGroup>
            </div>

            <div>
              <label htmlFor="">Lista de eventos Vinculados</label>
              <table className="tableStyle">
                <thead>
                  <tr>
                    <th>Evento</th>
                    <th>Mesa</th>
                    <th>Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {ArrayList.arrayEventSelect !== undefined && (
                    <>
                      {ArrayList.arrayEventSelect?.map((event) => {
                        return (
                          <tr key={event.eventoId}>
                            <td>
                              <p>{event.nome}</p>
                            </td>
                            <td>
                              <div
                                style={
                                  event.compoeMesa === true
                                    ? { color: '#0d6efd' }
                                    : { color: '#29292e' }
                                }
                                onClick={async () => {
                                  event.compoeMesa = !event.compoeMesa

                                  setArrayList({
                                    arrayEventSelect:
                                      ArrayList.arrayEventSelect,
                                  })
                                  if (idPessoa !== 0) {
                                    const objTable = {
                                      compoeMesa: event.compoeMesa,
                                      eventoId: event.eventoId,
                                      pessoaId: idPessoa,
                                    }

                                    await RegisterMesa(objTable)
                                  }
                                }}
                              >
                                <TableIconImage />
                              </div>
                            </td>
                            <td>
                              <span
                                onClick={() =>
                                  handleDeleteEvent(event.eventoId)
                                }
                              >
                                <FaTrash />
                              </span>
                            </td>
                          </tr>
                        )
                      })}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ContainerCadaster>

      <span className="ContainerButton">
        {newPessoa ? (
          <ButtonContainer
            // disabled={!isFormatValid}
            onClick={handleSendData}
            variant="primary"
          >
            Salvar
          </ButtonContainer>
        ) : (
          <ButtonContainer onClick={Alterar} variant="primary">
            Alterar
          </ButtonContainer>
        )}
        <Button onClick={CleanStateFormInput} variant="primary">
          Limpar
        </Button>
        <Link
          onClick={() => localStorage.removeItem('EventRegister')}
          to={event === null ? '/' : `/frequenciasevento/${event}`}
        >
          <Button variant="danger">Cancelar</Button>
        </Link>
      </span>
    </StyleContainer>
  )
}
