import { Button, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'

type TypeProp = {
  sucessOrError: boolean
  showModal: boolean
  handleClose: () => void
  mensageHeader: string
  mensageBody: string | Element | any
  nextFunction: boolean
  handleNextFunction?: () => void
  urlPath?: string
}

export function DetalheDoEvento({
  sucessOrError,
  showModal,
  handleClose,
  mensageHeader,
  mensageBody,
  nextFunction,
  handleNextFunction,
  urlPath,
}: TypeProp) {
  return (
    <Modal show={showModal} onHide={() => handleClose()}>
      {sucessOrError ? (
        <Modal.Header style={{ background: '#D1E7DD' }} closeButton>
          <Modal.Title style={{ color: '#2A664A' }}>
            {mensageHeader}
          </Modal.Title>
        </Modal.Header>
      ) : (
        <Modal.Header style={{ background: '#F8D7DA' }} closeButton>
          <Modal.Title style={{ color: 'rgb(116, 31, 39)' }}>
            {mensageHeader}
          </Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>{mensageBody}</Modal.Body>
      <Modal.Footer>
        {nextFunction ? (
          <>
            {handleNextFunction === undefined ? (
              <>
                <Button variant="secondary" onClick={() => handleClose()}>
                  Fechar
                </Button>
              </>
            ) : (
              <>
                <Button variant="secondary" onClick={() => handleClose()}>
                  Fechar
                </Button>
                <Button variant="danger" onClick={() => handleNextFunction()}>
                  Continuar
                </Button>
              </>
            )}
          </>
        ) : (
          <Link to={`${urlPath}`}>
            <Button variant="primary" onClick={() => handleClose()}>
              Fechar
            </Button>
          </Link>
        )}
      </Modal.Footer>
    </Modal>
  )
}

// export DetalheDoEvento;
