import styled from 'styled-components'

export const Container = styled.div`
  > div {
    margin-bottom: 16px;
    > div {
      :first-child {
        width: 100%;
        height: 39px;
        display: flex;
        margin-bottom: 5px;

        input {
          width: 70%;
        }

        button {
          margin-left: 5px;
        }
      }

      :last-child {
        margin-top: 0.5rem;
        border-collapse: separate;
        border-spacing: 0 0.5rem;

        table {
          width: 100%;
          vertical-align: middle;

          thead {
            padding: 1.25rem 2rem;
            text-align: center;
            tr > th {
              :first-child {
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;
              }

              :last-child {
                width: 5px;
                border-bottom-right-radius: 6px;
                border-bottom-right-radius: 6px;
              }
            }
          }

          tbody {
            text-align: center;
          }
        }
      }
    }

    :last-child {
      margin-top: 6.5rem;
      border-collapse: separate;
      border-spacing: 0 0.5rem;
      span {
        width: 50%;
        font-weight: bold;
        margin-bottom: 1.5 rem;
      }

      table {
        width: 100%;
        vertical-align: middle;

        thead {
          padding: 1.25rem 2rem;
          text-align: center;
          tr > th {
            width: calc(100% / 4);
            :first-child {
              border-top-left-radius: 6px;
              border-bottom-left-radius: 6px;
            }

            :last-child {
              width: 5px;
              border-bottom-right-radius: 6px;
              border-bottom-right-radius: 6px;
            }
          }
        }

        tbody {
          text-align: center;
        }
      }
    }
  }
`
export const Buttons = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: 15px;

  button {
    width: 100px;
  }
`

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`
