import React, { ChangeEvent, useContext, useEffect, useState } from 'react'

import { Button, FormControl, Modal } from 'react-bootstrap'
import { DetalhesEventos, StyleList } from './styled'
import { DetalheDoEvento } from '../../components/Modal'
import { BsFillPencilFill, BsTrash } from 'react-icons/bs'
import { RiFileList3Line } from 'react-icons/ri'
import { TbShare } from 'react-icons/tb'
import html2pdf from 'html2pdf.js'
import { Link, Params } from 'react-router-dom'
import { DeleteEventos, GetFotoEvento } from '../../service'
import { ContextAPI } from '../../hooks/useContextApi'
import { useBreakpointValue } from '@chakra-ui/react'
import DadosEventoDesktop from './detalheDoEvento/DadosEventoDesktop'
import DadosEventoMobile from './detalheDoEvento/DadosEventoMobile'

interface TypeLitingProps {
  search: string
  ShowDetail: Boolean
  arrayEventos: Evento[]
  arrayNotify: []
  arrayEventListResponsaveis: EventTypeListResponsaveis[]
  ListPessoa: Pessoa[]
}

interface IPropForm {
  event: ChangeEvent<HTMLInputElement>
}

interface IPropWidthHeight {
  width: any
  height: any
}

function EventosCadastrados() {
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
    xl: false,
  })

  const [Listing, setListing] = React.useReducer(
    (prev: TypeLitingProps, next: Partial<TypeLitingProps>) => {
      return { ...prev, ...next }
    },
    {
      search: '',

      ShowDetail: false,

      arrayEventos: [],
      arrayNotify: [],
      arrayEventListResponsaveis: [],
      ListPessoa: [],
    },
  )

  const [endereco, setEndereco] = useState<Endereco>()

  const [formInput, setFormInput] = React.useReducer(
    (prev: Evento, next: Partial<Evento>) => {
      return { ...prev, ...next }
    },
    {
      banner: '',
      dataFinal: '',
      dataInicial: '',
      descricao: '',
      email: '',
      enderecos: {},
      eventoId: 0,
      horaFinal: '',
      horaInicial: '',
      local: '',
      mestreDeCerimoniaId: 0,
      nome: '',
      responsaveis: [],
      site: '',
      telefones: [],
      // eventosId:0
    },
  )

  const [ShowDetail, setShowDetail] = React.useState(false)
  const [ShowNotify, setShowNotify] = React.useState(false)
  const [imagemSelecionada, setImagemSelecionada] = React.useState<any>()
  const [resolution, setResolution] = React.useState<IPropWidthHeight>()

  const {
    pessoas,
    eventos,
    modalEvent,
    handleModalDelete,
    handleModalSucess,
    handleModalError,
    handleAlterName,
    handleChangeUrlRoute,
  } = useContext(ContextAPI)

  const HandleSearch = (e: any) => {
    setListing({ search: e.target.value })
  }

  const FecharModal = () => {
    setShowDetail(false)
  }

  const concatenatedNames = (eventoId: number) => {
    const filterResponsaveis = Listing.arrayEventListResponsaveis.filter(
      (responsavel) => responsavel.eventoId === eventoId,
    )

    const names: string[] = []
    filterResponsaveis.forEach((responsavel) => {
      const responsavelNames = responsavel.responsaveis.map(
        (pessoa) => pessoa.nome,
      )
      names.push(...responsavelNames)
    })

    const concatenated = names.join(', ')

    return concatenated
  }

  const mestreDecerimonia = (evento: Evento) => {
    const filterResponsaveis: ResponsavelEvento | undefined =
      evento.responsaveis.find((event) => event.isResponsavel === false)

    if (filterResponsaveis) {
      const pessoa: Pessoa | undefined = Listing.ListPessoa.find(
        (e) => e.pessoaId === filterResponsaveis.pessoaId,
      )

      if (pessoa) {
        return pessoa?.nome
      } else {
        return <>Tribunal de Justica do estado de Goias</>
      }
    } else {
      return <>Tribunal de Justica do estado de Goias</>
    }
  }

  useEffect(() => {
    handleAlterName('Eventos Cadastrados')
    handleChangeUrlRoute('/')
    async function response() {
      const filteredEventos = eventos.map((evento) => {
        const responsaveis = evento.responsaveis.map((responsavel) => {
          const filteredPessoas = pessoas
            .filter((pessoa) => pessoa.pessoaId === responsavel.pessoaId)
            .map((pessoa) => pessoa.nome)[0]

          return {
            nome: filteredPessoas,
          }
        })

        return {
          eventoId: evento.eventoId,
          responsaveis,
        }
      })

      setListing({
        arrayEventos: eventos,
        ListPessoa: pessoas,
        arrayEventListResponsaveis: filteredEventos,
      })
    }
    response()
  }, [eventos, pessoas])

  function gerarPDF() {
    const elemento = document.getElementById('TabelaPDF')
    const opt = {
      margin: 0,
      filename: 'Relatorios.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 1 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    }
    html2pdf().set(opt).from(elemento).save()
  }

  const handleOpenModal = async (e: Evento) => {
    setFormInput({
      dataFinal: e.dataFinal,
      dataInicial: e.dataInicial,
      descricao: e.descricao,
      email: e.email,
      enderecos: e.enderecos,
      telefones: e.telefones,
      local: e.enderecos,
      nome: e.nome,
      site: e.site,
      eventoId: e.eventoId,
      banner: e.banner,
      horaFinal: e.horaFinal,
      horaInicial: e.horaInicial,
    })

    const enderecos: Endereco[] = e.enderecos

    if (enderecos && enderecos.length > 0) {
      setEndereco(enderecos[0])
    } else {
      setEndereco(undefined)
    }

    if (e.banner !== '') {
      try {
        // const fotoPessoa = await GetFotoPessoa(objList.foto)

        await GetFotoEvento(e.banner).then((e) => {
          const base64 = btoa(
            new Uint8Array(e.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              '',
            ),
          )
          // const teste =
          setImagemSelecionada(
            `data:${e.headers['content-type'].toLowerCase()};base64,${base64}`,
          )

          handleImageUpload(
            `data:${e.headers['content-type'].toLowerCase()};base64,${base64}`,
          )
        })
      } catch (err) {}
    }
    setShowDetail(true)
  }

  const HandleDelete = async () => {
    try {
      const res = await DeleteEventos(formInput.eventoId)
      if (res.status === 202) {
        handleModalDelete(false)
        handleModalSucess(true)
        setListing({
          arrayEventos: Listing.arrayEventos.filter(
            (e) => e.eventoId !== formInput.eventoId,
          ),
        })
      }
    } catch (err) {
      handleModalDelete(false)

      handleModalError(true)
    }
  }

  const handleImageUpload = (base64String: string) => {
    const image = new Image()
    image.src = base64String

    image.onload = function () {
      setResolution({ width: image.width, height: image.height })
    }
  }

  return (
    <>
      <DetalheDoEvento
        sucessOrError={false}
        showModal={modalEvent.delete}
        handleClose={() => {
          handleModalDelete(false)
          setShowDetail(true)
        }}
        mensageHeader="Deletar item"
        mensageBody="Gostaria de deletar este item?"
        nextFunction={true}
        handleNextFunction={() => HandleDelete()}
      />

      <DetalheDoEvento
        sucessOrError={true}
        showModal={modalEvent.sucess}
        handleClose={() => {
          handleModalSucess(false)
        }}
        mensageHeader="Deletado"
        mensageBody="Item deletado com sucesso."
        nextFunction={true}
        // handleNextFunction={() => HandleDelete()}
      />

      <DetalheDoEvento
        sucessOrError={false}
        showModal={modalEvent.error}
        handleClose={() => {
          handleModalError(false)
        }}
        mensageHeader="Não podemos prosseguir"
        mensageBody="Este item esta vinculado a uma pessoa."
        nextFunction={true}
        // handleNextFunction={() => HandleDelete()}
      />

      {/* <Card.Body> */}
      <StyleList>
        <div>
          <Link to={'/registerevento'}>
            <Button>Novo evento</Button>
          </Link>
          <Link to={'/'}>
            <Button>Lista de pessoas</Button>
          </Link>
        </div>

        <div>
          <FormControl
            value={Listing.search}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              HandleSearch(event)
            }}
            placeholder="Buscar por nome ou mestre de cerimonias"
          />
          {/* <Button
                  variant="primary"
                >
                  <FaSearch />
                </Button> */}
        </div>
        {/* #d1d3d686 */}
        <div>
          <ul>
            {Listing.arrayEventos
              .filter((e: Evento) => {
                const nome = e.nome
                  .toLocaleLowerCase()
                  .includes(Listing.search.toLocaleLowerCase())
                const desc = e.descricao
                  .toLocaleLowerCase()
                  .includes(Listing.search.toLocaleLowerCase())
                return nome || desc
              })
              .map((e: Evento) => (
                <li key={e.eventoId} onClick={() => handleOpenModal(e)}>
                  <div>
                    <span>
                      <h1>Nome do evento:</h1>
                      <p>{e.nome}</p>
                    </span>
                    <span>
                      <h1>Descrição:</h1>
                      <p>{e.descricao}</p>
                    </span>
                  </div>
                  <div>
                    <span>
                      <h1>Mestre de cerimônias:</h1>
                      <p>{mestreDecerimonia(e)}</p>
                    </span>
                    <span>
                      <h1>Responsável (eis):</h1>
                      <p>{concatenatedNames(e.eventoId)}</p>
                    </span>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </StyleList>
      {/* </Card.Body> */}

      <Modal size="lg" show={ShowDetail} onHide={() => FecharModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Detalhes do Evento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DetalhesEventos id={'TabelaPDF'}>
            <div>
              <Link to={`/frequenciasevento/${formInput.eventoId}`}>
                <Button variant="link">
                  <RiFileList3Line />
                </Button>
              </Link>
              <Button variant="link" onClick={gerarPDF}>
                <TbShare />
              </Button>
              <Link to={`/evento/${formInput.eventoId}`}>
                <Button variant="link">
                  <BsFillPencilFill />
                </Button>
              </Link>

              <Button
                variant="link"
                onClick={() => {
                  handleModalDelete(true)
                  FecharModal()
                }}
              >
                <BsTrash style={{ color: '#dc3545' }} />
              </Button>
            </div>
            <div>
              {formInput.banner !== '' ? (
                <img
                  src={imagemSelecionada}
                  // style={{ width: `${resolution?.width / 2}` }}
                  alt="Select image"
                />
              ) : (
                <h1>Banner do Evento</h1>
              )}
            </div>
            {!isMobile && (
              <DadosEventoDesktop
                dataFinal={formInput.dataFinal}
                dataInicial={formInput.dataInicial}
                descricao={formInput.descricao}
                email={formInput.email}
                logradouro={endereco?.logradouro}
                complemento={endereco?.complemento}
                nome={formInput.nome}
                site={formInput.site}
                telefones={formInput.telefones}
                horaInicial={formInput.horaInicial}
                horaFinal={formInput.horaFinal}
              />
            )}
            {isMobile && (
              <DadosEventoMobile
                dataFinal={formInput.dataFinal}
                dataInicial={formInput.dataInicial}
                descricao={formInput.descricao}
                email={formInput.email}
                logradouro={endereco?.logradouro}
                complemento={endereco?.complemento}
                nome={formInput.nome}
                site={formInput.site}
                telefones={formInput.telefones}
                horaInicial={formInput.horaInicial}
                horaFinal={formInput.horaFinal}
              />
            )}
          </DetalhesEventos>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => FecharModal()}>
            Voltar
          </Button>
          <Link to={`/evento/${formInput.eventoId}`}>
            <Button variant="primary" onClick={() => FecharModal()}>
              Editar
            </Button>
          </Link>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default EventosCadastrados

export async function reloadPage() {
  window.location.reload()
}
