import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Heading,
  Text,
  HStack,
  Switch,
  Spacer,
  Spinner,
  CircularProgress,
  Center,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { CadastrarPessoaEvento, GetEventos } from '../../service'

interface Props {
  pessoa: Pessoa
  isOpen: boolean
  onClose: () => void
  onSave: () => void
}

const VincularPopup = ({ pessoa, isOpen, onClose, onSave }: Props) => {
  const cancelRef = React.useRef<HTMLButtonElement>(null)
  const [isLoadingEvents, setisLoadingEvents] = useState(true)
  const [loadingProgress, setLoadingProgress] = useState(0)
  const [showLoadingProgress, setShowLoadingProgress] = useState(false)
  const [eventos, setEventos] = useState<Evento[]>()
  const [eventosChecked, setEventosChecked] = useState<boolean[]>([])
  const delay = (milliseconds: number) => {
    return new Promise((resolve) => {
      setTimeout(resolve, milliseconds)
    })
  }
  useEffect(() => {
    GetEventos()
      .then(async (response) => {
        setEventos(response.data)
        const eventosLength = eventos?.length
        const newArray = new Array(eventosLength)
        newArray.fill(false)
        setEventosChecked(newArray)
        setisLoadingEvents(false)
      })
      .catch((error) => {
        setisLoadingEvents(false)
        console.error(error)
      })
  }, [])

  async function saveData() {
    setShowLoadingProgress(true)
    const eventosCheckedTrue = eventosChecked.filter((e) => e)
    const progressStep = 100 / eventosCheckedTrue.length

    let currentProgress = 0
    for (let i = 0; i < eventosChecked.length; i++) {
      if (eventosChecked[i]) {
        currentProgress += progressStep
        setLoadingProgress(currentProgress)
        await CadastrarPessoaEvento({
          eventoId: eventos![i].eventoId,
          pessoaId: pessoa.pessoaId,
          compoeMesa: false,
          profissionalEventoId: 1,
          responsavel: false,
          vinculo: '',
        })
      }
    }
    onSave()
  }

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            <HStack fontFamily="inter-medium">
              <Text>Adicione</Text>
              <Text color="gray">{pessoa.nome}</Text>
              <Text>a um evento</Text>
            </HStack>
          </AlertDialogHeader>

          <AlertDialogBody
            fontFamily="inter-medium"
            maxH="70vh"
            overflowY="scroll"
            css={{
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-track': {
                borderRadius: '10px',
              },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: '10px',
                backgroundColor: 'rgba(0, 18, 250, 0.8)',
              },
            }}
          >
            {isLoadingEvents && (
              <Center>
                <Spinner />
              </Center>
            )}
            {showLoadingProgress && (
              <Center>
                <CircularProgress value={loadingProgress} size="120px" />
              </Center>
            )}
            {eventos &&
              !showLoadingProgress &&
              eventos.map((evento, index) => (
                <HStack key={evento.eventoId}>
                  <Text>{evento.nome}</Text>
                  <Spacer />
                  <Switch
                    id={evento.eventoId.toString()}
                    checked={eventosChecked[index]}
                    onChange={() => {
                      const newArray = eventosChecked
                      newArray[index] = !newArray[index]
                      setEventosChecked(newArray)
                    }}
                  />
                </HStack>
              ))}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              bg="rgba(0, 18, 250, 0.8)"
              onClick={() => saveData()}
              ml={3}
              textColor="white"
            >
              Adicionar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default VincularPopup
