import React from 'react'
import { VStack, Text, Box } from '@chakra-ui/react'

interface Props {
  title: string
  data: string
  hora: string
}

const DataField = ({ title, data, hora }: Props) => {
  const months = [
    'janeiro',
    'fevereiro',
    'março',
    'abril',
    'maio',
    'junho',
    'julho',
    'agosto',
    'setembro',
    'outubro',
    'novembro',
    'dezembro',
  ]
  return (
    <VStack>
      <Text fontFamily="inter-medium" fontSize="18px" color="gray.500">
        {title}
      </Text>
      <VStack
        w="115px"
        h="110px"
        borderRadius="10px"
        bg="#EEEEFA"
        boxShadow="0px 10px 10px rgba(0, 0, 0, 0.1)"
      >
        <Box
          flex={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
          fontSize="25px"
        >
          <Text>{data.split('/')[0]}</Text>
        </Box>
        <Text>{data.split('/')[2]}</Text>
        <Box
          flex={1}
          bg="red"
          p="2px"
          w="100%"
          borderRadius="0px 0px 10px 10px"
          textAlign="center"
          textColor="white"
          fontFamily="inter-bold"
          fontSize="15px"
        >
          <Text>{months[Number(data.split('/')[1]) - 1]}</Text>
        </Box>
      </VStack>
      <Text fontFamily="inter-bold" fontSize="15px">
        {hora}
      </Text>
    </VStack>
  )
}

export default DataField
