import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { Button, Card, Form, Modal, OverlayTrigger } from 'react-bootstrap'
import { FaEdit, FaSearch, FaTrash } from 'react-icons/fa'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'

// import paginationFactory from "react-bootstrap-table2-paginator";

// import { Container } from './styled'
import { Link } from 'react-router-dom'
import { renderTooltipDelete, renderTooltipEdit } from '../../utils/Tooltip'
import { BuscarPessoa, DeletePessoa, GetAllUser } from '../../service'
import { DetalheDoEvento } from '../../components/Modal'
import { customTotal } from '../../utils/functions'
import { ContextAPI } from '../../hooks/useContextApi'
import WebShare from '../../components/WebShare'
import { AiOutlineLink } from 'react-icons/ai'
import { Container } from './styled'
import { UserPessoa } from '../usuario/types'

export function ListaUsuarios() {
  const [pessoasList, setPessoasList] = useState<Pessoa[]>([])
  const [pessoasListFixed, setPessoasListFixed] = useState<Pessoa[]>([])
  const [search, setSearch] = useState<string>('')
  const [showDelete, setShowDelete] = useState(false)

  const [id, setId] = useState<number>(0)
  const {
    modalEvent,
    pessoas,
    handleModalCreate,
    handleModalDelete,
    handleModalSucess,
    handleAlterName,
    handleChangeReload,
  } = useContext(ContextAPI)

  useEffect(() => {
    handleAlterName('Lista de usuário')
    handleChangeReload()
    Rest()
    async function Rest() {
      const response = await GetAllUser()
      setPessoasListFixed(response.data)
      setPessoasList(response.data)
    }
  }, [])

  function HandleSearch(e: string) {
    setSearch(e)
    if (e === '') {
      setPessoasList(pessoasListFixed)
      return
    }

    setPessoasList((prevState) => {
      return pessoasListFixed.filter((event: any) => {
        const nome = event.nome.toLowerCase().includes(e.toLowerCase())
        const username = event.user.username
          .toLowerCase()
          .includes(e.toLowerCase())
        const cpf = event.cpf
          .replaceAll('.', '')
          .replaceAll('-', '')
          .includes(e.replaceAll('.', '').replaceAll('-', ''))

        return nome || username || cpf
      })
    })
  }

  const HandleDeleteOpenModal = (id: number) => {
    handleModalDelete(true)
    setId(id)
  }

  const HandleDelete = async () => {
    const response = await DeletePessoa(id)
    if (response.status === 202) {
      handleModalDelete(false)
      handleModalSucess(true)
      const totalPessoa = pessoasList.filter((e) => e.pessoaId !== id)
      setPessoasList(totalPessoa)
      setPessoasListFixed(totalPessoa)
    } else {
      handleModalCreate(true)
    }
  }

  const ColumnsFontes = [
    { dataField: 'nome', text: 'Nome', sort: true },
    {
      dataField: 'username',
      text: 'username',
      sort: true,
      formatter: (
        column: any,
        row: UserPessoa,
        rowIndex: any,
        formatExtraData: any,
      ) => {
        return <>{row.user.username}</>
      },
    },
    { dataField: 'cpf', text: 'cpf', sort: true },
    {
      dataField: 'editar',
      text: 'Editar',
      formatter: (
        column: any,
        row: Pessoa,
        rowIndex: any,
        formatExtraData: any,
      ) => {
        return (
          <div>
            <Link to={`/usuario/${row.pessoaId}`} tabIndex={-1}>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltipEdit}
              >
                <Button variant="link">
                  <FaEdit style={{ color: '#004a80' }} />
                </Button>
              </OverlayTrigger>
            </Link>
          </div>
        )
      },
    },
    {
      dataField: 'excluir',
      text: 'Excluir',
      formatter: (
        column: any,
        row: Pessoa,
        rowIndex: any,
        formatExtraData: any,
      ) => {
        return (
          <div>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltipDelete}
            >
              <Button variant="link" onClick={() => setShowDelete(true)}>
                <FaTrash style={{ color: '#ac0505' }} />
              </Button>
            </OverlayTrigger>
          </div>
        )
      },
    },
    {
      dataField: 'link',
      text: 'Vincular',
      formatter: (
        column: any,
        row: Pessoa,
        rowIndex: any,
        formatExtraData: any,
      ) => {
        return (
          <div>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltipDelete}
            >
              <Button
                variant="link"
                onClick={() => HandleDeleteOpenModal(row.pessoaId)}
              >
                <AiOutlineLink style={{ color: '#004a80' }} />
              </Button>
            </OverlayTrigger>
          </div>
        )
      },
    },
  ]
  const options = {
    paginationSize: 5,
    pageStartIndex: 1,
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '30',
        value: 30,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: 'Todos',
        value: pessoasList.length,
      },
    ],
  }
  return (
    <div>
      <DetalheDoEvento
        sucessOrError={false}
        showModal={modalEvent.delete}
        handleClose={() => handleModalDelete(false)}
        mensageHeader="Deletar"
        mensageBody="Gostaria de deletar essa pessoa?"
        nextFunction={true}
        handleNextFunction={() => HandleDelete()}
      />

      <DetalheDoEvento
        sucessOrError={true}
        showModal={modalEvent.sucess}
        handleClose={() => handleModalSucess(false)}
        mensageHeader="Deletado"
        mensageBody="Pessoa deletada com sucesso."
        nextFunction={true}
      />

      <DetalheDoEvento
        sucessOrError={false}
        showModal={modalEvent.create}
        handleClose={() => handleModalCreate(false)}
        mensageHeader="Erro"
        mensageBody="Tivemos um erro ao deletar a pessoa. Tente novamente."
        nextFunction={true}
      />

      <Modal show={showDelete} onHide={() => setShowDelete(false)}>
        <Modal.Header style={{ background: '#F8D7DA' }} closeButton>
          <Modal.Title style={{ color: 'rgb(116, 31, 39)' }}>
            Excluir usuário
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Deseja realmente excluir este usuário? <br />
          <ul>
            <li>
              Nome: <strong>teste</strong>
            </li>
            <li>
              Título: <strong>teste</strong>
            </li>
            <li>
              Cargo: <strong>teste</strong>
            </li>
            <li>
              Matrícula: <strong>teste</strong>
            </li>
            <li>
              CPF: <strong>teste</strong>
            </li>
            <li>
              Precedência: <strong>teste</strong>
            </li>
            <li>
              Observações: <strong>teste</strong>
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDelete(false)}>
            Fechar
          </Button>
          <Button
            variant="danger"
            // onClick={() => handleNextFunction()}
          >
            Continuar
          </Button>
        </Modal.Footer>
      </Modal>

      <Container>
        <div>
          <Link to={`/cadastro-usuarios`} tabIndex={-1}>
            <Button style={{ whiteSpace: 'nowrap' }} variant="primary">
              Novo cadastro
            </Button>
          </Link>
          <Link to={`/eventosCadastrados`} tabIndex={-1}>
            <Button style={{ whiteSpace: 'nowrap' }} variant="primary">
              Lista de eventos
            </Button>
          </Link>
          {/* <span></span> */}
        </div>
        <Form.Control
          placeholder="Pesquise por nome ou cargo"
          value={search}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            HandleSearch(e.target.value)
          }
        />
        <div>
          <BootstrapTable
            // loading={true}
            bordered={true}
            bootstrap4
            striped
            hover
            condensed
            keyField="pessoaId"
            columns={ColumnsFontes}
            data={pessoasList}
            pagination={paginationFactory(options)}
            defaultSortDirection="asc"
          />
        </div>
      </Container>
    </div>
  )
}
