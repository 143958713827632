export default {
  title: 'light',

  colors: {
    primary: '#fff',
    secundary: '#004a80',

    background: '#f5f5f5',
    text: '#000',
    title: '#004a80',
    barAcessibility: '#e5e5e5',
    navBar: '#004a80',
    numAcessibility: '#d3d3d3',
    border: 'rgb(208,213,220)',
    barMenu: 'rgb(246,246,246)',
    rodape: 'rgb(216,216,216)',
    main: '#5061FC',
    dark: '#3346F0',
  },
}
