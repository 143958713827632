import styled from 'styled-components'

type WidthContainerTable = {
  WidthContainer?: number
}

export const ContainerTable = styled.div<WidthContainerTable>`
  table {
    width: 100%;
    border: 1px solid rgb(208, 213, 220);

    /* th {
      text-align: center;
    } */

    td,
    th {
      border: 1px solid rgb(208, 213, 220);
      text-align: center;
      /* ${(prop) =>
        prop.WidthContainer === 0
          ? `width:calc(100% / 6)`
          : `width:calc(${prop.WidthContainer} / 6)`} */
      /* width: calc(1029px / 6); */
      width: ${(prop) =>
        prop.WidthContainer === 0
          ? `calc(100% / 6)`
          : `calc(${prop.WidthContainer}px / 6)`};
      height: 38px;
    }

    th {
      line-height: 1.6;
      padding: 0.525rem;
      background: rgba(0, 0, 0, 0.05);
    }
    td {
      padding: 0.525rem;
      line-height: 1.6;
      :nth-child(5) {
        svg {
          width: 40px;
          height: 40px;
          transition: all ease 0.2s;

          &:hover {
            color: #0d6efd;
          }
        }
      }
    }

    tbody,
    thead {
      width: 100%;
    }

    tbody > tr > tr {
      width: 100%;
    }

    .DragDropCOntainer {
      width: 100% !important;
    }
  }
`
