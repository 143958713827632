import { Button, FormControl } from 'react-bootstrap'
import styled, { css } from 'styled-components'

export const InputFormControl = styled(FormControl)`
  ${({ error }) =>
    error &&
    css`
      color: #fc5050;
      border: 1px solid #fff;
      border-color: #fc5050 !important;
    `}
`

export const ButtonContainer = styled(Button)`
  width: 100px;

  &:disabled {
    opacity: 0.5;
  }
`

export const ContainerEvent = styled.div`
  hr {
    width: 60%;
  }

  .PrimaryContainer {
    display: flex;
    gap: 10px;
    div {
      width: 60%;
      @media (max-width: 768px) {
        width: 50%;
      }
      display: flex;
      flex-direction: column;
      gap: 15px;

      span {
        display: flex;
        align-items: center;
        gap: 10px;
        @media (max-width: 768px) {
          flex-direction: column;
          input {
            text-align: center;
          }
        }
      }
    }

    .EventoContainer {
      width: 40%;

      @media (max-width: 768px) {
        width: 50%;
      }
      position: relative;
      height: 180px;

      .Visualizar {
        box-shadow: 1px 1px 5px 2.5px #6a6a6a37;
        width: 100%;
        display: grid;
        place-items: center;
        /* background: #6a6a6a37; */
        border-radius: 4px;
        width: 100%;
        height: 180px;

        /* margin-right: 1rem; */
        cursor: pointer;

        > span {
          /* border: 5px solid #333; */
          /* border-radius: 50%; */
          width: 100%;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          /* height: 100%; */
          display: flex;
          flex-direction: column;
          align-items: center;
          > svg {
            width: 50px;
            height: 50px;
            color: #3d3d3da9;
          }
          > strong {
            margin: 0;
            color: #3d3d3da9;
          }

          button {
            /* position: absolute; */
            top: 0;
            left: 120px;
            /* border-radius: 50%; */
            border: 0px;
          }
        }

        img {
          width: 100%;
          /* border-radius: 50%; */
          height: 175px;
          border-radius: 4px;
        }

        input {
          position: absolute;
          height: 180px;
          width: 100%;
          opacity: 0;
          border-radius: 50%;
          top: 0;
        }

        @media (max-width: 765px) {
          position: absolute;
          right: 0;
          /* margin: auto; */
          /* margin-top: -180px; */
        }
      }
    }
  }

  .SecondaryContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;

    textarea {
      width: 60%;
    }

    > div {
      :nth-child(2) {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 60%;
      }
    }

    .tableStyle {
      width: 60%;
      @media (max-width: 765px) {
        width: 60%;
      }
      th {
        :first-child {
          width: 80%;
        }
      }
      tr,
      td {
        border: 1px solid #ced4da;
        border-radius: 5px;
        td:first-child {
          padding: 10px;

          p {
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          width: 80%;
        }
        td:last-child {
          text-align: center;
        }
      }
    }
  }

  .ThreeContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;

    > div {
      :nth-child(1) {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 60%;
      }
    }

    .tableStyle {
      width: 60%;
      @media (max-width: 765px) {
        width: 60%;
      }
      th {
        :first-child {
          width: 80%;
        }
      }
      tr,
      td {
        border: 1px solid #ced4da;
        border-radius: 5px;

        td:first-child {
          padding: 10px;

          p {
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          width: 80%;
        }
        td:last-child {
          text-align: center;
        }
      }
    }

    .FormContainer {
      width: 60%;
      display: flex;
      gap: 10px;
      input {
        width: 50%;
      }
      margin-bottom: 10px;
    }
    input:last-child {
      width: 60%;
    }
  }

  .ContainerButton {
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-top: 10px;
    button {
      width: 100px;
    }
  }
`

interface IAutoComplete {
  primary?: boolean
}

export const AutoComplete = styled.div<IAutoComplete>`
  position: relative;
  width: 60%;
  margin-top: -10px;
  > ul {
    position: absolute;
    padding: 0;

    width: 70%;
    z-index: 1;

    box-shadow: 1px 3px 5px #333;
    background: #fff;

    transition: all ease 0.2s;

    overflow-y: auto;
    min-height: 0px;
    max-height: ${(prop) => (prop.primary ? '100px' : '0px')};
    opacity: ${(prop) => (prop.primary ? '1' : '0')};

    border-radius: 0 0 5px 5px;

    list-style-type: none;
    li:nth-last-child(n + 2) {
      border-bottom: 1px solid #ccc7c7;
    }
    li {
      padding: 5px 10px;
    }
  }
`
